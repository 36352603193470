<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="主体名称" name="nameOrCode">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入名称、编号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="类型" name="type">
              <a-select mode="multiple" v-model:value="queryParam.type" placeholder="请选择">
                <a-select-option :value="1">做单机器人</a-select-option>
                <a-select-option :value="2">查询机器人</a-select-option>
                <a-select-option :value="3">转换</a-select-option>
                <a-select-option :value="4">注销机器人</a-select-option>
                <a-select-option :value="5">转换机器人</a-select-option>
                <a-select-option :value="6">刻章机器人</a-select-option>
                <a-select-option :value="7">发财人客户端</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="状态" name="status">
              <a-select mode="multiple" v-model:value="queryParam.status" placeholder="请选择">
                <a-select-option :value="0">掉线</a-select-option>
                <a-select-option :value="1">闲置中</a-select-option>
                <a-select-option :value="2">工作中</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间" name="between_time">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="table-page-search-submitButtons" style="overflow: hidden; margin-bottom: 0">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button type="primary" @click="testmenu">测试一下</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table rowClassName="tableRowClass" :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'name'">
          <p class="mb-x">{{ record.name }}</p>
          <p class="mb-x">{{ record.account_number }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'status'">
          <p class="mb-x">{{ record.status_name }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'type'">
          <p class="mb-x">{{ record.type_name }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'remark'">
          <p class="mb-x">{{ record.mobile }}</p>
          <p class="mb-x">{{ record.remark }}</p>
          <span>最后更新时间：{{ record.updated_at }}</span>
        </template>
        <template v-else-if="column.dataIndex === 'action'">
          <div class="mb-x">
            <p>
              <a @click="handleEdit(record)">编辑 </a>
            </p>
          </div>
        </template>
      </template>
    </a-table>
    <!-- 新增机器人 -->
    <create-form ref="createForm" :key="data.createKey" :open="data.visible" @cancel="handleCancel" @ok="handleOk" />
    <edit-form v-if="data.editVisible" :open="true" :tableColumnsData="data.tableColumnsData" @cancel="handleCancel" @ok="handleOk" />
  </a-card>
</template>

<script lang="ts" setup name="CustomerList">
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import CreateForm from './CreateForm.vue'
import EditForm from './CreateForm.vue'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { useStore } from 'vuex'
const $store = useStore()
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('Bearer ', '')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'

interface data {
  tableColumnsData: Object //传数据的
  visible: boolean
  loading: boolean //表格的加载中
  editVisible: boolean //编辑弹窗
  createKey: number //添加的key
}
const data = reactive<data>({
  // create model
  visible: false,
  tableColumnsData: {},
  loading: false,
  // 编辑
  editVisible: false,
  createKey: 1
})
const filteredInfo = ref()

const columns = computed(() => {
  const filtered = filteredInfo.value || []
  return [
    {
      title: '名称',
      dataIndex: 'name',
      width: 350
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      width: 240
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 240
    },

    {
      title: '其他信息',
      dataIndex: 'remark',
      width: 480
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 240
    }
  ]
})
let queryParam = reactive<any>({
  order_status_list: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}

// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/robotsList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.name
        }
        return { ...item }
      })
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('列表查询失败,请联系管理员')
      }
    })
}

// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}

const handleEdit = (record) => {
  data.editVisible = true
  data.tableColumnsData = { ...record }
}
const handleCancel = () => {
  data.visible = false
  data.editVisible = false
}
const handleOk = () => {
  data.createKey++
  data.visible = false
  data.editVisible = false
  loadData()
}

// const router = useRouter()
import store from '@/store'
import { random } from 'nanoid'
const testmenu = () => {
  console.log('11111111111111111')
  let ResponseAuthorization = 'Bearer xxxx'

  alert(ResponseAuthorization)
  return
  let targetPath = '/acustomer'
  //判断是否包含targetPath
  let isContain = false
  router.getRoutes().map((item) => {
    if (item.path == targetPath) {
      isContain = true
    }
  })

  if (isContain) {
    console.log('包含')
  } else {
    console.log('不包含')
  }
}
</script>
<style lang="less" scoped>
.tabs {
  position: relative;
  .tabs-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 0;
    display: flex;
    gap: 32px;
    align-items: center;
  }
}
:deep(.tableRowClass) {
  .ant-table-cell:first-child {
    padding: 0 !important;
  }
}
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px !important;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}

.companyInformation {
  padding: 16px;
  .deng {
    display: none;
  }
}

.companyInformation:hover {
  .deng {
    display: block;
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
:deep(.giveborder) {
  border: 2px dashed #ccc;
  padding: 1px;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}
</style>
