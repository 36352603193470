<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserMenu',
})
</script>
<template>
  <div class="user-wrapper">
    <div class="content-box">
      <!-- <span @click="showInform" class="action ant-dropdown-link user-dropdown-menu">
        <a-badge color="#1677ff" count="5">
          <BellOutlined style="font-size: 20px" />
        </a-badge>
      </span> -->

      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" :src="$store.state.user.image_domain + filingsInfo.wechat_picture" v-if="filingsInfo?.wechat_picture && $store.state.user.image_domain" />
          <a-avatar class="avatar" v-else>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
          <span class="nickname">{{ UserInfo.nickname }}</span>
        </span>
        <template #overlay>
          <a-menu class="user-dropdown-menu-wrapper">
            <a-menu-item key="4" @click="showSystemSetting">
              <a>
                <SettingOutlined />
                <span>系统设置</span>
              </a>
            </a-menu-item>
            <a-menu-item key="5" @click="onLockScreen">
              <a>
                <LockOutlined />
                <span>锁定屏幕</span>
              </a>
            </a-menu-item>
            <a-menu-item key="4" v-if="isAdminJump">
              <a href="javascript:;" @click="handleBackAdmin">
                <RollbackOutlined />
                <span>返回admin</span>
              </a>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="3">
              <a href="javascript:;" @click="handleLogout">
                <LogoutOutlined />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup name="UserMenu">
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { SettingOutlined, LogoutOutlined, LockOutlined, RollbackOutlined, WechatOutlined } from '@ant-design/icons-vue'
import { systemConfig } from '@/store/reactiveState'
import ls from '@/utils/Storage'
import { clearUserInfo } from '@/utils/util'
import { UserOutlined, BellOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import store from '@/store'
import { loginSuccess, requestFailed } from '@/views/user/helper'
const router = useRouter()
import { useStore } from 'vuex'
const $store = useStore()
const props = defineProps(['theme'])

const UserInfo = ls.get('USER_INFO')
const isAdminJump = ls.get('login_success_flag')
const filingsInfo = ls.get('FILINGS_INFO')
const handleLogout = () => {
  Modal.confirm({
    title: '提示',
    content: '确定注销登录吗 ?',
    onOk: () => {
      store
        .dispatch('Logout', router)
        .then((res) => {
          router.push({ path: '/user/login', query: { redirect: router.currentRoute.value.fullPath } })
        })
        .catch((res) => {})
    },
    onCancel() {}
  })
}
const showSystemSetting = () => {
  systemConfig.commit('SET_SETTING_DRAWER', true)
}

const showInform = () => {
  $store.commit('SET_SHOW_INFORM', true)
}

const onLockScreen = () => {
  systemConfig.commit('SET_LOCK_SCREEN', true)
}

const handleBackAdmin = () => {
  $store
    .dispatch('SwitchUser', 'back' + isAdminJump)
    .then((res) => {
      $store.dispatch('GetInfo')
      // TODO 默认配置中使用服务端获取菜单
      // if (config.useAsyncRouter) {
      //     generateAsyncRoutes(router, res.menu)
      // }
      loginSuccess(router, res)
      ls.set('login_success_flag', 0) //清除admin标记
      setTimeout(() => {
        window.location.href = '/'
      })
    })
    .catch((res) => {
      message.error(res.msg)
    })
}
</script>
<style lang="less" scoped>
.BellOutlined {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  cursor: pointer;
  color: #1677ff;
  font-size: 16px;
}
.content-box {
  display: flex;
  align-items: center;
}
</style>
<style lang="less">
.user-dropdown-menu-wrapper {
  .ant-dropdown-menu-item {
    width: 100% !important;
  }
}
</style>
