<template>
  <a-select
    v-model:value="state.value"
    placeholder="请输入外勤电子化账号"
    style="width: 100%"
    show-search
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="state.fetching ? undefined : null"
    :options="state.data"
    @search="fetchDzhAccount"
    @change="changeValue"
  >
    <template v-if="state.fetching" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>
<script lang="ts" setup>
import { reactive, ref, onMounted, h, watch } from 'vue'
import { debounce } from 'lodash-es'
import baseService from '@/utils/http/axios'
const emit = defineEmits(['update:value', 'change'])
const props = defineProps({
  value: {
    type: Number,
    default: null
  }
})
const state = reactive<any>({
  data: [],
  value: null,
  fetching: false
})

//查询电子化账号数据
let lastFetchId = 0
const fetchDzhAccount = debounce((value) => {
  lastFetchId += 1
  const fetchId = lastFetchId
  state.data = []
  state.fetching = true
  let requestParam = {
    url: '/admin/v1/searchdzhaccount',
    method: 'post',
    data: { keyword: value }
  }
  baseService(requestParam).then((body) => {
    if (fetchId !== lastFetchId || body.data == null || body.data == '') {
      return
    }
    body.data.forEach((r: any) => {
      state.data.push({
        value: r.id,
        label: r.name + ' ' + r.tel
      })
    })
    state.fetching = false
  })
}, 300)
//改变值
const changeValue = (value: any) => {
  emit('update:value', state.value)
  emit('change', state.value)
}
watch(
  () => props.value,
  (val) => {
    if (val) {
      state.value = val
      //如果不在选项里面，则查询
      if (!state.data.find((r: any) => r.value == val)) {
        setTimeout(() => {
          fetchDzhAccount('id_' + val)
        })
      }
    }
  },
  {
    deep: true,
    immediate: true
  }
)
</script>
