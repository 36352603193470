import { Response } from './../../../types/global.d';
import axios, { AxiosResponse } from 'axios'
import { message } from 'ant-design-vue'
import ls from '@/utils/Storage'
import emitter from '@/utils/eventBus'
import { clearUserInfo } from '@/utils/util'
import router from '../../router'
import { debug, log } from 'console'
import store from '@/store'
const ContentType = {
    urlencoded: 'application/x-www-form-urlencoded;charset=UTF-8',
    json: 'application/json',
    formData: 'multipart/form-data'
}
let showMsg = true;
// 创建 axios 实例   withCredentials: true,
const baseService = axios.create({
    // baseURL,
    baseURL: '/',
    timeout: 60000,
    responseType: 'json',
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
})

// request interceptor
baseService.interceptors.request.use(
    (config: any) => {
        if (config.url.indexOf('hncskaiyela') == -1 && config.url.indexOf('cswanjie') == -1) {
            config.baseURL = '/hncskaiyela'
        }
        // 文件下载
        if (config.headers.responseType == 'blob') {
            config.responseType = 'blob'
        }
        const Authorization = ls.get('ACCESS_TOKEN')
        if (Authorization) {
            config.headers['Authorization'] = Authorization // 让每个请求携带自定义 token 请根据实际情况自行修改
        }
        config.headers['Content-Type'] = ContentType[config.data instanceof FormData ? 'formData' : 'json']
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

baseService.interceptors.response.use(
    (res: AxiosResponse<any>) => {
        // 文件流下载
        if (res.config.headers.responseType === 'blob' && res.headers['content-type'] != 'application/json') {
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            let disposition = res.headers['content-disposition']
            let filename: any = ''
            if (disposition) {
                const filename_start = disposition.indexOf('filename=') + 'filename='.length;
                filename = disposition.substring(filename_start).trim().replace(/"/g, '');
                filename = decodeURIComponent(filename);
                filename = filename.split("'").pop();
            } else {
                filename = '1';
            }
            let url = window.URL.createObjectURL(blob)
            const link = document.createElement('a') // 创建a标签
            link.href = url
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.style.display = 'none';
            link.click();
            URL.revokeObjectURL(url);
            return res
        }
        if (res.status === 200) {
            let resData = res.data
            if (resData.code === 200 || resData.code === '200') {
                //如果返回有新token，则更新一下
                let ResponseAuthorization = res.headers['authorization']
                if (ResponseAuthorization) {
                    store.dispatch('RefreshToken', ResponseAuthorization)
                        .then((res) => {
                            ls.set('ACCESS_TOKEN', ResponseAuthorization);
                        })
                        .catch((res) => {
                            console.log(res)
                        })
                }

                return res.data
            } else if (resData.code === 401) {
                // 防止多次提示
                if (showMsg) {
                    message.error('登录过期或权限不足, 请重新登陆!')
                    showMsg = false
                    setTimeout(() => {
                        showMsg = true;
                    }, 3000);
                }

                clearUserInfo()
                router.push({ path: '/user/login', query: { redirect: router.currentRoute.value.fullPath } })
                return
            } else {
                return Promise.reject(resData)
            }
        } else {
            if (window.localStorage.getItem('lang') === 'en') {
                message.error(res.data.enMsg)
            } else {
                message.error(res.data.zhMsg)
            }
            return false
        }
        // return res
    },
    error => {
        if (error.response.status === 401) {
            // 防止多次提示
            if (showMsg) {
                message.error('登录过期或权限不足, 请重新登陆!')
                showMsg = false
                setTimeout(() => {
                    showMsg = true;
                }, 3000);
            }
            clearUserInfo()
            router.push({ path: '/user/login', query: { redirect: router.currentRoute.value.fullPath } })
            return Promise.reject(error)
        } else {
            return Promise.reject(error)
        }
    }
)

export default baseService
