<template>
  <a-modal :title="`${props.tableColumnsData.full_name} - 档案管理`" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <div class="d-flex">
        <div>
          <a-button :loading="state.downZcLoading" v-if="props.tableColumnsData.main_type == 1 && props.tableColumnsData.registration_type != 2" type="primary" @click="handleDownload"
            >获取公司章程</a-button
          >
        </div>
        <div>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="handleRecord">添加档案</a-button>
        </div>
      </div>
    </template>
    <a-table size="small" :loading="state.ownershipTableLoading" style="margin-bottom: 30px" :columns="columns" rowKey="name" :data-source="shareOwnership" :pagination="false">
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.dataIndex === 'options'">
          <a v-if="record.status == 2" :href="'/hncskaiyela/admin/v1/templateZipDown?id=' + record.id + '&token=' + token" target="_blank"> 下载</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除此档案?" @confirm="handleDelete(record)">
            <a style="color: red">删除</a>
          </a-popconfirm>
        </template>
        <template v-else-if="column.dataIndex === 'name'">
          <div v-if="record.remark">{{ record.remark }}</div>
          <div v-else>-</div>
          <div v-if="record.type == 6">防伪码：{{ record.ukeycode }}</div>
        </template>
        <template v-else-if="column.dataIndex === 'url'">
          <div v-if="[1, 2, 3, 5, 6, 7, 8, 12].includes(record.type)" class="image-list">
            <template v-for="(item, index) in text">
              <a-image
                v-if="item.url.endsWith('png') || item.url.endsWith('jpg') || item.url.endsWith('jpeg')"
                :width="60"
                :height="60"
                :key="index"
                :src="$store.state.user.image_domain + item.url"
              ></a-image>
            </template>
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="column.dataIndex === 'operator_admin_info'">
          {{ text?.nickname ? text.nickname : '-' }}
        </template>
      </template>
    </a-table>
    <a-modal
      title="添加档案"
      :bodyStyle="{ padding: '20px', paddingBottom: '0' }"
      :width="700"
      :destroyOnClose="true"
      :open="state.createOpen"
      @ok="createOk"
      @cancel="
        () => {
          state.createOpen = false
        }
      "
    >
      <a-form :model="createForm" v-bind="formLayout" ref="createFormRef">
        <a-form-item label="类型" required>
          <a-select v-model:value="createForm.type" placeholder="请选择档案类型">
            <a-select-option v-for="(item, index) in typeList" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="附件" required>
          <Upload
            :upload_path="props.tableColumnsData.upload_path"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'mp3', 'mp4', 'zip', 'rar', '7z']"
            :customerName="props.tableColumnsData.entityNameToRegister.full_name"
            uploadType="text"
            v-model:value="createForm.url"
            :limitation="99"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="备注" required v-if="createForm.type == 10">
          <a-input placeholder="请输入备注" v-model:value="createForm.remark"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-modal>
</template>

<script lang="ts" setup>
import Upload from '@/components/base/Upload.vue'
import { reactive, onMounted, ref } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import ls from '@/utils/Storage'
import { useStore } from 'vuex'
const $store = useStore()
const token = ls.get('ACCESS_TOKEN').replace('Bearer ', '')
interface tableData {
  isEdit: boolean
}
const emit = defineEmits(['cancel'])
const columns: Array<Object> = [
  {
    title: '资料类型',
    dataIndex: 'type_name',
    width: 250
  },
  {
    title: '资料名称',
    dataIndex: 'name',
    width: 200
  },
  {
    title: '资源',
    dataIndex: 'url',
    width: 450
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    width: 200
  },
  {
    title: '创建人',
    dataIndex: 'operator_admin_info',
    width: 200
  },
  {
    title: '操作',
    dataIndex: 'options',
    width: 300
  }
]

const typeList = [
  {
    label: '电子化资料',
    value: 1
  },
  {
    label: '现场资料',
    value: 2
  },
  {
    label: '银行开户资料',
    value: 3
  },
  {
    label: '租赁合同',
    value: 4
  },
  {
    label: '续费催款告知书',
    value: 5
  },
  {
    label: '解除协议',
    value: 6
  },
  {
    label: '移出异常资料',
    value: 7
  },
  {
    label: '营业执照照片资料',
    value: 8
  },
  {
    label: '章程资料',
    value: 9
  },
  {
    label: '代理记账协议',
    value: 10
  },
  {
    label: '列入异常资料',
    value: 11
  },
  {
    label: '移交线索关店资料',
    value: 12
  },
  {
    label: '其他资料',
    value: 101
  }
]
interface data {
  ownershipTableLoading: boolean
  downZcLoading: boolean
  createOpen: boolean
}
const state = reactive<data>({
  ownershipTableLoading: false,
  downZcLoading: false,
  createOpen: false
})
const createForm = reactive<any>({})
const createFormRef = ref()
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 }
  }
}
const shareOwnership = ref<tableData[]>([])

onMounted(() => {
  queryDetail()
})
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})

const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/templateZip/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      queryDetail()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

const queryDetail = () => {
  state.ownershipTableLoading = true
  let requestParam = {
    url: '/admin/v1/templateZipList',
    method: 'post',
    data: {
      customer_id: props.tableColumnsData.id,
      limit: 'all'
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      shareOwnership.value = res.data.list
      state.ownershipTableLoading = false
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const cancel = () => {
  emit('cancel')
}
const handleRecord = () => {
  state.createOpen = true
}
const handleDownload = () => {
  state.downZcLoading = true
  let requestParam = {
    url: '/admin/v1/customerDownZc?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res: any) => {
      state.downZcLoading = false
      message.success(res.msg ? res.msg : '获取成功')
      queryDetail()
    })
    .catch((res) => {
      state.downZcLoading = false
      message.error(res.msg ? res.msg : '获取失败')
    })
}
const createOk = () => {
  createFormRef.value.validate().then(() => {
    let requestParam = {
      url: '/admin/v1//templateZipAppend',
      method: 'post',
      data: Object.assign({ customer_id: props.tableColumnsData.id }, createForm)
    }
    baseService(requestParam)
      .then((res: any) => {
        message.success(res.msg ? res.msg : '添加成功')
        state.createOpen = false
        const keys = Object.keys(createForm)
        let obj: { [name: string]: any } = {}
        keys.forEach((item) => {
          obj[item] = null
        })
        Object.assign(createForm, obj)
        queryDetail()
      })
      .catch((res) => {
        message.error(res.msg ? res.msg : '添加失败')
      })
  })
}
</script>

<style lang="less" scoped>
.d-flex {
  justify-content: space-between;
  align-items: center;
}
.image-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
