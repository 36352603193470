<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="平台" name="platform_id">
              <a-select mode="multiple" v-model:value="queryParam.platform_id" placeholder="请选择">
                <a-select-option v-for="(item, index) in data.platformList" :key="index" :value="item.id">{{ item.platform_abbreviation }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="公司名称" name="nameOrCode">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入公司名称、税号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="续费联系人号码" name="renew_first_contact_mobile">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.renew_first_contact_mobile" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="代理商姓名" name="seller">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.seller" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <template v-if="data.advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="法人代表姓名" name="fr_name">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.fr_name" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="地址编号" name="address_number">
                <a-input @keyup.enter="loadData" v-model:value="queryParam.address_number" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="主体类型" name="main_type">
                <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择">
                  <a-select-option :value="1">公司</a-select-option>
                  <a-select-option :value="2">个体</a-select-option>
                  <a-select-option :value="3">个人独资</a-select-option>
                  <a-select-option :value="4">合伙企业</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="收费类型" name="fee_standard">
                <a-select mode="multiple" v-model:value="queryParam.fee_standard" placeholder="请选择">
                  <a-select-option :value="1">按年收费</a-select-option>
                  <a-select-option :value="2">一次性</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="付款状态" name="is_pay">
                <a-select mode="multiple" v-model:value="queryParam.is_pay" placeholder="请选择">
                  <a-select-option :value="1">已付款</a-select-option>
                  <a-select-option :value="2">未付款</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="入驻类型" name="business_type">
                <a-select mode="multiple" v-model:value="queryParam.business_type" placeholder="请选择">
                  <a-select-option :value="1">设立</a-select-option>
                  <a-select-option :value="2">同区迁入</a-select-option>
                  <a-select-option :value="3">跨区迁入</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="进度状态" name="order_status">
                <a-select mode="multiple" :disabled="queryParam.order_status" v-model:value="queryParam.order_status_list" placeholder="请选择" @change="orderStatusChange" @select="orderStatusSelect">
                  <a-select-option v-for="(item, index) in data.progressOptions" :key="index" :value="item.value">{{ item.text }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="录入系统时间" name="between_time">
                <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否查询已删除客户" name="recycle">
                <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="queryParam.recycle"></a-switch>
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>

      <div class="table-operator">
        <div></div>
        <div class="table-page-search-submitButtons" style="float: right; overflow: hidden; margin-bottom: 0">
          <a-button type="primary" @click="loadData">查询</a-button>
          <a @click="toggleAdvanced" style="margin-left: 8px">
            {{ data.advanced ? '收起' : '展开' }}
            <UpOutlined v-if="data.advanced" />
            <DownOutlined v-else />
          </a>
          <a-popover title="导出参数配置" trigger="click" :overlayStyle="{ width: '60%' }" placement="left" v-model:open="exportDataArgumentChecked.visible">
            <template #content>
              <a-tabs v-model:activeKey="exportDatatab">
                <a-tab-pane key="1" tab="根据搜索参数直接导出"
                  ><a-descriptions :column="1">
                    <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                      <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                      <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="导出字段">
                      <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                    </a-descriptions-item>
                    <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                      <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excel' })" @click="exportClick(1)" :loading="data.exportLoading">导出</a-button>
                    </a-descriptions-item>
                  </a-descriptions></a-tab-pane
                >
                <a-tab-pane key="2" tab="根据输入的公司名称导出详细信息（选择此项，搜索条件将失效）"
                  ><a-descriptions :column="1">
                    <a-descriptions-item label="公司名称" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                      <a-textarea v-model:value="exportDataArgumentChecked.companylist" placeholder="请输入公司名称" allow-clear :rows="6" />
                    </a-descriptions-item>
                    <a-descriptions-item label="导出版本" :contentStyle="{ paddingTop: '10px' }" :labelStyle="{ paddingTop: '10px' }">
                      <a-button @click="selectExportDataArgument(1)">精简版</a-button><a-divider type="vertical" />
                      <a-button @click="selectExportDataArgument(2)">全能版</a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="导出字段">
                      <a-checkbox-group v-model:value="exportDataArgumentChecked.checkedfieldSimple" name="checkboxgroup" :options="exportDataArgument" />
                    </a-descriptions-item>
                    <a-descriptions-item :contentStyle="{ paddingLeft: '62px' }">
                      <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excel' })" @click="exportClick(2)" :loading="data.exportLoading">导出</a-button>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-tab-pane>
              </a-tabs>
            </template>
            <a-button style="margin-left: 8px; display: inline-flex" :icon="h(SvgIcon, { name: 'excel' })" @click="exportClickComfirm" :loading="data.exportLoading">导出</a-button>
          </a-popover>
        </div>
      </div>
    </div>

    <a-table rowClassName="tableRowClass" :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'companyInformation'">
          <div class="companyInformation" :style="[5, 6].includes(record.status) ? { borderLeft: '10px dashed #000' } : {}">
            <a-tag color="grey" v-if="record.status === 5">该主体已删除</a-tag>
            <a-tag v-else-if="record.status === 6" color="grey"> 主体删除审核中，如需撤销删除，请联系管理员</a-tag>
            <div class="mv-1x">
              <a-popover trigger="click" v-model:open="record.isShowCompany" overlayClassName="customer-options-popover">
                <template #content>
                  <p @click="handleDoubleNameClick(record.tyc_url)">跳转到天眼查</p>
                  <p @click="handleDoubleNameClick(record.qcc_url)">跳转到企查查</p>
                </template>
                <div style="width: 100%"></div>
              </a-popover>
              <span
                class="mr-1x pointer"
                :style="record.status === 5 ? { textDecorationLine: 'line-through' } : {}"
                @click="
                  (e) => {
                    record.isShowCompany = false
                    handleNameClick(e, record.full_name)
                  }
                "
                @dblclick="
                  () => {
                    record.isShowCompany = true
                  }
                "
                v-html="record.fullName"
              ></span>
              <a-tag color="green" v-if="record.business_type == 1">设立</a-tag>
              <a-tag color="blue" v-if="record.business_type == 2">同区迁入</a-tag>
              <a-tag color="blue" v-if="record.business_type == 3">跨区迁入</a-tag>
            </div>
            {{ record.social_credit_code }}
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'full_address_number'">
          <div @click="handleCopyClick(text)" class="pointer">
            <div v-if="record.raw_address?.length > 0" style="border-bottom: 1px dashed #ccc">由：{{ record.raw_address }}</div>
            <div><span v-if="record.raw_address?.length > 0">迁到：</span>{{ text }}</div>
          </div>
        </template>

        <template v-else-if="column.dataIndex === '成立日期'">
          <div v-if="record.isEdit1">
            <a-date-picker
              :value="foundingTime(record.founding_time)"
              @change="
                (val) => {
                  record.founding_time = dayjs(val).format('YYYY-MM-DD')
                }
              "
              :format="format"
              placeholder="请输入成立日期"
            ></a-date-picker>
            <div>
              <a-button class="mr-2x" @click="loadData()" size="small">取消</a-button>
              <a-button @click="editTimesChange(record, 'founding_time')" size="small" type="primary">确认</a-button>
            </div>
          </div>
          <span v-else @click="editTimes(index, 'isEdit1')" class="pointer founding_time"
            >{{ record.founding_time || '无' }}
            <EditOutlined />
          </span>
        </template>

        <template v-else-if="column.dataIndex === 'verify_time'">
          <div v-if="record.isEdit2">
            <a-date-picker
              :value="foundingTime(record.verify_time)"
              @change="
                (val) => {
                  record.verify_time = dayjs(val).format('YYYY-MM-DD')
                }
              "
              :format="format"
              placeholder="请输入核准日期"
            ></a-date-picker>
            <div>
              <a-button class="mr-2x" @click="loadData()" size="small">取消</a-button>
              <a-button @click="editTimesChange(record, 'verify_time')" size="small" type="primary">确认</a-button>
            </div>
          </div>
          <span v-else @click="editTimes(index, 'isEdit2')" class="pointer verify_time"
            >{{ record.verify_time || '无' }}
            <EditOutlined />
          </span>
        </template>

        <template v-else-if="column.dataIndex === 'status'">
          <div v-if="record.customer_work_order_status">
            <p class="mb-x">
              {{ record.customer_work_order_status.after_status_name }}
            </p>
            <p class="mb-x">{{ record.customer_work_order_status.created_at }}</p>
          </div>
          <div v-else>-</div>
        </template>

        <template v-else-if="column.dataIndex === 'seller'">
          <p class="mb-x">
            {{ record.seller ? record.seller : '-' }}<a @click="handleCopyClick(record.renew_first_contact_mobile)" class="ml-1x"><CopyOutlined /></a>
          </p>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script lang="ts" setup name="CustomerList">
import SvgIcon from '@/components/SvgIcon/index.vue'
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import Record from './components/Record.vue'
import { useRouter } from 'vue-router'
import { EditOutlined } from '@ant-design/icons-vue'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { Value } from 'sass'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('Bearer ', '')
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'
const shareholderColumns: Object[] = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '职位',
    dataIndex: 'position_name'
  },
  {
    title: '出资金额',
    dataIndex: 'investment_money'
  },
  {
    title: '出资截止日期',
    dataIndex: 'subscribe_endtime'
  }
]

interface data {
  key: string | number
  rowSelection: number[]
  customerNavig: any
  entryAgreementVisible: boolean
  recordVisible: boolean
  addTableVisible: boolean
  signatureStatusVisible: boolean
  directPaymentVisible: boolean
  platformVisible: boolean
  pushPaymentVisible: boolean
  pushOrderMakingVisible: boolean
  SFMailVisible: boolean
  addressRenewVisible: boolean
  visibleLog: boolean
  visibleStatus: boolean
  optionsVisible: boolean
  visible: boolean
  advanced: boolean
  tableColumnsData: Object
  loading: boolean
  editVisible: boolean
  exportLoading: boolean
  logTabsKey: number
  createKey: number
  platformList: any[]
  downList: any
  customerInfo: any
  progressOptions: any[]
  tabsKey: number
  chat_read_count: number
}
const data = reactive<data>({
  key: '',
  rowSelection: [],
  customerNavig: {},
  exportLoading: false,
  // 入驻协议
  entryAgreementVisible: false,
  // 档案管理
  recordVisible: false,
  // 打标签
  addTableVisible: false,
  // 查看签名状态
  signatureStatusVisible: false,
  // 付款弹窗
  directPaymentVisible: false,
  platformVisible: false,
  // 推送付款
  pushPaymentVisible: false,
  // 推送做单
  pushOrderMakingVisible: false,
  // 顺丰邮寄
  SFMailVisible: false,
  //   地址续费
  addressRenewVisible: false,
  visibleLog: false,
  visibleStatus: false,
  // 操作弹窗
  optionsVisible: false,
  // create model
  visible: false,
  // 高级搜索 展开/关闭
  advanced: false,
  tableColumnsData: {},
  loading: false,
  // 编辑
  editVisible: false,
  // 进入日志 还是进入状态
  logTabsKey: 1,
  createKey: 1,
  platformList: [],
  /* 下载列表 */
  downList: {},
  //数据列表
  customerInfo: {},
  // 进度状态
  progressOptions: [],
  tabsKey: 2,
  chat_read_count: 0
})
const filteredInfo = ref()
const createForm = ref()

const selectedRowKeys = ref<data['key'][]>([]) // Check here to configure the default column
const onSelectChange = (changableRowKeys: string[]) => {
  console.log('selectedRowKeys changed: ', changableRowKeys)
  selectedRowKeys.value = changableRowKeys
}

const columns = computed(() => {
  const filtered = filteredInfo.value || []
  const progressOptions = data.progressOptions
  return [
    {
      title: '公司名称',
      dataIndex: 'companyInformation',
      width: 300
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      width: 200
    },
    {
      title: '注册地址',
      dataIndex: 'full_address_number',
      width: 300
    },
    {
      title: '成立日期',
      dataIndex: '成立日期',
      width: 100
    },
    {
      title: '核准日期',
      dataIndex: 'verify_time',
      width: 100
    },
    {
      title: '录入日期',
      dataIndex: 'created_at',
      width: 100
    },
    {
      title: '代理商',
      dataIndex: 'seller',
      width: 100
    }
  ]
})
const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: [11],
  platform_id: [1, 3, 9, 11]
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  platformList()
  customerNavig()
  loadData()
})
onActivated(() => {
  queryParam.nameOrCode = router.currentRoute.value.query.customer_name
  if (router.currentRoute?.value?.query?.customer_name) {
    loadData()
  }
})
// 跳转天眼查
const handleDoubleNameClick = (url) => {
  window.open(url)
}
const foundingTime = (val) => {
  if (!val) return null
  return dayjs(val, 'YYYY-MM-DD')
}
const editTimes = (index: number, fieldStr: string) => {
  dataSource.value[index][fieldStr] = true
}
// 修改时间
const editTimesChange = (record, fieldStr) => {
  let timestr = dayjs(record[fieldStr]).format('YYYY-MM-DD')

  let data: any = {
    customer_id: record.id,
    field_str: fieldStr,
    time_str: timestr
  }
  let requestParam = {
    url: '/admin/v1/customerAlterTime',
    method: 'post',
    data: data
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}
// 复制
const handleCopyClick = (val) => {
  var input = document.createElement('input')
  //将input的值设置为需要复制的内容
  input.value = val
  //添加input标签
  document.body.appendChild(input)
  //选中input标签
  input.select()
  //执行复制
  document.execCommand('copy')
  //成功提示信息
  message.success('复制成功!')
  //移除input标签
  document.body.removeChild(input)
}
// 复制名称
const handleNameClick = (e, name) => {
  if (e.detail == 1) {
    var input = document.createElement('input')
    //将input的值设置为需要复制的内容
    input.value = name
    //添加input标签
    document.body.appendChild(input)
    //选中input标签
    input.select()
    //执行复制
    document.execCommand('copy')
    //成功提示信息
    message.success('复制成功，尝试双击有惊喜！')
    //移除input标签
    document.body.removeChild(input)
  }
}

// 进度状态选择
const orderStatusChange = (val) => {
  if (!val || val.length === 0) {
    data.tabsKey = 2
  }
}
// 进度状态选择
const orderStatusSelect = (val) => {
  if (val == 'blz') {
    queryParam.order_status_list = ['blz']
    data.tabsKey = 1
  } else {
    data.tabsKey = 2
    queryParam.order_status_list = queryParam.order_status_list.filter((item) => item != 'blz')
  }
  filteredInfo.value = queryParam.order_status_list
}
const formatDuration = (duration: number) => {
  const days: any = Math.floor(duration / (24 * 60 * 60))
  if (days > 0 && days <= 30) {
    return '到期前30天内'
  } else if (days > 30 && days <= 60) {
    return '到期前60天内'
  } else if (days < 0 && days >= -30) {
    return '到期后30天内'
  } else if (days < -30 && days >= -60) {
    return '到期后60天内'
  } else if (days < -60 && days >= -90) {
    return '到期后90天内'
  } else if (days < -90) {
    return '到期90天后客户,即将申报工商地址异常'
  } else {
    return null
  }
}
// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize, ishezhun: 2 }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      data.customerInfo = {
        unrecorded_electronic: res.data.unrecorded_electronic,
        modify_data: res.data.modify_data,
        to_be_verified: res.data.to_be_verified
      }
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, isEdit1: false, isEdit2: false, isEdit3: false }
      })

      let downList: any = []
      for (const key in res.data.down_type) {
        downList.push({ type: key, name: res.data.down_type[key] })
      }
      if (data.progressOptions != res.data.order_status_list) {
        data.progressOptions = res.data.order_status_list
      }
      data.downList = downList
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
const customerNavig = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customerNavig',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      Object.assign(data.customerNavig, res.data)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取统计失败')
      }
    })
}
// 获取平台数据
const platformList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: { limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      data.platformList = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('平台列表查询失败,请联系管理员')
      }
    })
}
// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}
const exportDataArgument = [
  '主体名称',
  '统一社会信用代码',
  '法人代表姓名',
  '注册地址',
  '合同开始时间',
  '合同结束时间',
  '主体类型',
  '入驻类型',
  '成立时间',
  '核准日期',
  '录入时间',
  '主体状态',
  '异常状态',
  '法人代表电话',
  '工商预留手机号码',
  '紧急联系人电话',
  '其他电话',
  '是否支付',
  '归属代理商',
  '财务会计电话(续费联系人)',
  '财务公司名称',
  '财务公司对接人姓名',
  '财务公司对接人电话',
  '财务公司统一社会信用代码',
  '销售价格',
  '来年续费价格'
]
const exportDataArgumentChecked = reactive({
  visible: false,
  companylist: '',
  checkedfieldSimple: ['主体名称', '统一社会信用代码', '法人代表姓名', '注册地址', '合同开始时间', '合同结束时间', '主体类型', '入驻类型', '成立时间', '核准日期', '录入时间', '主体状态', '异常状态'],
  checkedfieldAll: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态',
    '法人代表电话',
    '工商预留手机号码',
    '紧急联系人电话',
    '其他电话',
    '是否支付',
    '归属代理商',
    '财务会计电话(续费联系人)',
    '财务公司名称',
    '财务公司对接人姓名',
    '财务公司对接人电话',
    '财务公司统一社会信用代码',
    '销售价格',
    '来年续费价格'
  ],
  checkedfieldSimpleBak: [
    '主体名称',
    '统一社会信用代码',
    '法人代表姓名',
    '注册地址',
    '合同开始时间',
    '合同结束时间',
    '主体类型',
    '入驻类型',
    '成立时间',
    '核准日期',
    '录入时间',
    '主体状态',
    '异常状态'
  ]
})
const selectExportDataArgument = (type) => {
  if (type == 1) {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldSimpleBak
  } else {
    exportDataArgumentChecked.checkedfieldSimple = exportDataArgumentChecked.checkedfieldAll
  }
}

const exportDatatab = ref('1')

const exportClick = (type) => {
  data.exportLoading = true
  exportDataArgumentChecked.visible = false
  queryParam.exportfield = exportDataArgumentChecked.checkedfieldSimple
  queryParam.type = type
  if (type == 2) {
    queryParam.companylist = exportDataArgumentChecked.companylist
  }
  queryParam = { ...queryParam, simple: 1 }

  let requestParam = {
    url: '/admin/v1/customerExport',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      responseType: 'blob'
    },
    data: queryParam
  }
  baseService(requestParam)
    .then((res) => {
      data.exportLoading = false
      message.success('下载成功')
    })
    .catch((res) => {
      data.exportLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('下载失败')
      }
    })
}

const toggleAdvanced = () => {
  data.advanced = !data.advanced
}
</script>
<style lang="less" scoped>
.tabs {
  position: relative;
  .tabs-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 0;
    display: flex;
    gap: 32px;
    align-items: center;
  }
}
:deep(.tableRowClass) {
  .ant-table-cell:first-child {
    padding: 0 !important;
  }
}
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px !important;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}

.companyInformation {
  padding: 16px;
  .deng {
    display: none;
  }
}

.companyInformation:hover {
  .deng {
    display: block;
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
</style>
