<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SettingDrawer',
})
</script>
<template>
  <div>
    <a-drawer
      :bodyStyle="{ padding: 0 }"
      width="1200"
      placement="right"
      :maskStyle="{ background: '#0000001a' }"
      @after-open-change="afterOpenChange"
      @close="onClose"
      :closable="false"
      :open="$store.state.showWXInform"
    >
      <div class="inform-drawer">
        <div class="drawer-left" style="width: 35%">
          <div class="title">
            微信公众号信息
            <span v-if="data.menuCount > 0">{{ data.menuCount }}</span>
          </div>
          <div class="menus" style="overflow-y: auto; height: 90%; max-height: 700px" @scroll="handleScroll">
            <div class="menus-item" :style="data.lianxiren.key == item.key ? { background: '#ebecf0' } : {}" @click="selectMenus(item)" v-for="(item, index) in menusList" :key="index">
              <a-row style="width: 100%">
                <a-col :span="3">
                  <a-avatar :size="38" style="margin-right: 5px" :src="item.headimgurl">
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </a-col>
                <a-col :span="19">
                  <!-- <p style="overflow: hidden; text-overflow: ellipsis; height: 21px; width: 100%; white-space: nowrap">{{ item.title }}</p> -->
                  <p>{{ item.companyname }}</p>
                  <p style="font-size: 12px; color: gray">{{ item.lastmsg }}</p>
                  <p style="font-size: 10px; color: gray">{{ item.created_at }}</p>
                </a-col>
                <a-col :span="2">
                  <a-badge color="#1677ff" :count="item.inform"> </a-badge>
                  <a-tag color="#f50" v-if="item.is_top == 1" class="mt-1x" style="margin-left: -10px">置顶</a-tag>
                </a-col>
              </a-row>
            </div>
            <div style="display: flex; justify-content: center; align-items: center">
              <a-spin :spinning="data.menuLoading"></a-spin>
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <div class="title">
            {{ data.lianxiren.companyname }}
            <a-switch
              @click="toTopClick(data.lianxiren)"
              :checked="data.lianxiren.is_top == 1 ? true : false"
              checked-children="已置顶"
              un-checked-children="未置顶"
              :loading="data.topLoading"
              style="float: right"
            />
            <p style="font-size: 12px; color: gray; padding: 5px 0">入驻地址：{{ data.lianxiren.address }}</p>
          </div>
          <Inform :lianxirenData="data.lianxiren"></Inform>
        </div>
      </div>
      <template #handle>
        <div class="inform-drawer-index-handle" v-if="$store.state.showWXInform" @click="onClose">
          <CloseOutlined style="color: #fff" />
        </div>
      </template>
    </a-drawer>
  </div>
</template>

<script lang="ts" setup name="SettingDrawer">
import { UserOutlined, BellOutlined } from '@ant-design/icons-vue'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons-vue'
import Inform from './components/inform.vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import type { PaginationProps } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
const $store = useStore()
interface data {
  menuLoading: boolean
  menuCount: number
  lianxiren: {
    customer_id: number
    side: number
    companyname: string
    address: string
    key: string
    is_top: number
  }
  topLoading: boolean
}
const data = reactive<data>({
  menuLoading: false,
  menuCount: 0,
  lianxiren: {
    customer_id: 0,
    side: 0,
    companyname: '',
    address: '',
    key: '',
    is_top: 0
  },
  topLoading: false
})
// 消息列表
const menusList = ref<any>([])
const menusPagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
const selectMenus = (item) => {
  data.lianxiren = {
    customer_id: item.customer_id,
    side: item.side,
    companyname: item.companyname,
    address: item.address,
    key: item.key,
    is_top: item.is_top
  }
  //清空未读标记
  item.inform = 0
}
//打开弹出-初始化
const afterOpenChange = (bool: boolean) => {
  if (bool) {
    menusList.value = []
    menusPagination.current = 1
    data.menuCount = 0
    data.menuLoading = false
    data.lianxiren = {
      customer_id: 0,
      side: 0,
      companyname: '',
      address: '',
      key: '',
      is_top: 0
    }
    loadLianxiren()
  }
}
const onClose = () => {
  $store.commit('SET_SHOW_WXINFORM', false)
}
//联系人列表滚动加载
const handleScroll = (event) => {
  if (!data.menuLoading && (data.menuCount == 0 || data.menuCount > menusList.value.length)) {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    // 检查是否接近底部，并触发加载更多数据
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      // 留一点余地避免重复触发
      if (menusPagination.current) {
        menusPagination.current++
        loadLianxiren()
      }
    }
  }
}
const loadLianxiren = () => {
  //获取消息列表
  data.menuLoading = true
  let requestParam = {
    url: '/admin/v1/chatlist',
    method: 'post',
    data: {
      page: menusPagination.current,
      page_size: menusPagination.pageSize
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      data.menuLoading = false
      res.data.list.forEach((item) => {
        menusList.value.push(item)
      })
      data.menuCount = res.data.total_records
      if (menusPagination.current == 1 && menusList.value.length > 0) {
        selectMenus(menusList.value[0])
      }
    })
    .catch((res) => {
      data.menuLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('查询微信消息失败！')
      }
    })
}
//置顶
const toTopClick = (record) => {
  data.topLoading = true
  let requestParam = {
    url: '/admin/v1/chatTop',
    method: 'post',
    data: {
      customer_id: record.customer_id,
      side: record.side,
      is_top: record.is_top == 1 ? 0 : 1
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      data.topLoading = false
      afterOpenChange(true)
    })
    .catch((res) => {
      data.topLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('置顶失败！')
      }
    })
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
