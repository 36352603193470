<template>
  <a-modal :title="props.tableColumnsData.full_name + '_发票'" :confirmLoading="state.loading" centered :bodyStyle="{ padding: '20px' }" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <a-popconfirm title="是否确定？" @confirm="onFormSubmit">
        <a-button :loading="state.loading" type="primary">确定申请发票</a-button>
      </a-popconfirm>
    </template>
    <!-- 申请发票记录 -->
    <a-table
      size="small"
      :loading="state.loading"
      style="margin-bottom: 30px"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :row-selection="{ selectedRowKeys: state.selectedRowKeys, type: 'radio', onChange: onSelectChange }"
      rowKey="id"
    >
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'action'"> {{ record.action_name }} </template>
      </template>
    </a-table>
    <!-- 表单提交发票需要的内容 -->
    <a-form ref="ruleForm" :rules="rules" :model="formState" v-bind="formLayout" scrollToFirstError="true">
      <a-form-item label="购买方名称" name="full_name" required>
        <a-input placeholder="" v-model:value="formState.full_name"></a-input>
      </a-form-item>
      <a-form-item label="统一社会信用代码" name="social_credit_code" required>
        <a-input placeholder="" v-model:value="formState.social_credit_code"></a-input>
      </a-form-item>
      <a-form-item label="发票备注" name="invoice_remark">
        <a-input placeholder="" v-model:value="formState.invoice_remark"></a-input>
      </a-form-item>
      <a-form-item label="开票金额" name="invoice_amount" required>
        <a-input placeholder="" v-model:value="formState.invoice_amount" prefix="￥" suffix="元" style="width: 140px" @blur="checkAmount"></a-input>
        <div v-if="formState.show_cha">
          <p>超出流水金额的部分，需提供超额发票。</p>
          <p>开票信息：<br />￥{{ formState.cha }}元<br />{{ state.platform_name }}<br />{{ state.platform_social_credit_code }}</p>
        </div>
      </a-form-item>
      <a-form-item label="差额发票" name="attachment" required v-if="formState.show_cha">
        <Upload
          :upload_path="`public/uploads/财务管理/发票申请/差额发票/${dayjs().format('YYYYMM')}/`"
          :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
          uploadType="text"
          v-model:value="formState.attachment"
          :limitation="99"
          uploadText="上传附件"
        >
        </Upload>
      </a-form-item>
      <a-form-item label="申请备注" name="remark ">
        <a-input placeholder="" v-model:value="formState.remark"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { reactive, onMounted, ref, computed, onUnmounted } from 'vue'
import dayjs from 'dayjs'
import Upload from '@/components/base/Upload.vue'
const emit = defineEmits(['cancel', 'ok'])
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const state = reactive<any>({
  loading: false,
  selectedRowKeys: [],
  selectedRecord: {}, //选中的order
  platform_name: '',
  platform_social_credit_code: ''
})
onMounted(() => {
  loadData()
})
//列表数据
const dataSource = ref([])
const columns: Array<Object> = [
  {
    title: '订单号',
    dataIndex: 'order_number',
    width: 250
  },
  {
    title: '流水金额',
    dataIndex: 'actually_paid_money',
    width: 200
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 200
  },
  {
    title: '类型',
    dataIndex: 'action',
    width: 200
  },
  {
    title: '付款时间',
    dataIndex: 'created_at',
    width: 200
  }
]
const loadData = () => {
  state.loading = true
  let requestParam = {
    url: '/admin/v1/customerInvoiceRecord',
    method: 'post',
    data: { customer_id: props.tableColumnsData.id }
  }
  baseService(requestParam)
    .then((res: any) => {
      state.loading = false
      dataSource.value = res.data.list
      formState.full_name = res.data.full_name
      formState.social_credit_code = res.data.social_credit_code
      state.platform_name = res.data.platform_name
      state.platform_social_credit_code = res.data.platform_social_credit_code
      checkAmount()
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('查询失败')
      }
    })
}

const onSelectChange = (selectedRowKeys: any[], val) => {
  state.selectedRowKeys = selectedRowKeys
  state.selectedRecord = val[0]
  formState.invoice_amount = val[0].actually_paid_money
  if (val[0].action == 'custRenewal' || val[0].action == 'custRegister') {
    formState.invoice_remark = val[0].full_name + val[0].before_enter_endtime + '~' + val[0].alter_enter_endtime + '入驻服务费'
  } else {
    formState.invoice_remark = ''
  }
}
//表单
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 }
  }
}
const ruleForm = ref()
const rules = []
const formState = reactive({
  full_name: '',
  social_credit_code: '',
  fee_standard: 1,
  invoice_amount: 0,
  invoice_remark: '',
  show_cha: false,
  cha: 0,
  attachment: [], //差额发票
  remark: '',
  order_id: 0,
  customer_id: 0
})
const checkAmount = () => {
  let actually_paid_money = state.selectedRecord.actually_paid_money ?? 0
  if (formState.invoice_amount > actually_paid_money) {
    formState.show_cha = true //上传差额发票
  } else if (formState.invoice_amount == actually_paid_money) {
    formState.show_cha = false
  } else {
    formState.invoice_amount = actually_paid_money
  }
  formState.cha = formState.invoice_amount - actually_paid_money
}

const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      if (!state.selectedRecord.id) {
        message.error('请选择开票订单')
        return
      }
      if (formState.invoice_amount <= 0) {
        message.error('请输入正确的开票金额')
        return
      }
      formState.order_id = state.selectedRecord.id
      formState.customer_id = props.tableColumnsData.id
      let requestParam = {
        url: '/admin/v1/customerInvoice',
        method: 'post',
        data: formState
      }
      baseService(requestParam)
        .then((res: any) => {
          message.success('提交成功')
          cancel()
        })
        .catch((res) => {
          state.loading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('申请失败')
          }
        })
    })
    .catch(() => {})
}
const cancel = () => {
  emit('cancel')
}
</script>
<style lang="scss" scoped></style>
