<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <!-- 选择材质 -->
  <a-modal
    :title="state.modalTitle"
    width="1200px"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    :maskClosable="false"
    @cancel="
      () => {
        emit('cancel')
      }
    "
    ok-text="确定"
    cancel-text="取消"
  >
    <a-spin :spinning="state.loading">
      <div style="padding: 20px 50px; font-size: 20px">
        <div>请选择{{ state.type_name }}型号</div>
        <div class="category">
          <div v-for="(item, index) in state.materialList" :key="index" @click="materialChange(item)" class="category-item" :class="{ check: state.materialId == item.id }">
            <div class="name">{{ item.name }}</div>
            <div class="image"><a-image :width="80" :height="80" :key="index" :src="$store.state.user.image_domain + item.thumb"></a-image></div>
            <p>￥{{ item.price }}</p>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>
<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, h, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
import Upload from '@/components/base/Upload.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
const $store = useStore()
let dayTime = dayjs()
import ls from '@/utils/Storage'
import { number } from 'echarts'
const user = ls.get('USER_INFO')
let timer: any = null
const emit = defineEmits(['cancel', 'ok'])

const props = defineProps({
  zhangType: {
    type: Number,
    default: 11 //默认是公章
  },
  defaultValue: {
    type: Object,
    default: () => null
  },
  optionList: {
    type: Array,
    default: () => []
  }
})

interface state {
  modalTitle: string
  loading: boolean
  materialId: number
  materialData: any
  materialList: any[]
  type_name: string
}
const state = reactive<state>({
  modalTitle: '选择材质',
  loading: false,
  materialId: 0,
  materialData: null,
  materialList: [],
  type_name: '公章'
})

onMounted(() => {
  if (props.defaultValue) {
    state.materialId = props.defaultValue.id
  }
  let rule = { 11: '公章', 12: '财务章', 13: '姓名章', 14: '合同专用章', 15: '发票专用章', 16: '其他类型章' }
  state.type_name = rule[props.zhangType]
  state.materialList = props.optionList
})
const materialChange = (item) => {
  state.materialId = item.id
  state.materialData = item
}
const onFormSubmit = () => {
  if (state.materialData && state.materialData.id > 0) {
    emit('ok', props.zhangType, state.materialData)
  } else {
    message.warning('请选择材质')
  }
}
</script>

<style lang="less" scoped>
.category {
  padding: 30px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  :deep(.ant-input-number-handler-wrap) {
    display: none;
  }

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 240px;
    margin-bottom: 20px;
    margin-right: 20px;
    text-align: center;
    .name {
      height: 80px;
      font-size: 19px;
    }
    .image {
      margin-top: 10px;
    }
  }

  .category-item:nth-child(1) {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }
  .category-item:nth-child(1).check {
    border: 1px solid #00d097;
  }

  .category-item:nth-child(2) {
    background: #e8f1ff;
    color: #3f8cff;
    border: 1px solid #e8f1ff;
  }

  .category-item:nth-child(2).check {
    border: 1px solid #3f8cff;
  }

  .category-item:nth-child(3) {
    border: 1px solid #e6fffb;
    background: #e6fffb;
    color: #13c2c2;
  }

  .category-item:nth-child(3).check {
    border: 1px solid #13c2c2;
  }

  .category-item:nth-child(4) {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }
  .category-item:nth-child(4).check {
    border: 1px solid #00d097;
  }

  .category-item:nth-child(5) {
    border: 1px solid yellow;
    background: #20e2c8;
    color: #fff;
  }
  .category-item:nth-child(5).check {
    border: 1px solid #00d097;
  }

  .category-item:nth-child(6) {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }
  .category-item:nth-child(6).check {
    border: 1px solid #00d097;
  }

  .category-item:nth-child(7) {
    background: #e8f1ff;
    color: #3f8cff;
    border: 1px solid #e8f1ff;
  }

  .category-item:nth-child(7).check {
    border: 1px solid #3f8cff;
  }

  .category-item:nth-child(8) {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }
  .category-item:nth-child(8).check {
    border: 1px solid #00d097;
  }
}

.m-0 {
  :deep(.ant-form-explain) {
    position: absolute;
  }
}
</style>
