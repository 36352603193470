<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Login',
})
</script>
<template>
  <div class="main">
    <a-form id="formLogin" class="user-layout-login" @submit="handleSubmit" :model="formRef">
      <a-tabs :activeKey="customActiveKey" :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }" @change="handleTabClick">
        <!-- 手机号登录 -->
        <a-tab-pane key="tab2" tab="微信扫码登录">
          <div id="wxqrcodeId" style="text-align: center"></div>
        </a-tab-pane>
        <!-- 账户密码登录 -->
        <a-tab-pane key="tab1" tab="账户密码登录">
          <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px" message="账户或密码错误" />
          <a-form-item v-bind="validateInfos.username">
            <a-input size="large" type="text" placeholder="账户" v-model:value="formRef.username">
              <template #prefix>
                <UserOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item v-bind="validateInfos.password">
            <a-input-password size="large" placeholder="密码" v-model:value="formRef.password">
              <template #prefix>
                <LockOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
              </template>
            </a-input-password>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>

      <!-- <a-form-item v-bind="validateInfos.rememberMe">
                <a-checkbox v-model:checked="formRef.rememberMe" style="float:left">
                    自动登录
                </a-checkbox>
                <router-link :to="{ name: 'recover', params: { user: 'aaa' } }" class="forge-password"
                    style="float: right">忘记密码</router-link>
            </a-form-item> -->

      <a-form-item style="margin-top: 24px" v-if="customActiveKey == 'tab1'">
        <a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn">登录</a-button>
      </a-form-item>
    </a-form>
    <p style="line-height: 24px; color: rgba(0, 0, 0, 0.45); border: 3px dashed #ccc; background: yellow; color: red">
      提示：如果是360浏览器扫码，网址栏的重定向要放开，具体位置如图：<a-image :width="50" src="https://hncs.cskaiyela.com/storage/uploads/images/3601.png" /> &nbsp;&nbsp;<a-image
        :width="50"
        src="https://hncs.cskaiyela.com/storage/uploads/images/3602.png"
      />（图片可以点击）,打开即可，推荐chrome浏览器扫码登录，不会出现这个问题。不支持360浏览器兼容模式，请用极速模式。
    </p>
    <!-- 微信登录的填手机验证码弹窗 -->
    <a-modal
      v-model:visible="state.wxMobileVisible"
      :title="'请输入手机号码绑定登录 ' + state.wxMobileData.wx_nickname"
      @ok="wxhandleOk"
      @cancel="
        () => {
          state.wxMobileVisible = false
        }
      "
    >
      <a-form :model="state.wxMobileData">
        <a-form-item>
          <a-input size="large" type="text" placeholder="手机号" v-model:value="state.wxMobileData.mobile">
            <MobileOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
          </a-input>
        </a-form-item>

        <a-row :gutter="16">
          <a-col class="gutter-row" :span="16">
            <a-form-item v-bind="validateInfos.captcha">
              <a-input size="large" type="text" placeholder="验证码" v-model:value="state.wxMobileData.captcha">
                <MailOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="8">
            <a-button class="getCaptcha" tabindex="-1" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha">
              {{ (!state.smsSendBtn && '获取验证码') || state.time + ' s' }}
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts" setup name="Login">
import { ref, reactive, UnwrapRef, onMounted } from 'vue'
import { Form } from 'ant-design-vue'
import { loginSuccess, requestFailed } from './helper'
import { useRouter } from 'vue-router'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { FormState } from './types'
import generateAsyncRoutes from '@/router/generateAsyncRoutes'
import ls from '@/utils/Storage'
import store from '@/store'
import { nextTick, watch } from 'vue'
import baseService from '@/utils/http/axios'
import { message, Modal, Table } from 'ant-design-vue'

const useForm = Form.useForm
const router = useRouter()

onMounted(() => {
  //判断是否为微信授权扫码回来
  let code = router.currentRoute.value.query.code
  let state = router.currentRoute.value.query.state
  if (code && state) {
    wechatLoginCode(code, state)
  } else {
    wechatLogin()
  }
})

const state = reactive({
  time: 60,
  loginBtn: false,
  // login type: 0 email, 1 name, 2 telephone
  loginType: 0,
  smsSendBtn: false,
  wxMobileVisible: false,
  wxMobileData: {
    mobile: '',
    captcha: '',
    wx_headimgurl: '',
    wx_nickname: '',
    unionid: '',
    openid: ''
  }
})

// #region 表单相关
const formRef: UnwrapRef<FormState> = reactive({
  // rememberMe: false,
  username: '',
  password: ''
  // mobile: '',
  // captcha: ''
})

const handleUsernameOrEmail = (rule, value: string) => {
  const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
  if (regex.test(value)) {
    state.loginType = 0
  } else {
    state.loginType = 1
  }
  return Promise.resolve()
}
const rulesRef = reactive({
  rememberMe: [{ trigger: 'checked' }],
  username: [
    {
      required: true,
      message: '请输入帐户名'
    },
    {
      validator: handleUsernameOrEmail,
      trigger: 'change'
    }
  ],
  password: [{ required: true, message: '请输入密码！' }, {}],
  mobile: [
    {
      required: true,
      pattern: /^1[34578]\d{9}$/,
      message: '手机号',
      validateTrigger: 'change'
    }
  ],
  captcha: [
    {
      required: true,
      message: '请输入验证码！',
      validateTrigger: 'blur'
    }
  ]
})
const { validate, validateInfos } = useForm(formRef, rulesRef)
const isLoginError = ref(false)
const handleSubmit = (e: Event) => {
  e.preventDefault()
  state.loginBtn = true
  const validateFieldsKey = customActiveKey.value === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha']

  validate(validateFieldsKey)
    .then(() => {
      store
        .dispatch('Login', formRef)
        .then((res) => {
          store.dispatch('GetInfo')
          // TODO 默认配置中使用服务端获取菜单
          // if (config.useAsyncRouter) {
          //     generateAsyncRoutes(router, res.menu)
          // }
          loginSuccess(router, res)
          isLoginError.value = false
          state.loginBtn = false
        })
        .catch((res) => {
          requestFailed(res)
          isLoginError.value = true
          formRef.password = ''
          state.loginBtn = false
        })
    })
    .catch((e) => {
      state.loginBtn = false
    })
}
// #endregion

//#region 切换tab
const customActiveKey = ref<string>('tab2')
const handleTabClick = (key: string) => {
  customActiveKey.value = key
  if (key == 'tab2') {
    wechatLogin()
  }
}
//#endregion

//微信扫码登录
const wechatLogin = () => {
  nextTick(async () => {
    //调用后台接口获取账号
    let requestParam = {
      url: '/admin/v1/loginwx',
      method: 'post',
      data: {
        step: 1
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        let appid = res.data
        let redirect = router.currentRoute.value.query.redirect + ''
        let state = encodeURIComponent(redirect)
        //@ts-ignore
        new WxLogin({
          self_redirect: false,
          id: 'wxqrcodeId', //放置二维码的容器ID
          appid: appid,
          scope: 'snsapi_login',
          redirect_uri: encodeURIComponent('https://console.cskaiyela.com/user/login'), //编码重定向地址
          state: state,
          style: '',
          href: ''
        })
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  })
}
const wechatLoginCode = (code, param) => {
  if (param && param != 'undefined') {
    let redirect = decodeURIComponent(param)
    router.currentRoute.value.query.redirect = redirect
  }
  //根据code获取access_token
  let requestParam = {
    url: '/admin/v1/loginwx',
    method: 'post',
    data: {
      step: 2,
      code: code
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      if (res.msg == 'mobile') {
        //提示填写手机号和验证码
        state.wxMobileData = Object.assign({ mobile: '', captcha: '' }, res.data)
        state.wxMobileVisible = true
      } else if (res.msg == 'login') {
        wchartLoginFinal(res.data.id, res.data.openid)
      }
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const wchartLoginFinal = (id, openid) => {
  state.wxMobileVisible = false
  store
    .dispatch('WxScanUser', { id: id, openid: openid })
    .then((res) => {
      store.dispatch('GetInfo')
      loginSuccess(router, res)
      isLoginError.value = false
      state.loginBtn = false
    })
    .catch((res) => {
      requestFailed(res)
      isLoginError.value = true
      formRef.password = ''
      state.loginBtn = false
    })
}
const getCaptcha = () => {
  if (state.wxMobileData.mobile == '') {
    message.error('请输入手机号')
    return
  }
  //判断手机号格式是否正确
  if (!/^1[3456789]\d{9}$/.test(state.wxMobileData.mobile)) {
    message.error('请输入正确的手机号')
    return
  }
  state.smsSendBtn = true
  let interval = window.setInterval(() => {
    if (state.time-- <= 0) {
      state.time = 60
      state.smsSendBtn = false
      window.clearInterval(interval)
    }
  }, 1000)
  let requestParam = {
    url: '/admin/v1/loginwx',
    method: 'post',
    data: {
      mobile: state.wxMobileData.mobile,
      step: 3
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success('发送成功')
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const wxhandleOk = () => {
  if (state.wxMobileData.mobile == '') {
    message.error('请输入手机号')
    return
  }
  if (state.wxMobileData.captcha == '') {
    message.error('请输入验证码')
    return
  }
  let requestParam = {
    url: '/admin/v1/loginwx',
    method: 'post',
    data: {
      step: 4,
      mobile: state.wxMobileData.mobile,
      captcha: state.wxMobileData.captcha,
      openid: state.wxMobileData.openid,
      unionid: state.wxMobileData.unionid,
      wx_nickname: state.wxMobileData.wx_nickname,
      wx_headimgurl: state.wxMobileData.wx_headimgurl
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      wchartLoginFinal(res.data.id, res.data.openid)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped>
@import '../../style/index.less';

.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .anticon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
  }
}
</style>
