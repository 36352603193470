<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1200"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    :maskClosable="false"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" scrollToFirstError :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="是否启用" name="status">
          <a-radio-group button-style="solid" v-model:value="formState.status">
            <a-radio-button :value="1">启用</a-radio-button>
            <a-radio-button :value="2">禁用</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否开启短信通知" name="smsflag">
          <a-radio-group button-style="solid" v-model:value="formState.smsflag">
            <a-radio-button :value="1">开启</a-radio-button>
            <a-radio-button :value="2">关闭</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="地址编号增长方式" name="numbering_increment_mode">
          <a-radio-group button-style="solid" v-model:value="formState.numbering_increment_mode">
            <a-radio-button :value="1">单轨制</a-radio-button>
            <a-radio-button :value="2">双轨制</a-radio-button>
            <a-radio-button :value="3">固定编号制</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="限制类型" name="limit_business_types">
          <a-checkbox-group v-model:value="formState.limit_business_types">
            <a-checkbox :value="1">设立</a-checkbox>
            <a-checkbox :value="2">同区迁入</a-checkbox>
            <a-checkbox :value="3">跨区迁入</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="平台名称" name="name">
          <a-input placeholder="请输入平台名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="平台简称" name="platform_abbreviation">
          <a-input placeholder="请输入平台简称" v-model:value="formState.platform_abbreviation"></a-input>
        </a-form-item>
        <a-form-item label="统一社会信用代码" name="social_credit_code">
          <a-input placeholder="请输入统一社会信用代码" v-model:value="formState.social_credit_code"></a-input>
        </a-form-item>
        <a-form-item
          name="latitude_longitude"
          :label="
            h(
              'span',
              {},
              {
                default: () => [
                  h('span', '公司地址经纬度'),
                  h(
                    Tooltip,
                    { title: '点击选取公司地址经纬度' },
                    {
                      default: () => [h(InfoCircleOutlined, { onClick: mapClick, class: ['ml-x'] })]
                    }
                  )
                ]
              }
            )
          "
        >
          <a-input placeholder="请输入公司地址经纬度" v-model:value="formState.latitude_longitude">
            <!-- <template #suffix>
                            <a-tooltip title="点击获取经纬信息">
                                <a-icon @click="mapClick" type="info-circle" style="color: rgba(0, 0, 0, 0.45)" />
                            </a-tooltip>
                        </template> -->
          </a-input>
        </a-form-item>
        <a-form-item label="公司地址省市区" name="company_area_ids">
          <AddressCascader
            v-model:value="formState.company_area_ids"
            @change="
              (res, e) => {
                companyPauaChange(res, e, 'company_detailed_address')
              }
            "
          ></AddressCascader>
        </a-form-item>
        <a-form-item label="公司注册地址" name="company_detailed_address">
          <a-input placeholder="请输入公司注册地址" v-model:value="formState.company_detailed_address"> </a-input>
        </a-form-item>
        <a-form-item label="邮政编码" name="zipcode">
          <a-input placeholder="请输入邮政编码" v-model:value="formState.zipcode"> </a-input>
        </a-form-item>
        <a-form-item label="注册地址匹配关键词">
          <associate-authorization
            v-model:value="formState.company_detailed_address_keywords"
            placeholder="请输入注册地址匹配关键词"
            suffixTitle="回车或失去焦点后可以添加多个关键词"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="公司地址详情介绍" name="company_address" :labelCol="{ span: 7 }" :wrapperCol="{ span: 15 }">
          <Ueditor v-model:value="formState.company_address" uid="u1"></Ueditor>
        </a-form-item>
        <a-form-item label="请输入平台价格详情介绍" name="platform_price_list" :labelCol="{ span: 7 }" :wrapperCol="{ span: 15 }">
          <Ueditor v-model:value="formState.platform_price_list" uid="u2"></Ueditor>
        </a-form-item>
        <a-form-item label="邮寄成功提示语" name="logistics_order_cue">
          <a-textarea v-model:value="formState.logistics_order_cue" placeholder="请输入邮寄成功提示语" :rows="3"></a-textarea>
        </a-form-item>

        <a-form-item label="平台核准日期" name="ratify_time">
          <a-date-picker v-model:value="formState.ratify_time" format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item label="主管机关" name="company_authority">
          <Institution v-model:value="formState.company_authority"></Institution>
        </a-form-item>
        <a-row>
          <a-col :span="12"
            ><a-form-item label="公司地址编号前缀" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
              <associate-auth-and-check
                v-model:value="formState.company_address_number_prefix"
                :formState="formState"
                placeholder="请输入公司地址编号前缀"
                suffixTitle="回车或失去焦点后可以添加多个公司地址编号前缀"
                inputType="text"
              ></associate-auth-and-check>
            </a-form-item>
            <a-form-item label="公司地址编号后缀" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
              <associate-auth-and-check-after
                v-model:value="formState.company_address_number_postfix"
                placeholder="请输入公司地址编号后缀"
                :formState="formState"
                suffixTitle="回车或失去焦点后可以添加多个公司地址编号后缀"
                inputType="text"
              ></associate-auth-and-check-after> </a-form-item
          ></a-col>
          <a-col :span="12"
            ><a-form-item label="个体户地址编号前缀" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
              <associate-auth-and-check
                v-model:value="formState.individual_address_number_prefix"
                placeholder="请输入个体户地址编号前缀"
                suffixTitle="回车或失去焦点后可以添加多个个体户地址编号前缀"
                inputType="text"
                :formState="formState"
              ></associate-auth-and-check>
            </a-form-item>
            <a-form-item label="个体户地址编号后缀" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
              <associate-auth-and-check-after
                v-model:value="formState.individual_address_number_postfix"
                placeholder="请输入个体户地址编号后缀"
                suffixTitle="回车或失去焦点后可以添加多个个体户地址编号后缀"
                inputType="text"
                :formState="formState"
              ></associate-auth-and-check-after> </a-form-item
          ></a-col>
        </a-row>

        <div class="form-tips-title">平台角色配置</div>
        <a-form-item label="平台管理员手机号码">
          <associate-authorization
            v-model:value="formState.super_admin_tels"
            placeholder="请输入平台管理员手机号码"
            suffixTitle="回车或失去焦点后可以添加多个平台管理员手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="行政负责人手机号码">
          <associate-authorization
            v-model:value="formState.store_manager_tels"
            placeholder="请输入行政负责人手机号码"
            suffixTitle="回车或失去焦点后可以添加多个行政负责人手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="业务对接人电话" name="business_contact_tel" :required="formState.platform_model_type == 2">
          <associate-authorization
            v-model:value="formState.business_contact_tel"
            placeholder="请输入业务对接人电话"
            suffixTitle="回车或失去焦点后可以添加多个日常行政业务对接人电话"
            inputType="number"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="审核专员手机号码">
          <associate-authorization
            v-model:value="formState.audit_admin_tels"
            placeholder="请输入审核专员手机号码"
            suffixTitle="回车或失去焦点后可以添加多个审核专员手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="财务手机号码">
          <associate-authorization
            v-model:value="formState.finance_tels"
            placeholder="请输入财务手机号码"
            suffixTitle="回车或失去焦点后可以添加多个财务手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="异常投诉专员手机号码">
          <associate-authorization
            v-model:value="formState.ycts_tels"
            placeholder="请输入异常投诉专员手机号码"
            suffixTitle="回车或失去焦点后可以添加多个异常投诉专员手机号码"
            inputType="text"
          ></associate-authorization>
        </a-form-item>
        <a-form-item label="是否有营业执照">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.ishave_business_license"></a-switch>
        </a-form-item>
        <a-form-item label="是否开放一次性地址">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.disposable_switch"></a-switch>
        </a-form-item>

        <a-form-item label="是否有章">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.ishave_seal"></a-switch>
        </a-form-item>
        <a-form-item label="默认是否要政府赠送的公章" v-if="formState.ishave_seal == 1">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.iswant_seal"></a-switch>
        </a-form-item>

        <a-form-item label="平台模式">
          <a-radio-group button-style="solid" v-model:value="formState.platform_model">
            <a-radio-button :value="1">自营</a-radio-button>
            <a-radio-button :value="2">深度联营</a-radio-button>
            <a-radio-button :value="3">合作</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="平台佣金比例" name="commission_rate">
          <a-input placeholder="请输入平台分给服务商的佣金比例" v-model:value="formState.commission_rate"> </a-input>
        </a-form-item>
        <a-form-item label="运行模式">
          <a-radio-group button-style="solid" v-model:value="formState.platform_model_type">
            <a-radio-button :value="1">自动（可直接提交，无需人工审核）</a-radio-button>
            <a-radio-button :value="2">手动（需人工审核，合作地址方）</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="最大允许编号" name="maximum_number">
          <a-input placeholder="请输入最大允许编号" v-model:value="formState.maximum_number"></a-input>
        </a-form-item>
        <a-form-item label="是否提供裸地址">
          <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.is_provide_adds"></a-switch>
        </a-form-item>
        <a-form-item label="是否提供一次性地址">
          <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.is_provide_ycx"></a-switch>
        </a-form-item>
        <a-form-item label="公司类主体后加字">
          <a-input placeholder="请输入公司类主体后加字" v-model:value="formState.gs_add_words"></a-input>
        </a-form-item>
        <a-form-item label="个体类主体后加字">
          <a-input placeholder="请输入个体类主体后加字" v-model:value="formState.gt_add_words"></a-input>
        </a-form-item>
        <a-form-item label="入驻协议乙方姓名">
          <a-radio-group v-model:value="formState.yifang_name_type">
            <a-radio :value="1">主体名称</a-radio>
            <a-radio :value="2">主体法人代表或负责人姓名</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="平台公章" name="official_seal">
          <UploadTmp uploadType="picture-card" :limitation="1" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.official_seal" uploadText="上传平台公章"> </UploadTmp>
        </a-form-item>
        <div class="form-tips-title">公司类注册信息配置</div>
        <a-form-item label="刻章材料接收人微信" name="kezhang_wxname">
          <a-input placeholder="请输入刻章材料接收人微信" v-model:value="formState.kezhang_wxname"></a-input>
        </a-form-item>
        <a-form-item label="经营范围开放类别">
          <a-checkbox-group v-model:value="formState.is_provide_company_xuke">
            <a-checkbox :value="0">一般项目</a-checkbox>
            <a-checkbox :value="1">前置许可</a-checkbox>
            <a-checkbox :value="2">后置许可</a-checkbox>
            <a-checkbox :value="4">一般事项（需备案）</a-checkbox>
            <a-checkbox :value="6">负面清单事项</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="公司类主体经营范围是否必须内资适用">
          <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="formState.jyfw_is_nzsy_company"></a-switch>
        </a-form-item>
        <a-form-item label="公司类录单强制填写收货地址">
          <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="formState.is_must_recive_address_company"></a-switch>
        </a-form-item>
        <a-form-item label="执照领取方式(公司)" name="licence_receive_ngt">
          <a-radio-group button-style="solid" v-model:value="formState.licence_receive_ngt">
            <a-radio-button :value="1">到登记机关窗口领取</a-radio-button>
            <a-radio-button :value="2">工商局邮寄</a-radio-button>
            <a-radio-button :value="3">自助机领取</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="公司默认外勤账号（拿证人）" name="dzh_account_id_ngt_main">
          <DzhAccountPick v-model:value="formState.dzh_account_id_ngt.main"></DzhAccountPick>
        </a-form-item>
        <a-form-item label="公司外勤（拿证人）特殊规则" name="dzh_account_id_ngt_other">
          <a-space v-for="(other, index) in formState.dzh_account_id_ngt.other" style="display: flex; margin-bottom: 8px">
            当录单人账号绑定的手机号码为：<a-form-item-rest><a-input placeholder="请输入手机号" v-model:value="other.tel"></a-input></a-form-item-rest> 时，外勤电子化账号为：<a-form-item-rest
              ><DzhAccountPick v-model:value="other.account"></DzhAccountPick
            ></a-form-item-rest>

            <MinusCircleOutlined @click="removeNgtOther(other)" />
          </a-space>
          <a-form-item>
            <a-button type="dashed" block @click="addNgtOther">
              <PlusOutlined />
              增加数据
            </a-button>
          </a-form-item></a-form-item
        >
        <div class="form-tips-title">个体户注册信息配置</div>
        <a-form-item label="经营范围开放类别">
          <a-checkbox-group v-model:value="formState.is_provide_individual_xuke">
            <a-checkbox :value="0">一般项目</a-checkbox>
            <a-checkbox :value="1">前置许可</a-checkbox>
            <a-checkbox :value="2">后置许可</a-checkbox>
            <a-checkbox :value="4">一般事项（需备案）</a-checkbox>
            <a-checkbox :value="6">负面清单事项</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="个体类主体经营范围是否必须个体适用">
          <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="formState.jyfw_is_nzsy_geti"></a-switch>
        </a-form-item>
        <a-form-item label="个体类录单强制填写收货地址">
          <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="是" un-checked-children="否" v-model:checked="formState.is_must_recive_address_geti"></a-switch>
        </a-form-item>
        <a-form-item label="执照领取方式(个体)" name="licence_receive_gt">
          <a-radio-group button-style="solid" v-model:value="formState.licence_receive_gt">
            <a-radio-button :value="1">到登记机关窗口领取</a-radio-button>
            <a-radio-button :value="2">工商局邮寄</a-radio-button>
            <a-radio-button :value="3">自助机领取</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="个体默认外勤账号（拿证人）" name="dzh_account_id_gt_main">
          <DzhAccountPick v-model:value="formState.dzh_account_id_gt.main"></DzhAccountPick>
        </a-form-item>
        <a-form-item label="个体外勤（拿证人）特殊规则" name="dzh_account_id_gt_other">
          <a-space v-for="(other, index) in formState.dzh_account_id_gt.other" style="display: flex; margin-bottom: 8px">
            当录单人账号绑定的手机号码为：<a-form-item-rest><a-input placeholder="请输入手机号" v-model:value="other.tel"></a-input></a-form-item-rest> 时，外勤电子化账号为：<a-form-item-rest
              ><DzhAccountPick v-model:value="other.account"></DzhAccountPick>
            </a-form-item-rest>

            <MinusCircleOutlined @click="removeGtOther(other)" />
          </a-space>
          <a-form-item>
            <a-button type="dashed" block @click="addGtOther">
              <PlusOutlined />
              增加数据
            </a-button>
          </a-form-item></a-form-item
        >
        <!-- 公司个人存放地址 -->
        <div class="form-tips-title">运营类相关配置</div>

        <a-row>
          <a-col :span="12">
            <a-form-item label="公司执照存放地址(省市区)" name="company_bl_area_ids" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <AddressCascader
                v-model:value="formState.company_bl_area_ids"
                @change="
                  (res, e) => {
                    companyPauaChange(res, e, 'company_bl_address')
                  }
                "
              ></AddressCascader>
            </a-form-item>
            <a-form-item label="公司执照存放详细地址" name="company_bl_address" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入公司执照存放详细地址" v-model:value="formState.company_bl_address" style="width: 300px"></a-input>
            </a-form-item>
            <a-form-item label="公司类运营主体名称" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入公司类运营主体名称" v-model:value="formState.company_name" style="width: 300px"></a-input>
            </a-form-item>

            <a-form-item label="公司类运营主体微信收款appid" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入公司类运营主体微信收款appid" v-model:value="formState.company_wxappid" style="width: 300px"></a-input>
            </a-form-item>

            <a-form-item label="公司类运营主体微信收款appkey" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入公司类运营主体微信收款appkey" v-model:value="formState.company_wxappkey" style="width: 300px"></a-input> </a-form-item></a-col
          ><a-col :span="12">
            <a-form-item label="个人执照存放地址(省市区)" name="individual_bl_area_ids" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <AddressCascader
                v-model:value="formState.individual_bl_area_ids"
                @change="
                  (res, e) => {
                    companyPauaChange(res, e, 'individual_bl_address')
                  }
                "
              ></AddressCascader>
            </a-form-item>

            <a-form-item label="个人执照存放详细地址" name="individual_bl_address" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入个人详细地址" v-model:value="formState.individual_bl_address" style="width: 300px"></a-input>
            </a-form-item>
            <a-form-item label="个体类运营主体名称" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入个体类运营主体名称" v-model:value="formState.individual_name" style="width: 300px"></a-input>
            </a-form-item>

            <a-form-item label="个体类运营主体微信收款appid" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入个体类运营主体微信收款appid" v-model:value="formState.individual_wxappid" style="width: 300px"></a-input>
            </a-form-item>

            <a-form-item label="个体类运营主体微信收款appkey" :labelCol="{ span: 9 }" :wrapperCol="{ span: 18 }">
              <a-input placeholder="请输入个体类运营主体微信收款appkey" v-model:value="formState.individual_wxappkey" style="width: 300px"></a-input>
            </a-form-item> </a-col
        ></a-row>

        <div class="form-tips-title">限制、风控、平台属性配置</div>
        <a-row>
          <a-col :span="12">
            <a-form-item label="限制公司注册时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14 }">
              <range-picker v-model:value="formState.company_register_time" style="width: 300px" /> </a-form-item></a-col
          ><a-col :span="12">
            <a-form-item label="限制个体注册时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14 }">
              <range-picker v-model:value="formState.individual_register_time" style="width: 300px" /> </a-form-item
          ></a-col>
          <a-col :span="12">
            <a-form-item label="公司签完字是否最终提交" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14 }">
              <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.checkstatusrobotsubmitflag[0]"></a-switch>
              （状态机器人动作）</a-form-item
            ></a-col
          ><a-col :span="12">
            <a-form-item label="个体签完字是否最终提交" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14 }">
              <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.checkstatusrobotsubmitflag[1]"></a-switch>
              （状态机器人动作）</a-form-item
            ></a-col
          >
        </a-row>
        <a-form-item label="手机号码实名认证">
          <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.shiming_flag"></a-switch>
        </a-form-item>
        <a-form-item v-if="formState.shiming_flag == 1" label="核验手机号码时间">
          <a-input style="width: 200px" addonAfter="月" v-model:value="formState.shiming_telusetime"></a-input>
        </a-form-item>
        <a-form-item label="未付款数量最多">
          <a-input style="width: 200px" addonAfter="家" v-model:value="formState.unpaid_num"></a-input>
        </a-form-item>
        <a-form-item label="未办结数量最多">
          <a-input style="width: 200px" addonAfter="家" v-model:value="formState.unfinished_num"></a-input>
        </a-form-item>
        <a-form-item label="账期天数">
          <a-input style="width: 200px" addonAfter="天" v-model:value="formState.period_days"></a-input>
        </a-form-item>
        <a-form-item label="同负责人、同法人代表同时注册的数量">
          <a-input style="width: 200px" addonAfter="个" v-model:value="formState.register_number"></a-input>
        </a-form-item>
        <a-form-item label="推送做单-遮挡租金">
          <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="不遮挡" un-checked-children="遮挡" v-model:checked="formState.zujin_zd"></a-switch>
        </a-form-item>
        <div class="form-tips-title">开启人工核验条件（如不设置则忽略此条件）</div>
        <a-form-item label="手机号码24小时核验次数超过">
          <a-input style="width: 200px" addonAfter="次" v-model:value="formState.check_tel_times"></a-input>
        </a-form-item>
        <a-form-item label="本省身份证标识">
          <a-input style="width: 200px" v-model:value="formState.bensheng_flag"></a-input>
        </a-form-item>
        <a-form-item label="年龄大于"> <a-input style="width: 200px" addonAfter="岁必须实名认证" v-model:value="formState.age_gt"></a-input> </a-form-item>
        <a-form-item label="年龄小于"> <a-input style="width: 200px" addonAfter="岁必须实名认证" v-model:value="formState.age_lt"></a-input> </a-form-item>
        <div class="form-tips-title">微信商户配置</div>
        <a-form-item label="子商户APPID" name="sub_appid">
          <a-input placeholder="子商户APPID" v-model:value="formState.sub_appid"></a-input>
        </a-form-item>
        <a-form-item label="子商户号" name="sub_mchid">
          <a-input placeholder="子商户号" v-model:value="formState.sub_mchid"></a-input>
        </a-form-item>
        <a-form-item label="提供的微信支付费率" name="sub_wechatpay_rates">
          <a-input placeholder="提供的微信支付费率" v-model:value="formState.sub_wechatpay_rates"></a-input>
        </a-form-item>
        <a-form-item label="直接客户支付的超额部分的返佣服务费费率" name="sub_wechatpay_rates_overflow">
          <a-input placeholder="直接客户支付的超额部分的返佣服务费费率" v-model:value="formState.sub_wechatpay_rates_overflow"></a-input>
        </a-form-item>
        <div class="form-tips-title">电子化模板</div>
        <a-form-item label="选择公司电子化核名承诺书模板">
          <select-template v-model:value="formState.company_online_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择公司电子化地址资料模板" name="company_online_templatedz_ids">
          <select-template v-model:value="formState.company_online_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择公司电子化其他资料模板">
          <select-template v-model:value="formState.company_online_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个体电子化核名承诺书模板">
          <select-template v-model:value="formState.individual_online_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个体电子化地址资料模板" name="individual_online_templatedz_ids">
          <select-template v-model:value="formState.individual_online_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个体电子化其他资料模板">
          <select-template v-model:value="formState.individual_online_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个独电子化核名承诺书模板">
          <select-template v-model:value="formState.proprietorships_online_templatedz_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个独电子化地址资料模板" name="proprietorships_online_templatecn_ids">
          <select-template v-model:value="formState.proprietorships_online_templatecn_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个独电子化其他资料模板">
          <select-template v-model:value="formState.proprietorships_online_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>

        <div class="form-tips-title">现场模板</div>
        <a-form-item label="选择公司现场核名承诺书模板">
          <select-template v-model:value="formState.company_scene_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择公司现场地址资料模板" name="company_scene_templatedz_ids">
          <select-template v-model:value="formState.company_scene_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择公司现场其他资料模板">
          <select-template v-model:value="formState.company_scene_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个体现场核名承诺书模板">
          <select-template v-model:value="formState.individual_scene_templatecn_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个体现场地址资料模板" name="individual_scene_templatedz_ids">
          <select-template v-model:value="formState.individual_scene_templatedz_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个体现场其他资料模板">
          <select-template v-model:value="formState.individual_scene_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>
        <a-divider />
        <a-form-item label="选择个独现场核名承诺书模板">
          <select-template v-model:value="formState.proprietorships_scene_templatedz_ids" :options="templateObj.letterOfCommitment"></select-template>
        </a-form-item>
        <a-form-item label="选择个独现场地址资料模板" name="proprietorships_scene_templatecn_ids">
          <select-template v-model:value="formState.proprietorships_scene_templatecn_ids" :options="templateObj.address"></select-template>
        </a-form-item>
        <a-form-item label="选择个独现场其他资料模板">
          <select-template v-model:value="formState.proprietorships_scene_templateqt_ids" :options="templateObj.other"></select-template>
        </a-form-item>

        <div class="form-tips-title">银行开户资料</div>
        <a-form-item label="选择公司给客户模板">
          <select-template v-model:value="formState.company_client_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="选择个体户给客户模板">
          <select-template v-model:value="formState.individual_client_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="选择个人独资企业给客户模板">
          <select-template v-model:value="formState.proprietorships_client_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-divider />
        <div class="form-tips-title">其他模板</div>
        <a-form-item label="续费合同">
          <select-template v-model:value="formState.xufeihetong_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="催款告知书">
          <select-template v-model:value="formState.xufeigaozhishu_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="解除入驻合同模版">
          <select-template v-model:value="formState.jiechuhetong_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="列入异常模板">
          <select-template v-model:value="formState.lieruyichang_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移出地址异常合同模版">
          <select-template v-model:value="formState.yichuyichang_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="工单汇报模板">
          <select-template v-model:value="formState.ticketreporting_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="刻章模板">
          <select-template v-model:value="formState.kezhang_template_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-divider />
        <div class="form-tips-title">案件线索移送函专区</div>
        <a-form-item label="移送阿里巴巴模板">
          <select-template v-model:value="formState.yisong_alibaba_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移送淘宝模板">
          <select-template v-model:value="formState.yisong_taobao_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移送抖音模板">
          <select-template v-model:value="formState.yisong_douyin_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-form-item label="移送拼多多模板">
          <select-template v-model:value="formState.yisong_pinduoduo_ids" :options="templateObj.all"></select-template>
        </a-form-item>
        <a-divider />
      </a-form>
    </a-spin>
    <image-cropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :isId="false"
      :upload_path="`public/uploads/${state.company_detailed_address}/${formState.name}/平台资料/`"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      @change="cropperChange"
      v-if="state.showCropper"
    ></image-cropper>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, h } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import ImageCropper from '@/components/ImageCropper/index.vue'
import dayjs from 'dayjs'
import Institution from './Institution.vue'
import { debounce } from 'lodash-es'
import cloneDeep from 'lodash.clonedeep'
import SelectTemplate from './SelectTemplate.vue'
import Ueditor from '@/components/Ueditor/index.vue'
import { Tooltip } from 'ant-design-vue'
import { PlusOutlined, MinusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
import { AddressCascader, AssociateAuthorization, AssociateAuthAndCheck, RangePicker, AssociateAuthAndCheckAfter } from '@/components'
import { useStore } from 'vuex'
import UploadTmp from '@/components/base/UploadTmp.vue'
import DzhAccountPick from '@/components/base/DzhAccountPick.vue'
const $store = useStore()
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const templateObj = reactive<any>({
  // 全部
  all: [],
  // 承诺书
  letterOfCommitment: [],
  // 地址资料
  address: [],
  // 其他资料
  other: []
})

let timer: any = null
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const checkAuthority = (_rule: any, value: any) => {
  if (!value) {
    return Promise.reject('请输入机关')
  }
  if (!value.glj1) {
    return Promise.reject('请输入市机关')
  }
  if (!value.glj2) {
    return Promise.reject('请输入区机关')
  }
  if (!value.jd) {
    return Promise.reject('请输入街道机关')
  }
  if (!value.gls) {
    return Promise.reject('请输入所机关')
  }
  return Promise.resolve()
}
const rules = {
  name: [{ required: true, message: '请输入平台名称' }],
  platform_abbreviation: [{ required: true, message: '请输入平台简称' }],
  social_credit_code: [{ required: true, message: '请输入统一社会信用代码' }],
  latitude_longitude: [{ required: true, message: '请输入公司地址经纬度' }],
  company_area_ids: [{ required: true, message: '请选择公司地址省市区' }],
  company_detailed_address: [{ required: true, message: '请输入公司详细地址' }],
  company_address: [{ required: true, message: '请输入公司地址详情介绍' }],
  platform_price_list: [{ required: false, message: '请输入平台价格详情介绍' }],
  ratify_time: [{ required: true, message: '请选择批准核准时间' }],
  company_authority: [{ required: true, validator: checkAuthority }],

  company_bl_area_ids: [{ required: true, message: '请选择公司执照存放地址' }],
  company_bl_address: [{ required: true, message: '请输入公司执照存放详细地址' }],
  individual_bl_area_ids: [{ required: true, message: '请选择个人执照存放地址' }],
  individual_bl_address: [{ required: true, message: '请输入个人执照存放详细地址' }],
  company_register_time: [{ required: true, message: '请选择限制公司注册时间' }],
  individual_register_time: [{ required: true, message: '请选择限制个体注册时间' }],
  maximum_number: [{ required: true, message: '请输入最大允许编号' }],
  yifang_name_type: [{ required: true, message: '请选择入驻协议乙方姓名类型' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
  showCropper: boolean
  imageUrl: string
  imageName: string
  currentUpload: string
  company_detailed_address: string
  selectIndividualOptions: any[]
  selectCompanyOptions: any[]
  company_agent_wxname: string
  individual_agent_wxname: string
}
const state = reactive<state>({
  modalTitle: '新增平台',
  loading: false,
  showCropper: false,
  imageUrl: '',
  imageName: '',
  currentUpload: '',
  company_detailed_address: '',
  selectCompanyOptions: [],
  selectIndividualOptions: [],
  company_agent_wxname: '',
  individual_agent_wxname: ''
})
interface dzhOther {
  tel: string
  account: number
}
interface dzhAccount {
  main: number
  other: Array<dzhOther>
}
interface formState {
  name: string
  yifang_name_type: number
  company_detailed_address_keywords: string
  status: number
  register_number: number
  licence_receive_gt: number
  licence_receive_ngt: number
  numbering_increment_mode: number
  platform_abbreviation: string
  social_credit_code: string
  latitude_longitude: string
  company_area_ids: string
  company_address: string
  platform_price_list: string
  company_detailed_address: string
  zipcode: string
  ratify_time: string
  company_bl_area_ids: string
  company_bl_address: string
  individual_bl_area_ids: string
  individual_bl_address: string
  maximum_number: string
  company_online_templatedz_ids: string
  individual_online_templatedz_ids: string
  company_scene_templatedz_ids: string
  individual_scene_templatedz_ids: string
  company_name: string | undefined
  company_wxappid: string | undefined
  company_wxappkey: string | undefined
  individual_name: string | undefined
  individual_wxappid: string | undefined
  individual_wxappkey: string | undefined
  ishave_seal: number | undefined
  iswant_seal: number | undefined
  ishave_business_license: number | undefined
  company_online_templatecn_ids: string | undefined
  company_online_templateqt_ids: string | undefined
  individual_online_templatecn_ids: string | undefined
  individual_online_templateqt_ids: string | undefined
  proprietorships_online_templateqt_ids: string | undefined
  proprietorships_online_templatecn_ids: string | undefined
  proprietorships_online_templatedz_ids: string | undefined
  company_scene_templatecn_ids: string | undefined
  company_scene_templateqt_ids: string | undefined
  individual_scene_templatecn_ids: string | undefined
  individual_scene_templateqt_ids: string | undefined
  proprietorships_scene_templatedz_ids: string | undefined
  proprietorships_scene_templatecn_ids: string | undefined
  proprietorships_scene_templateqt_ids: string | undefined
  company_client_template_ids: string | undefined
  individual_client_template_ids: string | undefined
  proprietorships_client_template_ids: string | undefined
  xufeihetong_template_ids: string | undefined
  xufeigaozhishu_template_ids: string | undefined
  shiming_flag: number
  shiming_telusetime: number | null
  is_provide_adds: number
  platform_model: number
  platform_model_type: number
  is_provide_ycx: number
  company_register_time: string | undefined
  individual_register_time: string | undefined
  logistics_order_cue: string | undefined
  company_address_number_prefix: Array<Object> | undefined
  company_address_number_postfix: Array<Object> | undefined
  individual_address_number_prefix: Array<Object> | undefined
  individual_address_number_postfix: Array<Object> | undefined
  business_contact_tel: string | undefined
  field_work_tels: string | undefined
  finance_tels: string | undefined
  store_manager_tels: string | undefined
  audit_admin_tels: string | undefined
  super_admin_tels: string | undefined
  company_authority: object
  jiechuhetong_template_ids: string
  yichuyichang_template_ids: string
  ticketreporting_template_ids: string
  lieruyichang_template_ids: string
  disposable_switch: number
  gs_add_words: string
  gt_add_words: string
  ycts_tels: string | undefined
  official_seal: string | undefined
  yisong_alibaba_ids: string | undefined
  yisong_taobao_ids: string | undefined
  yisong_douyin_ids: string | undefined
  yisong_pinduoduo_ids: string | undefined
  kezhang_template_ids: string | undefined
  kezhang_wxname: string | undefined
  is_provide_company_xuke: number[]
  jyfw_is_nzsy_company: number
  is_provide_individual_xuke: number[]
  jyfw_is_nzsy_geti: number
  limit_business_types: object
  smsflag: number
  bensheng_flag: string
  check_tel_times: number
  unpaid_num: number
  unfinished_num: number
  period_days: number
  zujin_zd: number
  checkstatusrobotsubmitflag: Array<Object> | undefined
  sub_appid: string | null
  sub_mchid: string | null
  sub_wechatpay_rates: number
  sub_wechatpay_rates_overflow: number
  dzh_account_id_ngt: dzhAccount
  dzh_account_id_gt: dzhAccount
  commission_rate: number
  is_must_recive_address_company: number
  is_must_recive_address_geti: number
}
let form = ref<formState>({
  company_detailed_address_keywords: '',
  status: 1,
  yifang_name_type: 1,
  register_number: 2,
  numbering_increment_mode: 1,
  licence_receive_gt: 1,
  licence_receive_ngt: 1,
  name: '',
  platform_abbreviation: '',
  social_credit_code: '',
  latitude_longitude: '',
  company_area_ids: '',
  company_address: '',
  platform_price_list: '',
  logistics_order_cue: '',
  company_detailed_address: '',
  zipcode: '410000',
  ratify_time: '',
  is_provide_ycx: 1,
  company_bl_area_ids: '',
  company_bl_address: '',
  individual_bl_area_ids: '',
  individual_bl_address: '',
  maximum_number: '',
  company_online_templatedz_ids: '',
  individual_online_templatedz_ids: '',
  company_scene_templatedz_ids: '',
  individual_scene_templatedz_ids: '',
  company_name: undefined,
  company_wxappid: undefined,
  company_wxappkey: undefined,
  individual_name: undefined,
  individual_wxappid: undefined,
  individual_wxappkey: undefined,
  ishave_seal: 2,
  iswant_seal: 1,
  jyfw_is_nzsy_company: 0,
  jyfw_is_nzsy_geti: 0,
  ishave_business_license: 2,
  company_online_templatecn_ids: undefined,
  company_online_templateqt_ids: undefined,
  individual_online_templatecn_ids: undefined,
  individual_online_templateqt_ids: undefined,
  proprietorships_online_templateqt_ids: undefined,
  proprietorships_online_templatecn_ids: undefined,
  proprietorships_online_templatedz_ids: undefined,
  company_scene_templatecn_ids: undefined,
  company_scene_templateqt_ids: undefined,
  individual_scene_templatecn_ids: undefined,
  individual_scene_templateqt_ids: undefined,
  proprietorships_scene_templatedz_ids: undefined,
  proprietorships_scene_templatecn_ids: undefined,
  proprietorships_scene_templateqt_ids: undefined,
  company_client_template_ids: undefined,
  individual_client_template_ids: undefined,
  proprietorships_client_template_ids: undefined,
  xufeihetong_template_ids: undefined,
  xufeigaozhishu_template_ids: undefined,
  shiming_flag: 2,
  shiming_telusetime: 1,
  is_provide_adds: 1,
  platform_model: 1,
  platform_model_type: 1,
  company_register_time: undefined,
  individual_register_time: undefined,
  company_address_number_prefix: [],
  company_address_number_postfix: [],
  individual_address_number_prefix: [],
  business_contact_tel: undefined,
  individual_address_number_postfix: [],
  field_work_tels: undefined,
  finance_tels: undefined,
  ycts_tels: undefined,
  store_manager_tels: undefined,
  audit_admin_tels: undefined,
  super_admin_tels: undefined,
  company_authority: {},
  jiechuhetong_template_ids: '',
  yichuyichang_template_ids: '',
  ticketreporting_template_ids: '',
  lieruyichang_template_ids: '',
  disposable_switch: 1,
  gs_add_words: '',
  gt_add_words: '',
  official_seal: '',
  yisong_alibaba_ids: '',
  yisong_taobao_ids: '',
  yisong_douyin_ids: '',
  yisong_pinduoduo_ids: '',
  kezhang_template_ids: '',
  kezhang_wxname: '',
  is_provide_company_xuke: [0],
  is_provide_individual_xuke: [0],
  limit_business_types: [],
  smsflag: 2,
  bensheng_flag: '',
  check_tel_times: 12,
  unpaid_num: 0,
  unfinished_num: 0,
  period_days: 0,
  zujin_zd: 2,
  checkstatusrobotsubmitflag: [1, 1],
  sub_appid: null,
  sub_mchid: null,
  sub_wechatpay_rates: 0.006,
  sub_wechatpay_rates_overflow: 0.06,
  dzh_account_id_ngt: { main: 0, other: [] }, //电子化账号-公司
  dzh_account_id_gt: { main: 0, other: [] }, //电子化账号-个人
  commission_rate: 0.0,
  is_must_recive_address_company: 2,
  is_must_recive_address_geti: 2
})
let formState = form.value

const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  formState[type] = str
}
const wxphoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  formState[type] = str
  //判断是否绑定微信
  let requestParam = {
    url: '/admin/v1/platformwWxtelCheck',
    method: 'post',
    data: {
      wxtel: str
    }
  }
  baseService(requestParam)
    .then((res) => {
      if (type == 'company_agent_wxtel') {
        state.company_agent_wxname = res.data.nickname
      } else if (type == 'individual_agent_wxtel') {
        state.individual_agent_wxname = res.data.nickname
      }
    })
    .catch((res) => {
      message.error('该手机号未关注公众号，请关注后再填写。')
      if (type == 'company_agent_wxtel') {
        state.company_agent_wxname = ''
      } else if (type == 'individual_agent_wxtel') {
        state.individual_agent_wxname = ''
      }
    })
}
onMounted(() => {
  if (props.detailData?.id) {
    queryDetail(props.detailData.id)
    state.modalTitle = '编辑平台'
  }
  queryTemplate('all')
  queryTemplate(1)
  queryTemplate(2)
  queryTemplate(3)
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/platform/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    res.data.ratify_time = dayjs(res.data.ratify_time, 'YYYY-MM-DD')
    form.value = res.data
    formState = form.value
    state.company_detailed_address = res.data.comoany_area_ssq
  })
}
// 查询模板
const queryTemplate = async (type) => {
  let requestParam = {
    url: '/admin/v1/templateList',
    method: 'post',
    data: Object.assign({
      limit: 'all',
      template_type: type === 'all' ? null : type
    })
  }
  await baseService(requestParam).then((res) => {
    switch (type) {
      case 1:
        templateObj.address = [...res.data.list]
        break
      case 2:
        templateObj.letterOfCommitment = [...res.data.list]
        break
      case 3:
        templateObj.other = [...res.data.list]
        break
      case 'all':
        templateObj.all = [...res.data.list]
        break
    }
  })
}

// 获取经纬度
const mapClick = () => {
  window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html')
}
// 省市区 选择和替换
const companyPauaChange = (res, e, type) => {
  if (type == 'company_detailed_address') {
    state.company_detailed_address = `${e[0].name}${e[1].name}${e[2].name}`
  }
  let address = `${e[0].name}${e[1].name}${e[2].name}`
  formState[type] = address
}
// 提交
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      let form = cloneDeep(formState)
      form.ratify_time = dayjs(formState.ratify_time).format('YYYY-MM-DD')
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/v1/platform/' + props.detailData.id,
          method: 'put',
          data: form
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/platform',
          method: 'post',
          data: form
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      }
    })
    .catch(() => {
      message.warning('请检查表单必填项')
    })
}
// 手动上传验证
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请上传正确图片！')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片必须小于2MB!')
  }
  return false
}

// 图片上传
const handleChange = (info, type) => {
  if (!formState.name || formState.name.length === 0) {
    message.warn('请填写平台名称')
    return
  }
  if (!state.company_detailed_address || state.company_detailed_address.length === 0) {
    message.warn('请选择平台地址')
    return
  }
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}
// 裁剪成功
const cropperChange = (res) => {
  formState[state.currentUpload] = res.url
  state.showCropper = false
  ruleForm.value.validate(state.currentUpload)
}

// 公司电子化账号
const addNgtOther = () => {
  if (!formState.dzh_account_id_ngt.other) {
    formState.dzh_account_id_ngt.other = []
  }
  formState.dzh_account_id_ngt.other.push({ tel: '', account: 0 })
}
const removeNgtOther = (other) => {
  const index = formState.dzh_account_id_ngt.other.indexOf(other)
  if (index !== -1) {
    formState.dzh_account_id_ngt.other.splice(index, 1)
  }
}
const addGtOther = () => {
  if (!formState.dzh_account_id_gt.other) {
    formState.dzh_account_id_gt.other = []
  }
  formState.dzh_account_id_gt.other.push({ tel: '', account: 0 })
}
const removeGtOther = (other) => {
  const index = formState.dzh_account_id_gt.other.indexOf(other)
  if (index !== -1) {
    formState.dzh_account_id_gt.other.splice(index, 1)
  }
}
</script>

<style lang="less" scoped>
.upload-img {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 300px !important;
    height: 200px !important;
  }
}
</style>
