<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'inform',
})
</script>
<template>
  <div>
    <div style="height: 500px; overflow-y: auto; padding: 10px">
      <a-spin :spinning="data.loading">
        <div v-for="(item, index) in data.chatList" :key="index" style="overflow: hidden; margin-bottom: 30px">
          <p style="text-align: center; font-size: 12px; color: #333">{{ item.created_at }}</p>
          <div :style="{ float: item.is_private == 1 ? 'left' : 'right' }">
            <a-spin :spinning="item.sending == 1">
              <a-avatar size="small" :src="item.send_info?.headimgurl" :style="{ float: item.is_private == 1 ? 'left' : 'right' }">
                <template #icon><UserOutlined /></template
              ></a-avatar>
              <div
                :class="item.is_private == 1 ? 'chat-bubble chat-bubble-left' : 'chat-bubble2 chat-bubble-right'"
                :style="item.is_private == 1 ? 'margin-left: 40px; width: 100%; margin-bottom: 10px' : 'margin-right: 40px; width: 100%; margin-bottom: 10px'"
              >
                <span v-html="item.msg" style="line-height: 24px"></span>
                <div v-if="item.attachment">
                  附件：
                  <span v-for="(itemaa, index) in item.attachment">
                    <a-image
                      v-if="itemaa.name.indexOf('jpg') > -1 || itemaa.name.indexOf('png') > -1 || itemaa.name.indexOf('jpeg') > -1"
                      :key="index"
                      :width="120"
                      :height="50"
                      :src="$store.state.user.image_domain + itemaa.url"
                    >
                    </a-image>
                    <p v-else>
                      <a :href="$store.state.user.image_domain + itemaa.url" target="_blank">{{ itemaa.name }}</a>
                    </p>
                  </span>
                </div>
              </div>
              <a class="ml-2x" :style="{ fontSize: '12px', marginLeft: item.is_private == 1 ? '40px' : '12px' }" v-if="item.is_send == 1" @click="handleSpeaker(item)"
                ><NotificationOutlined /> 发送至云喇叭通知</a
              ></a-spin
            >
          </div>
        </div>
      </a-spin>
    </div>
    <div style="padding-bottom: 10px; border-top: 1px solid #eee; padding-top: 30px">
      <a-form class="flex-1" ref="goutonFormRef" v-bind="formLayout" :model="goutonFrom">
        <a-form-item label="抄送">
          <a-select v-model:value="goutonFrom.tels" mode="multiple" style="width: 86%" placeholder="请选择" :options="data.tels" @change="handleChange"></a-select>
        </a-form-item>
        <a-form-item label="附件上传">
          <Upload
            upload_path="public/uploads/微信沟通"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            v-model:value="goutonFrom.attachment"
            uploadType="text"
            :limitation="9"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="发微信消息" style="margin-top: -25px">
          <div style="display: flex">
            <a-textarea required v-model:value="goutonFrom.msg" :auto-size="{ minRows: 2, maxRows: 5 }" />
            <a-popconfirm title="确认发消息吗?" @confirm="fabugouton">
              <a-button class="ml-1x" type="primary" :loading="data.submitLoading">发布</a-button>
            </a-popconfirm>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script lang="ts" setup name="inform">
import Upload from '@/components/base/Upload.vue'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons-vue'
import Inform from './components/inform.vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { unref, ref, watch, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import type { PaginationProps } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { UserOutlined, BellOutlined, NotificationOutlined } from '@ant-design/icons-vue'
const $store = useStore()
const formLayout = {
  labelCol: {
    xs: { span: 3 },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
const props = defineProps({
  lianxirenData: {
    type: Object,
    default: false
  }
})
interface data {
  loading: boolean
  submitLoading: boolean
  chatList: any[]
  tels: any[]
  side_id: number
}
const data = reactive<data>({
  loading: false,
  submitLoading: false,
  chatList: [],
  tels: [],
  side_id: 0
})
watch(
  () => props.lianxirenData.key,
  (val) => {
    if (val) {
      initData()
    }
  },
  {
    deep: true,
    immediate: true
  }
)
const initData = () => {
  //切换联系人
  data.chatList = []
  data.tels = []
  getChatList()
  //查询发送人
  getCtelInfo()
}
const getChatList = () => {
  data.loading = true
  let requestParam = {
    url: '/admin/v1/memberChatList',
    method: 'post',
    data: {
      customer_id: props.lianxirenData.customer_id,
      side: props.lianxirenData.side,
      limit: 'all',
      page: 1
    }
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      data.chatList = res.data.list
    })
    .catch((res) => {
      data.loading = false
    })
}
//发送消息
const goutonFormRef = ref()
const goutonFrom = reactive<any>({
  customer_id: props.lianxirenData.customer_id,
  msg: '',
  attachment: [],
  tels: []
})
const handleChange = (value: string[]) => {
  // 添加到goutonFrom里面去
  goutonFrom.tels = value
}
const fabugouton = () => {
  let tels = [...goutonFrom.tels]
  tels.push(data.side_id)
  let postData = {
    customer_id: props.lianxirenData.customer_id,
    tels: tels,
    msg: goutonFrom.msg,
    attachment: goutonFrom.attachment
  }
  goutonFormRef.value
    .validate()
    .then(() => {
      data.submitLoading = true
      let requestParam = {
        url: '/admin/v1/addCustomerChat',
        method: 'post',
        data: postData
      }
      baseService(requestParam)
        .then((res) => {
          message.success('添加成功')
          goutonFrom.msg = null
          goutonFrom.attachment = null
          goutonFrom.tels = []
          getChatList()
          data.submitLoading = false
        })
        .catch((res) => {
          data.submitLoading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('添加失败')
          }
        })
    })
    .catch(() => {})
}
const getCtelInfo = () => {
  let requeryParam = {
    url: `/admin/v1/getCtelInfo?customer_id=` + props.lianxirenData.customer_id + '&side=' + props.lianxirenData.side,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    data.tels = res.data.tels
    data.side_id = res.data.side_id
  })
}
//调用喇叭
const handleSpeaker = (record) => {
  record.sending = 1
  let requeryParam = {
    url: '/admin/v1/chatSendSpeaker',
    method: 'post',
    data: {
      id: record.id
    }
  }
  baseService(requeryParam)
    .then((res) => {
      message.info('发送成功！')
    })
    .catch((res) => {
      message.error(res.msg)
    })
    .finally(() => {
      record.sending = 0
    })
}
</script>

<style lang="less" scoped>
.add-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.timelineStatus {
  .ant-timeline-item:first-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
:deep(.ant-table-cell) {
  padding: 12px !important;
}
:deep(.ant-image) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.timeline {
  .ant-timeline-item:first-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
.chat-bubble {
  position: relative;
  margin: 12px;
  padding: 10px;
  word-break: break-all;
  background: #89d961;
  border-radius: 5px;
  max-width: 180px;
}

.chat-bubble-left:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -19px;
  top: 2px;
  border: 10px solid;
  border-color: transparent #89d961 transparent transparent;
}
.chat-bubble-left:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -15px;
  top: 2px;
  border: 8px solid;
  border-color: transparent #89d961 transparent transparent;
}
.chat-bubble2 {
  position: relative;
  margin: 12px;
  padding: 10px;
  word-break: break-all;
  background: #4793fe;
  border-radius: 5px;
  max-width: 180px;
  color: #fff;
}

.chat-bubble-right:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: -19px;
  top: 2px;
  border: 10px solid;
  border-color: transparent transparent transparent #4793fe;
}
.chat-bubble-right:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: -10px;
  top: 2px;
  border: 8px solid;
  border-color: transparent transparent transparent #4793fe;
}
</style>
