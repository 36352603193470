<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CustomerList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="平台" name="platform_id">
              <a-select mode="multiple" v-model:value="queryParam.platform_id" placeholder="请选择">
                <a-select-option v-for="(item, index) in data.platformList" :key="index" :value="item.id">{{ item.platform_abbreviation }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24">
            <a-form-item label="主体类型" name="main_type">
              <a-select mode="multiple" v-model:value="queryParam.main_type" placeholder="请选择">
                <a-select-option :value="1">公司</a-select-option>
                <a-select-option :value="2">个体</a-select-option>
                <a-select-option :value="3">个人独资</a-select-option>
                <a-select-option :value="4">合伙企业</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="经营范围" name="keys">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.keys" placeholder="请输入经营范围"> </a-input>
            </a-form-item> </a-col
          ><a-col :md="6" :sm="24">
            <a-button type="primary" @click="loadData">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'companyInformation'">
          <div class="companyInformation">
            <div class="mb-x">
              <a-popover trigger="click" v-model:open="record.isShowCompany" overlayClassName="customer-options-popover">
                <template #content>
                  <p @click="handleDoubleNameClick(record.tyc_url)">跳转到天眼查</p>
                  <p @click="handleDoubleNameClick(record.qcc_url)">跳转到企查查</p>
                </template>
                <div style="width: 100%"></div>
              </a-popover>
              <span
                class="mr-1x pointer"
                @click="
                  (e) => {
                    record.isShowCompany = false
                    handleNameClick(e, record.full_name)
                  }
                "
                @dblclick="
                  () => {
                    record.isShowCompany = true
                  }
                "
                >{{ record.full_name }}</span
              >
            </div>
            <a-tag color="orange">{{ record.customer_work_order_status?.after_status_name }}</a-tag>
            <a-tag color="green">{{ record.business_type == 1 ? '设立' : record.business_type == 2 ? '同区迁入' : '跨区迁入' }}</a-tag>
            <a-tag color="green" v-if="record.fee_standard == 2">一次性付费</a-tag>
            <a-tag color="green" v-if="record.registration_type == 2">裸地址</a-tag>
            <a-tag color="green" v-if="record.main_type == 3">个人独资</a-tag>
          </div>
        </template>
        <template v-else-if="['verify_time', 'created_at', 'founding_time'].includes(column.dataIndex)">
          <div v-if="text?.length > 0">
            {{ text }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="column.dataIndex == 'business_scope'">
          <div v-html="text"></div>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script lang="ts" setup name="CustomerList">
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, computed } from 'vue'
import ls from '@/utils/Storage'
const token = ls.get('ACCESS_TOKEN').replace('Bearer ', '')
interface data {
  visible: boolean
  loading: boolean
  downList: any
  customerInfo: any
  platformList: any
}
const data = reactive<data>({
  // create model
  visible: false,
  loading: false,
  /* 下载列表 */
  downList: {},
  //数据列表
  customerInfo: {},
  platformList: []
})
const columns = computed(() => {
  return [
    {
      title: '公司名称',
      dataIndex: 'companyInformation',
      width: 200
    },
    {
      title: '经营范围',
      dataIndex: 'business_scope',
      width: 350
    },
    {
      title: '注册地址',
      dataIndex: 'full_address_number',
      width: 200
    },
    {
      title: '核准时间',
      dataIndex: 'verify_time',
      width: 100
    },
    {
      title: '成立时间',
      dataIndex: 'founding_time',
      width: 100
    },
    {
      title: '录入系统时间',
      dataIndex: 'created_at',
      width: 100
    }
  ]
})

let queryParam = reactive<any>({
  keys: ''
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 20,
  pageSizeOptions: ['10', '20', '40', '50', '60'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  platformList()
  loadData()
})
// 跳转天眼查
const handleDoubleNameClick = (url) => {
  window.open(url)
}

// 获取平台数据
const platformList = () => {
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: { limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      data.platformList = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('平台列表查询失败,请联系管理员')
      }
    })
}

// 复制名称
const handleNameClick = (e, name) => {
  if (e.detail == 1) {
    var input = document.createElement('input')
    //将input的值设置为需要复制的内容
    input.value = name
    //添加input标签
    document.body.appendChild(input)
    //选中input标签
    input.select()
    //执行复制
    document.execCommand('copy')
    //成功提示信息
    message.success('复制成功，尝试双击有惊喜！')
    //移除input标签
    document.body.removeChild(input)
  }
}

// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/customer/searchScope',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      data.customerInfo = {
        unrecorded_electronic: res.data.unrecorded_electronic,
        modify_data: res.data.modify_data,
        to_be_verified: res.data.to_be_verified
      }

      dataSource.value = res.data.list.map((item) => {
        if (queryParam.keys) {
          item.business_scope = item.business_scope.replace(queryParam.keys, `<span style="color: red">${queryParam.keys}</span>`)
        } else {
          item.business_scope = item.business_scope
        }
        return item
      })

      dataSource.value = res.data.list
      let downList: any = []
      for (const key in res.data.down_type) {
        downList.push({ type: key, name: res.data.down_type[key] })
      }
      data.downList = downList
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('客户列表查询失败,请联系管理员')
      }
    })
}
// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  loadData()
}
</script>
<style lang="less" scoped>
.table-operator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}

.companyInformation {
  .deng {
    display: none;
  }
}

.companyInformation:hover {
  .deng {
    display: block;
  }
}

.loading-message {
  .ant-message-notice-content {
    border: 1px solid #c8d8cf;
    color: rgb(8, 153, 68);
    background: rgb(233, 252, 241);
  }
}
</style>
