<template>
  <a-modal title="支付中心" :confirmLoading="state.loading" centered :bodyStyle="{ padding: '20px' }" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <a-popconfirm title="是否确定？" @confirm="onFormSubmit">
        <a-button :loading="state.loading" type="primary">确定</a-button>
      </a-popconfirm>
    </template>
    <a-form ref="ruleForm" :rules="rules" :model="formState" v-bind="formLayout" scrollToFirstError="true">
      <a-form-item label="主体名称">
        {{ props.tableColumnsData.full_name }}
        <a-tag class="ant-tag-green" v-if="props.tableColumnsData.exception_flag == 1">正常状态</a-tag>
        <a-tag class="ant-tag-red" v-else-if="props.tableColumnsData.exception_flag == 2">待申报异常，未交材料</a-tag>
        <a-tag class="ant-tag-red" v-else-if="props.tableColumnsData.exception_flag == 3">已申报异常，已交材料</a-tag>
        <a-tag class="ant-tag-red" v-else-if="props.tableColumnsData.exception_flag == 4">已确认进入异常名录</a-tag>
      </a-form-item>

      <a-form-item label="历史名称" v-if="props.tableColumnsData.history_names?.length > 0">
        <div v-for="(item, index) in props.tableColumnsData.history_names" :key="index">
          {{ item }}
        </div>
      </a-form-item>

      <a-form-item label="注册地址">
        {{ props.tableColumnsData.full_address_number }}
      </a-form-item>

      <a-form-item label="代理商" name="belonger" required>
        {{ formState.belonger }}
      </a-form-item>
      <a-form-item label="备注" v-if="props.tableColumnsData.remark">
        {{ props.tableColumnsData.remark }}
      </a-form-item>

      <a-form-item label="当前托管时间"> {{ props.tableColumnsData.enter_starttime.substr(0, 10) }} ~ <span v-html="props.tableColumnsData.enter_endtime"></span> </a-form-item>
      <a-form-item label="支付类型" required>
        <a-radio-group v-model:value="formState.public_payment_type" button-style="solid" @change="getComputeOreder">
          <a-radio-button :value="2">地址续费</a-radio-button>
          <a-radio-button :value="1">支付注册费</a-radio-button>
          <a-radio-button :value="3">其它收入（核查表，移除异常等等）</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <!-- 其他支付的支付名称+金额 -->
      <a-form-item v-if="formState.public_payment_type == 3" label="支付名称" extra="" name="other_name" required>
        <a-select v-model:value="formState.other_name" placeholder="请选择支付名称">
          <a-select-option value="核查表">核查表</a-select-option>
          <a-select-option value="移除异常">移除异常</a-select-option>
          <a-select-option value="申请迁入走现场">申请迁入走现场</a-select-option>
          <a-select-option value="其它">其它</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="formState.public_payment_type == 3" label="支付金额" name="other_amount" required>
        <a-input placeholder="" v-model:value="formState.other_amount" prefix="￥" suffix="元" style="width: 140px" @blur="getComputeOreder"></a-input>
      </a-form-item>

      <a-form-item label="支付渠道" required>
        <a-radio-group v-model:value="state.type" button-style="solid" @change="getComputeOreder">
          <a-radio-button :value="3">微信支付</a-radio-button>
          <a-radio-button :value="1">账户余额</a-radio-button>
          <a-radio-button :value="2">对公账户</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="续费类型" v-if="formState.public_payment_type == 2" required>
        <a-radio-group v-model:value="formState.fee_standard" button-style="solid" @change="getComputeOreder">
          <a-radio-button :value="1">按年续费</a-radio-button>
          <a-radio-button :value="2">一次性续费</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="按年续费价格" v-if="formState.public_payment_type == 2 && formState.fee_standard == 1">
        <span class="red"> ￥{{ props.tableColumnsData.next_year_price }} / 年 </span>
      </a-form-item>

      <PlatformPayment v-if="state.platformVisible" @cancel="handleCancel" @ok="handleOk" :tableColumnsData="props.tableColumnsData"></PlatformPayment>

      <a-form-item label="续费时长" v-if="formState.public_payment_type == 2 && formState.fee_standard == 1" name="month_quantity" required>
        <a-select v-model:value="formState.month_quantity" placeholder="请选择续费时长" @change="getComputeOreder">
          <a-select-option v-for="(item, index) in timesOption" :key="item.value"> {{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="一次性付费续费金额" v-if="formState.public_payment_type == 2 && formState.fee_standard == 2">
        <span class="red" v-if="formState.price > 0"> ￥{{ formState.price }} </span>
        <span v-else>-</span>
        <loading-outlined v-if="state.priceLoading" />
      </a-form-item>

      <div v-if="state.type == 2">
        <a-form-item label="付款人全称" name="public_payer_name" required>
          <a-input v-model:value="formState.public_payer_name" placeholder="请输入付款人全称"></a-input>
        </a-form-item>
        <a-form-item label="付款时间" name="public_pay_time" required>
          <a-date-picker :show-time="{ defaultValue: arrayMonent }" v-model:value="formState.public_pay_time" placeholder="请选择付款时间"></a-date-picker>
        </a-form-item>
        <a-form-item label="付款金额" name="public_money" required>
          <a-input v-model:value="formState.public_money" placeholder="请输入付款金额"></a-input>
        </a-form-item>
        <a-form-item label="付款备注" name="public_remark" required>
          <a-input v-model:value="formState.public_remark" placeholder="请输入付款备注"></a-input>
        </a-form-item>
        <a-form-item label="收款人全称" name="public_payee_name" required>
          <a-input v-model:value="formState.public_payee_name" placeholder="请输入收款人全称"></a-input>
        </a-form-item>
      </div>
      <a-form-item label="附件" :required="state.type == 2 ? true : false">
        <Upload
          :upload_path="props.tableColumnsData.upload_path"
          :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
          @uploading="
            (val) => {
              state.finishLoading = val
            }
          "
          :customerName="props.tableColumnsData.entityNameToRegister.full_name"
          uploadType="text"
          v-model:value="formState.attachment"
          :limitation="99"
          uploadText="上传附件"
        >
        </Upload>
      </a-form-item>

      <a-form-item label="备注">
        <a-textarea v-model:value="formState.remark" placeholder="请输入备注"></a-textarea>
      </a-form-item>
      <a-form-item label="日志">
        <a @click="onViewLogClick">查看日志</a>
      </a-form-item>
      <a-form-item label="付款方" v-if="state.type == 3">
        <a-radio-group v-model:value="formState.wx_payer" button-style="solid" @click="changeWxPayer">
          <a-radio-button :value="1">代理商付款</a-radio-button>
          <a-radio-button :value="2">续费联系人付款</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="总计需要支付金额" v-if="formState.public_payment_type == 1 || formState.public_payment_type == 2">
        <template v-if="state.type == 3 && formState.wx_payer == 2" title="续费联系人付款">
          <div style="font-size: 24px; color: red; line-height: 24px">
            <span class="red">￥{{ state.cust_paid_total }}</span>
          </div>
        </template>
        <template v-else title="代理商付款">
          <div style="font-size: 24px; color: red; line-height: 24px">
            <div v-if="state.coupon_use_id >= 1">
              <span class="red" style="text-decoration: line-through">￥{{ state.pay_amount_total }} </span>
              <span>，优惠了￥{{ state.discount_amount_total }}</span>
              <a-button type="primary" @click="useCoupon" style="background: #ff6b26; margin-left: 10px" size="small" v-if="state.isselected_coupon"
                >使用了优惠券（{{ state.isselected_coupon.coupon_type == 1 ? '直减现金券' : state.isselected_coupon.coupon_type == 2 ? '满减现金券' : '折扣券' }}：{{
                  state.isselected_coupon.name
                }}√）</a-button
              >
              <a style="margin-left: 20px; font-size: 14px" @click="deleteCoupon">删除优惠券</a>
            </div>
            <div v-else>
              ￥{{ state.actually_paid_total }}
              <a-button type="primary" @click="useCoupon" style="background: #ff6b26; margin-left: 10px" size="small">使用优惠券</a-button>
            </div>
          </div>
        </template>
      </a-form-item>
      <a-form-item label="总计需要支付金额" v-if="formState.public_payment_type == 3">
        <div style="font-size: 24px; color: red; line-height: 24px">
          <div>￥{{ formState.other_amount }}</div>
        </div>
      </a-form-item>
      <a-form-item label="优惠后需要支付金额" v-if="state.coupon_use_id >= 1">
        <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ state.actually_paid_total }}</div>
      </a-form-item>

      <a-form-item label="用户当前平台余额" v-if="state.type != 3">
        <span class="red"> ￥{{ state.moeny }} <a @click="recharge" class="m-3x">点击充值</a></span>
      </a-form-item>
      <div v-if="state.type == 3">
        <!-- 微信支付 -->
        <a-form-item label="操作">
          <a-button type="primary" @click="onFormSubmit" style="background: #ff6b26; margin-top: 10px" size="small">立即扫码付款</a-button>
        </a-form-item>
      </div>
    </a-form>
    <UseCoupons
      v-if="state.showCoupon"
      v-model:couponId="state.couponId"
      :tableColumnsData="{ public_payment_type: formState.public_payment_type, customer_full_names: null, id: props.tableColumnsData.id, fee_standard: formState.fee_standard }"
      @cancel="
        () => {
          state.showCoupon = false
        }
      "
      @ok="UseCouponsOk"
    ></UseCoupons>
    <ElectronicProof v-if="state.showModal" :counterfeitInfo="state.counterfeitInfo" @cancel="closeModal"></ElectronicProof>
    <view-log
      :logTabsKey="1"
      v-if="state.visibleLog"
      :tableColumnsData="props.tableColumnsData"
      @cancel="
        () => {
          state.visibleLog = false
        }
      "
    ></view-log>
  </a-modal>
  <!-- 微信扫码支付 -->
  <WechatPayQrcode
    v-if="state.showQrcode"
    :qrcodeData="state.qrcodeData"
    @cancel="state.showQrcode = false"
    @ok="
      () => {
        emit('ok')
      }
    "
  ></WechatPayQrcode>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { reactive, onMounted, ref, computed, onUnmounted } from 'vue'
import ViewLog from './ViewLog.vue'
import Upload from '@/components/base/Upload.vue'
import ElectronicProof from './ElectronicProof.vue'
import PlatformPayment from './PlatformPayment.vue'
import UseCoupons from '@/components/base/UseCoupons.vue'
import WechatPayQrcode from '@/components/base/WechatPayQrcode.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
const arrayMonent = dayjs('00:00:00', 'HH:mm:ss')
const format = 'YYYY-MM-DD HH:mm:ss'
const $store = useStore()
const router = useRouter()
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 }
  }
}
const ruleForm = ref()
const rules = []
const emit = defineEmits(['cancel', 'ok'])

const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const timesOption = [
  { label: '1年', value: 12 },
  { label: '2年', value: 24 },
  { label: '3年', value: 36 },
  { label: '4年', value: 48 },
  { label: '5年', value: 60 },
  { label: '1月', value: 1 },
  { label: '2月', value: 2 },
  { label: '3月', value: 3 },
  { label: '4月', value: 4 },
  { label: '5月', value: 5 },
  { label: '6月', value: 6 },
  { label: '7月', value: 7 },
  { label: '8月', value: 8 },
  { label: '9月', value: 9 },
  { label: '10月', value: 10 },
  { label: '11月', value: 11 }
]

const state = reactive<any>({
  loading: false,
  showModal: false,
  finishLoading: false,
  visibleLog: false,
  priceLoading: false,
  counterfeitInfo: {},
  showCoupon: false,

  discount_amount_total: 0, // 总优惠金额
  pay_amount_total: 0, // 总应付金额
  actually_paid_total: 0, // 总实付金额（代理商）
  customer_xfdata: [],
  cust_paid_total: 0, // 总实付金额（客户）

  coupon_use_id: null, // 选中优惠券id
  isselected_coupon: {}, //选中的优惠券
  moeny: 0.0,
  type: 3,
  platformVisible: false,
  showQrcode: false,
  qrcodeData: {}
})

const formState = reactive<any>({
  public_payment_type: 0, //地址续费
  fee_standard: 1,
  month_quantity: 12,
  price: props.tableColumnsData.price,
  other_amount: 0,
  other_name: '',
  wx_payer: 1 //微信支付付款人，1代理商，2续费联系人
})
onMounted(() => {
  formState.belonger = props.tableColumnsData.seller
  formState.public_payment_type = props.tableColumnsData.first_pay_time ? (props.tableColumnsData.fee_standard == 2 ? 3 : 2) : 1
  formState.fee_standard = props.tableColumnsData.fee_standard
  queryMoney()
  getComputeOreder()
})
const useCoupon = () => {
  state.showCoupon = true
}
const deleteCoupon = () => {
  state.isselected_coupon = {}
  state.coupon_use_id = null
  getComputeOreder()
}
const UseCouponsOk = (e) => {
  state.coupon_use_id = e.id
  state.isselected_coupon = e
  state.showCoupon = false
  getComputeOreder()
}
const handleCancel = () => {
  state.platformVisible = false
  queryMoney()
}
const handleOk = () => {
  state.platformVisible = false
  queryMoney()
}

const queryMoney = async () => {
  state.loading = true
  let requeryParam = {
    url: '/admin/user/getMoney?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  await baseService(requeryParam)
    .then((res) => {
      state.loading = false
      state.moeny = res.data.money
      state.addr_kucun = res.data.addr_kucun
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取余额失败')
      }
    })
}
// 查询日志
const onViewLogClick = () => {
  state.visibleLog = true
}

// 跳转平台充值
const recharge = () => {
  state.platformVisible = true
}

const closeModal = () => {
  emit('ok')
}

// 计算订单-地址续费
const getComputeOreder = () => {
  if (formState.public_payment_type == 3) {
    //其他支付不计算
    return
  }
  state.priceLoading = true
  let requeryParam = {
    url: `/admin/v1/getComputeOreder`,
    method: 'post',
    data: {
      public_payment_type: formState.public_payment_type, //地址续费
      customer_id: props.tableColumnsData.id,
      month_quantity: formState.month_quantity,
      fee_standard: formState.fee_standard, //按年收费
      coupon_use_id: state.coupon_use_id
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.priceLoading = false
      //state.coupon_use_list = res.data.coupon_use_list
      state.discount_amount_total = res.data.oreder.discount_amount_total // 总优惠金额
      state.pay_amount_total = res.data.oreder.pay_amount_total // 总应付金额
      state.actually_paid_total = res.data.oreder.actually_paid_total // 总实付金额(代理商)
      state.cust_paid_total = res.data.oreder.cust_paid_total // 总实付金额（客户）
      state.customer_xfdata = res.data.oreder.customer_xfdata //
      //这里显示
      formState.price = state.pay_amount_total
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '查询订单失败')
      state.priceLoading = false
      if (res?.data == 'coupon') {
        //优惠券问题，删除此优惠券
        deleteCoupon()
      }
    })
}
// 改变付款对象
const changeWxPayer = () => {
  console.log(formState.wx_payer)
}
// 提交
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      let obj: any = {}
      if (state.type == 1) {
        //账户余额
        obj = {
          public_payment_type: formState.public_payment_type, //地址续费
          customer_id: props.tableColumnsData.id, //客户id
          fee_standard: formState.fee_standard, //续费类型
          month_quantity: formState.month_quantity, //续费月份
          belonger: null, //代理商、这里没有变更代理商
          coupon_use_id: state.coupon_use_id, //余额抵扣可以用优惠券
          pay_type: 1, //余额抵扣
          remark: formState.remark, //付款备注
          attachment: formState.attachment, //附件、续费都有附件
          other_amount: formState.other_amount, //其他金额
          other_name: formState.other_name //支付名称
        }
      } else if (state.type == 3) {
        //微信支付
        obj = {
          public_payment_type: formState.public_payment_type, //地址续费
          customer_id: props.tableColumnsData.id, //客户id
          fee_standard: formState.fee_standard, //续费类型
          month_quantity: formState.month_quantity, //续费月份
          belonger: null, //代理商、这里没有变更代理商
          coupon_use_id: state.coupon_use_id, //余额抵扣可以用优惠券
          pay_type: 2, //微信支付
          remark: formState.remark, //付款备注
          attachment: formState.attachment, //附件、续费都有附件
          other_amount: formState.other_amount, //其他金额
          other_name: formState.other_name, //支付名称
          wx_payer: formState.wx_payer //微信支付特有的2项
        }
      } else {
        //对公账户
        obj = {
          public_payment_type: formState.public_payment_type, //地址续费
          customer_id: props.tableColumnsData.id, //客户id
          fee_standard: formState.fee_standard, //收费时间
          month_quantity: formState.month_quantity,
          belonger: formState.belonger, //代理商
          coupon_use_id: null, //对公不能使用优惠券
          pay_type: 4, //对公转账
          remark: formState.remark, //付款备注
          attachment: formState.attachment, //附件、对公才传附件
          other_amount: formState.other_amount, //其他金额
          other_name: formState.other_name, //支付名称

          public_payer_name: formState.public_payer_name, //对公转账特有的6项
          public_payee_number: formState.public_payee_number,
          public_money: formState.public_money,
          public_remark: formState.public_remark,
          public_payee_name: formState.public_payee_name,
          public_pay_time: dayjs(formState.public_pay_time).format('YYYY-MM-DD')
        }
      }
      let requeryParam = {
        url: '/admin/v1/payOreder',
        method: 'post',
        data: obj
      }
      if (formState.public_payment_type == 3) {
        requeryParam.url = '/admin/v1/payOrederOther'
      }
      baseService(requeryParam)
        .then((res: any) => {
          state.loading = false
          if (res.msg == 'showqrcode') {
            //显示微信扫的二维码
            state.qrcodeData = {
              qrcode: res.data.code_url, //二维码url
              qrcode_money: res.data.qrcode_money, //金额
              payment_record_id: res.data.payment_record_id, //记录id
              full_name: props.tableColumnsData.full_name, //名称
              trade_type: res.data.trade_type
            }
            state.showQrcode = true
          } else {
            message.success(res.msg)
            //   emit('ok')
            if (state.type == 1) {
              //余额续费显示一下凭证
              state.showModal = true
              state.counterfeitInfo = res.data
            } else {
              closeModal()
            }
          }
        })
        .catch((res) => {
          state.loading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch(() => {})
}
const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 15px;
}
.d-flex {
  align-items: center;
}
</style>
<style lang="less">
.addresssRenewModal {
  .ant-modal {
    top: 300px;
  }
}
</style>
