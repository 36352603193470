import { createRouter } from 'vue-router';
import { timeFix } from '@/utils/util';
import ls from '@/utils/Storage';
import { message, notification } from 'ant-design-vue';
import store from '@/store'

export const loginSuccess = (router, res) => {
    // 延迟 1 秒显示欢迎信息
    setTimeout(() => {
        notification.success({
            message: '欢迎 ' + res.data.original.user.nickname,
            description: `${timeFix()}，欢迎回来`,
        });
    });
    if (res.data.original.access_token) {
        ls.set('ACCESS_TOKEN', `${res.data.original.token_type} ${res.data.original.access_token}`);
        ls.set('PERMISSION', ['admin']);
        ls.set('USER_INFO', res.data.original.user);
        ls.set('IMAGE_DOMAIN', res.data.original.image_domain);
    }
    // 重新获取菜单和路由
    store.dispatch('GenerateRoutes', router).then(() => {
        if (router.currentRoute.value?.query?.redirect) {
            //判断是否包含,包含才跳进去
            let targetPath = router.currentRoute.value.query.redirect
            let isContain = false
            router.getRoutes().map((item) => {
                if (item.path == targetPath) {
                    isContain = true
                }
            })
            if (isContain) {
                router.push({ path: router.currentRoute.value.query.redirect });
            } else {
                router.push({ path: '/' });
            }
        } else {
            router.push({ path: '/' });

        }
    })
};

export const requestFailed = (err) => {
    notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4,
    });
};
