<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    width="60%"
    :open="open"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    :maskClosable="false"
    @cancel="
      () => {
        emit('cancel')
      }
    "
    ok-text="提交订单"
    cancel-text="取消"
  >
    <a-spin :spinning="state.loading">
      <a-row>
        <a-col :span="16"
          ><a-form ref="ruleForm" scrollToFirstError :model="formState" v-bind="formLayout" :rules="rules" style="margin: 20px 0 100px 0">
            <div class="form-tips-title">第一步：选择刻章类型</div>
            <a-form-item label="刻备案章" name="beian_zhang" has-feedback validate-status="success">
              <a-checkbox-group v-model:value="formState.beian_zhang" @change="changeBeianZhang">
                <a-row v-if="formState.pay_status != 1">
                  <a-col :span="24"
                    ><a-row>
                      <a-col :span="8">
                        <a-checkbox value="11">备案公章</a-checkbox>
                      </a-col>
                      <a-col :span="8">
                        <a-checkbox value="12">备案财务章</a-checkbox>
                      </a-col>
                      <a-col :span="8">
                        <a-checkbox value="13">备案法人章</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row style="margin-top: 20px">
                      <a-col :span="8">
                        <a-checkbox value="14">备案合同专用章</a-checkbox>
                      </a-col>
                      <a-col :span="8">
                        <a-checkbox value="15">备案发票专用章</a-checkbox>
                      </a-col>
                      <a-col :span="8">
                        <a-checkbox value="16">备案其他类型章</a-checkbox>
                      </a-col>
                    </a-row></a-col
                  >
                </a-row>
                <div v-else>
                  <span class="ml-1x" v-if="formState.beian_zhang.includes('11')">√ 备案公章</span><span class="ml-1x" v-if="formState.beian_zhang.includes('12')">√ 备案财务章</span
                  ><span class="ml-1x" v-if="formState.beian_zhang.includes('13')">√ 备案法人章</span><span class="ml-1x" v-if="formState.beian_zhang.includes('14')">√ 备案合同专用章</span
                  ><span class="ml-1x" v-if="formState.beian_zhang.includes('15')">√ 备案发票专用章</span><span class="ml-1x" v-if="formState.beian_zhang.includes('16')">√ 备案其他类型章</span>
                </div>
              </a-checkbox-group>
            </a-form-item>
            <div class="form-tips-title">第二步：填基本信息</div>
            <a-form-item label="营业执照照片" name="licence_url" :required="true">
              <div v-if="formState.status == 1 || formState.status == 3 || formState.status == null" style="height: 120px">
                <a-spin :spinning="state.identifyItLoading">
                  <Upload
                    :upload_path="`public/uploads/kezhang_fileupload_tmp/${dayjs().format('YYYYMM')}/`"
                    :accept="['png', 'jpg', 'jpeg']"
                    uploadType="picture-card"
                    :limitation="1"
                    v-model:value="formState.licence_url"
                    @change="handleLicenceChange"
                    uploadText="上传电子营业执照也行"
                  >
                  </Upload>
                  <div style="color: red; font-size: 14px; width: 300px; margin-top: -130px; margin-left: 120px">
                    <a-form-item label="主体名称" name="full_name" :required="true" :labelCol="{ span: 10 }" :wrapperCol="{ span: 14 }">
                      <a-input placeholder="请输入主体名称" v-model:value="formState.full_name" v-if="formState.status == 1 || formState.status == 3 || formState.status == null"></a-input>
                      <div v-else>{{ formState.full_name }}</div>
                    </a-form-item>
                    <a-form-item label="统一社会信用代码" name="social_credit_code" :labelCol="{ span: 10 }" :wrapperCol="{ span: 14 }">
                      <a-input
                        placeholder="请输入统一社会信用代码"
                        v-model:value="formState.social_credit_code"
                        v-if="formState.status == 1 || formState.status == 3 || formState.status == null"
                      ></a-input>
                      <div v-else>{{ formState.social_credit_code }}</div>
                    </a-form-item>
                  </div>
                </a-spin>
              </div>
              <div v-else>
                <a-image :width="100" :height="90" :src="$store.state.user.image_domain + formState.licence_url" />
              </div>
            </a-form-item>

            <a-form-item label="法人代表照片" name="id_card_front" :required="true">
              <div class="information" style="float: left; overflow: hidden">
                <div class="information-right">
                  <a-spin :spinning="state.loading_front" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                    <a-form-item-rest>
                      <a-upload
                        list-type="picture-card"
                        accept=".jpeg,.png,.jpg"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        :before-upload="beforeUpload"
                        @change="
                          (info) => {
                            handleChange(info, 'id_card_front')
                          }
                        "
                      >
                        <img class="upload-img" type="" v-if="formState.id_card_front" :src="$store.state.user.image_domain + formState.id_card_front" alt="avatar" />
                        <div v-else class="upload-img">
                          <PlusOutlined />
                          <div class="ant-upload-text">上传身份证(人像面)</div>
                        </div>
                      </a-upload>
                    </a-form-item-rest>
                  </a-spin>
                  <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.id_card_front" />
                  <a-spin :spinning="state.loading_back" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                    <a-form-item-rest>
                      <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept=".jpeg,.png,.jpg"
                        :show-upload-list="false"
                        :before-upload="beforeUpload"
                        @change="
                          (info) => {
                            handleChange(info, 'id_card_back')
                          }
                        "
                      >
                        <img class="upload-img" v-if="formState.id_card_back" :src="$store.state.user.image_domain + formState.id_card_back" alt="avatar" />
                        <div v-else class="upload-img">
                          <PlusOutlined />
                          <div class="ant-upload-text">上传身份证(国徽面)</div>
                        </div>
                      </a-upload>
                    </a-form-item-rest>
                  </a-spin>
                  <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.id_card_back" />
                  <a-form-item-rest v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                    <a-upload
                      list-type="picture-card"
                      accept=".jpeg,.png,.jpg"
                      :show-upload-list="false"
                      :before-upload="beforeUpload"
                      @change="
                        (info) => {
                          handleImageChange(info, 'one_inch_picture')
                        }
                      "
                    >
                      <img class="one_inch_picture" v-if="formState.one_inch_picture" :src="$store.state.user.image_domain + formState.one_inch_picture" alt="avatar" />
                      <div v-else>
                        <PlusOutlined />
                        <div class="ant-upload-text">上传用户自拍照</div>
                      </div>
                    </a-upload>
                  </a-form-item-rest>
                  <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.one_inch_picture" />
                </div>
              </div>
              <div style="color: red; font-size: 14px; width: 100%; float: left">提示：上传的身份证不能带水印，不然公安局系统备案通不过</div>
            </a-form-item>

            <div style="width: 1000px; margin: 0 auto 15px" v-show="false">
              <a-row>
                <a-col :span="2">性别</a-col>
                <a-col :span="4">身份证号码</a-col>
                <a-col :span="5">住址</a-col>
                <a-col :span="3">民族</a-col>
                <a-col :span="4">身份证有效期开始日期</a-col>
                <a-col :span="6">身份证有效期结束日期</a-col>
              </a-row>
              <a-row>
                <a-col :span="2"
                  ><a-form-item-rest>
                    <a-form-item class="m-0" name="gender" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                      <a-select style="width: 80px" v-model:value="formState.fr_gender" placeholder="性别">
                        <a-select-option :value="1"> 男</a-select-option>
                        <a-select-option :value="2"> 女</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form-item-rest></a-col
                >
                <a-col :span="4">
                  <a-form-item-rest>
                    <a-form-item class="m-0" name="id_card_number" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                      <a-input placeholder="身份证" v-model:value="formState.fr_id_card_number"></a-input>
                    </a-form-item>
                  </a-form-item-rest>
                </a-col>
                <a-col :span="5"
                  ><a-form-item-rest>
                    <a-form-item class="m-0" name="address" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                      <a-input placeholder="住所" v-model:value="formState.fr_address"></a-input>
                    </a-form-item> </a-form-item-rest
                ></a-col>
                <a-col :span="3"
                  ><a-form-item-rest>
                    <a-form-item class="m-0" name="nationality" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                      <a-select v-model:value="formState.fr_nationality" placeholder="民族">
                        <a-select-option :value="item" v-for="(item, index) in state.nationalityList" :Key="index"> {{ item }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form-item-rest></a-col
                >
                <a-col :span="4"
                  ><a-form-item-rest>
                    <a-form-item class="m-0" name="id_card_validity_start" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                      <a-date-picker placeholder="身份证开始日期" :format="format" v-model:value="formState.fr_id_card_validity_start" />
                    </a-form-item> </a-form-item-rest
                ></a-col>
                <a-col :span="6">
                  <a-form-item class="m-0" name="id_card_validity_end" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                    <a-form-item-rest>
                      <a-date-picker
                        placeholder="身份证结束日期"
                        :format="format"
                        v-model:value="formState.fr_id_card_validity_end"
                        style="width: 140px"
                        class="mr-1x"
                        :disabled="formState.fr_identity_perpetual == 1"
                      />
                    </a-form-item-rest>
                    <a-form-item-rest>
                      <a-checkbox :checked="formState.fr_identity_perpetual == 1 ? true : false" @change="identityPerpetualChange">长期</a-checkbox>
                    </a-form-item-rest>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <a-row>
              <a-col :span="8"
                ><a-form-item label="法人代表姓名" name="fr_name" :required="true" :labelCol="{ span: 12 }" :wrapperCol="{ span: 12 }">
                  <a-input placeholder="请输入法人代表姓名" v-model:value="formState.fr_name" v-if="formState.status == 1 || formState.status == 3 || formState.status == null"></a-input>
                  <div v-else>{{ formState.fr_name }}</div>
                </a-form-item></a-col
              >
              <a-col :span="16"
                ><a-form-item label="法人代表电话" name="fr_phone" :required="true" :labelCol="{ span: 6 }" :wrapperCol="{ span: 10 }">
                  <a-input
                    v-if="formState.status == 1 || formState.status == 3 || formState.status == null"
                    placeholder="请输入法人代表电话"
                    v-model:value="formState.fr_phone"
                    @change="
                      (v) => {
                        phoneChange(v, 'fr_phone')
                      }
                    "
                  ></a-input>
                  <div v-else>{{ formState.fr_phone }}</div>
                </a-form-item></a-col
              >
            </a-row>

            <a-form-item label="是否委托代表" name="is_db">
              <a-radio-group v-model:value="formState.is_db" button-style="solid" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                <a-radio-button :value="1">是</a-radio-button>
                <a-radio-button :value="2">否</a-radio-button>
              </a-radio-group>
              <div v-else>{{ formState.is_db == 1 ? '是' : '否' }}</div>
            </a-form-item>
            <div v-if="formState.is_db == 1">
              <a-form-item label="代办人身份证照片" name="db_id_card_front" :required="true">
                <div class="information">
                  <div class="information-right">
                    <a-spin :spinning="state.loading_db_front" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                      <a-form-item-rest>
                        <a-upload
                          list-type="picture-card"
                          accept=".jpeg,.png,.jpg"
                          class="avatar-uploader"
                          :show-upload-list="false"
                          :before-upload="beforeUpload"
                          @change="
                            (info) => {
                              handleChange(info, 'db_id_card_front')
                            }
                          "
                        >
                          <img class="upload-img" type="" v-if="formState.db_id_card_front" :src="$store.state.user.image_domain + formState.db_id_card_front" alt="avatar" />
                          <div v-else class="upload-img">
                            <PlusOutlined />
                            <div class="ant-upload-text">上传人员身份证照片(人像面)</div>
                          </div>
                        </a-upload>
                      </a-form-item-rest>
                    </a-spin>
                    <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.db_id_card_front" />
                    <a-spin :spinning="state.loading_db_back" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                      <a-form-item-rest>
                        <a-upload
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept=".jpeg,.png,.jpg"
                          :show-upload-list="false"
                          :before-upload="beforeUpload"
                          @change="
                            (info) => {
                              handleChange(info, 'db_id_card_back')
                            }
                          "
                        >
                          <img class="upload-img" v-if="formState.db_id_card_back" :src="$store.state.user.image_domain + formState.db_id_card_back" alt="avatar" />
                          <div v-else class="upload-img">
                            <PlusOutlined />
                            <div class="ant-upload-text">上传人员身份证照片(国徽面)</div>
                          </div>
                        </a-upload>
                      </a-form-item-rest>
                    </a-spin>
                    <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.db_id_card_back" />
                    <a-form-item-rest v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                      <a-upload
                        list-type="picture-card"
                        accept=".jpeg,.png,.jpg"
                        :show-upload-list="false"
                        :before-upload="beforeUpload"
                        @change="
                          (info) => {
                            handleImageChange(info, 'db_one_inch_picture')
                          }
                        "
                      >
                        <img class="one_inch_picture" v-if="formState.db_one_inch_picture" :src="$store.state.user.image_domain + formState.db_one_inch_picture" alt="avatar" />
                        <div v-else>
                          <PlusOutlined />
                          <div class="ant-upload-text">上传用户生活头像照片</div>
                        </div>
                      </a-upload>
                    </a-form-item-rest>
                    <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.db_one_inch_picture" />
                  </div>
                </div>
              </a-form-item>
              <a-form-item label="代办人姓名" name="db_name" :required="true">
                <a-input placeholder="请输入代办人姓名" v-model:value="formState.db_name" v-if="formState.status == 1 || formState.status == 3 || formState.status == null"></a-input>
                <div v-else>{{ formState.db_name }}</div>
              </a-form-item>
              <a-form-item label="代办人电话" name="db_phone" :required="true">
                <a-input
                  placeholder="请输入代办人电话"
                  v-model:value="formState.db_phone"
                  @change="
                    (v) => {
                      phoneChange(v, 'db_phone')
                    }
                  "
                  v-if="formState.status == 1 || formState.status == 3 || formState.status == null"
                ></a-input>
                <div v-else>{{ formState.db_phone }}</div>
              </a-form-item>
              <a-form-item label="授权委托书" name="db_weituoshu" :required="true">
                <Upload
                  :upload_path="`public/uploads/刻章管理/长沙哇哇叫刻章服务有限公司/客户资料/${dayjs().format('YYYYMM')}/${formState.full_name}/`"
                  :accept="['png', 'jpg', 'jpeg', 'pdf']"
                  uploadType="picture-card"
                  :limitation="1"
                  v-model:value="formState.db_weituoshu"
                  :beforeCheck="beforeCheck"
                  uploadText="授权委托书"
                  v-if="formState.status == 1 || formState.status == 3 || formState.status == null"
                >
                </Upload>
                <a-image v-else :width="150" :height="100" :src="$store.state.user.image_domain + formState.db_weituoshu" />
              </a-form-item>
            </div>

            <div class="form-tips-title">第三步：收货地址</div>
            <a-form-item label="邮寄付款类型" name="mailing_type">
              <a-radio-group v-model:value="formState.mailing_type" button-style="solid" @change="changeMailingType" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                <a-radio-button :value="1">快递发货</a-radio-button>
                <a-radio-button :value="2">跑腿发货</a-radio-button>
                <a-radio-button :value="3">现场取货</a-radio-button>
              </a-radio-group>
              <div v-else>{{ formState.mailing_type == 1 ? '快递发货' : formState.mailing_type == 2 ? '跑腿发货' : '现场取货' }}</div>
            </a-form-item>
            <a-form-item label="快递公司" v-if="formState.mailing_type == 1" name="mailing_type">
              <a-radio-group v-model:value="formState.mailing_kdgs" button-style="solid" @change="calcPrice" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                <a-radio-button :value="1">京东快递</a-radio-button>
                <a-radio-button :value="2">顺丰快递</a-radio-button>
              </a-radio-group>
              <div v-else>{{ formState.mailing_kdgs == 1 ? '京东快递' : '顺丰快递' }}</div>
            </a-form-item>
            <a-form-item label="付款方式" v-if="formState.mailing_type == 1" name="mailing_payment">
              <a-radio-group v-model:value="formState.mailing_payment" button-style="solid" @change="calcPrice" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                <a-radio-button :value="1">寄付</a-radio-button>
                <a-radio-button :value="2">到付</a-radio-button>
              </a-radio-group>
              <div v-else>{{ formState.mailing_payment == 1 ? '寄付' : '到付' }}</div>
            </a-form-item>
            <a-form-item label="发货类型" v-if="formState.mailing_type == 2" name="mailing_paotui">
              <a-radio-group v-model:value="formState.mailing_paotui" button-style="solid" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                <a-radio-button :value="1">刻章店发货</a-radio-button>
                <a-radio-button :value="2">自行发货</a-radio-button>
              </a-radio-group>
              <div v-else>{{ formState.mailing_paotui == 1 ? '刻章店发货' : '自行发货' }}</div>
            </a-form-item>
            <div v-if="formState.mailing_type == 2 && formState.mailing_paotui == 1" style="border: 3px dashed #ccc; margin-bottom: 5px">
              <a-form-item label="刻章店发货备注" name="mailing_paotui_remark"
                ><a>刻章店发货，仅发京东同城到付；自动发货推荐美团配送软件，价格比达达便宜，也可以自行叫滴滴车，我们送上车</a></a-form-item
              >
            </div>
            <div v-if="formState.mailing_type == 2 && formState.mailing_paotui == 2" style="border: 3px dashed #ccc; margin-bottom: 5px">
              <a-form-item label="寄件人姓名" name="mailing_paotui_name"> 填自己的姓名 或则填 沈判长刻章 </a-form-item>
              <a-form-item label="寄件人电话" name="mailing_paotui_tel"> 填自己的电话 或则填 15673641112 </a-form-item>
              <a-form-item label="刻章门店地址" name="mailing_paotui_address"> 湖南省长沙市芙蓉区马王堆街道浏阳河新桥家园8栋105号哇哇叫刻章店 </a-form-item>
              <a-form-item label="寄件备注" name="mailing_paotui_remark"> 务必要写章的取件码,取件码下单成功后会在列表页自动出现 </a-form-item>
            </div>
            <div v-if="formState.mailing_type == 1 || (formState.mailing_type == 2 && formState.mailing_paotui == 1)">
              <a-form-item label="收货人姓名" name="recipient_name">
                <div class="d-flex">
                  <a-input
                    class="mr-1x"
                    placeholder="请输入收货人姓名"
                    v-model:value="formState.recipient_name"
                    v-if="formState.status == 1 || formState.status == 3 || formState.status == null"
                  ></a-input>
                  <div v-else>{{ formState.recipient_name }}</div>
                </div>
              </a-form-item>
              <a-form-item label="收货人手机号码" name="recipient_mobile">
                <div class="d-flex" v-if="formState.status == 1 || formState.status == 3 || formState.status == null">
                  <a-input
                    class="mr-1x"
                    @change="
                      (v) => {
                        phoneChange(v, 'recipient_mobile')
                      }
                    "
                    :value="formState.recipient_mobile"
                    placeholder="请输入收货人手机号码"
                  >
                  </a-input>
                  <a-button :loading="state.emailLoading" type="primary" @click="emailChange">自动获取邮寄信息</a-button>
                </div>
                <div v-else>{{ formState.recipient_mobile }}</div>
              </a-form-item>
              <a-form-item label="收货人省市区" name="recipient_area_ids">
                <AddressCascader v-model:value="formState.recipient_area_ids" @change="residenceChange" :disabled="formState.status != 1 && formState.status != 3 && formState.status != null">
                </AddressCascader>
              </a-form-item>
              <a-form-item label="收货人地址" name="recipient_address">
                <a-input placeholder="请输入收货人地址" v-model:value="formState.recipient_address" v-if="formState.status == 1 || formState.status == 3 || formState.status == null"></a-input>
                <div v-else>{{ formState.recipient_address }}</div>
              </a-form-item>
              <a-form-item label="邮编" name="recipient_zipcode">
                <a-input placeholder="请输入邮编" v-model:value="formState.zipcode" v-if="formState.status == 1 || formState.status == 3 || formState.status == null"></a-input>
                <div v-else>{{ formState.zipcode }}</div>
              </a-form-item>
            </div>
            <div class="form-tips-title">第四步：订单备注（选填,方便自己记忆）</div>
            <a-form-item label="来源手机号" name="from_tel">
              <a-input
                placeholder="请输入来源手机号"
                v-model:value="formState.from_tel"
                @change="
                  (v) => {
                    phoneChange(v, 'from_tel')
                  }
                "
                v-if="formState.status == 1 || formState.status == 3 || formState.status == null"
              ></a-input>
              <div v-else>{{ formState.from_tel }}</div>
            </a-form-item>
            <a-form-item label="订单备注" name="order_note">
              <a-textarea placeholder="请输入" v-model:value="formState.order_note" v-if="formState.status == 1 || formState.status == 3 || formState.status == null"></a-textarea>
              <div v-else>{{ formState.order_note }}</div>
            </a-form-item>
          </a-form></a-col
        >
        <a-col :span="8">
          <div style="background: #f7f9fc; padding: 20px">
            <div class="form-tips-title">订单详情（下方按钮可以点击重选材质）</div>
            <div style="border-bottom: 1px dashed #e5e5e5; padding-top: 10px">
              <p v-if="formState.beian_zhang.includes('11')">
                公章：{{ formState.material_11_data.name }}√ ¥{{ formState.material_11_data.price }}
                <a-button type="primary" size="small" v-if="state.materialList[11].length > 1" @click="chooseMaterial(11)">重选材质</a-button>
              </p>
              <p v-if="formState.beian_zhang.includes('12')">
                财务章：{{ formState.material_12_data.name }}√ ¥{{ formState.material_12_data.price }}
                <a-button type="primary" size="small" v-if="state.materialList[12].length > 1" @click="chooseMaterial(12)">重选材质</a-button>
              </p>
              <p v-if="formState.beian_zhang.includes('13')">
                法人代表章：{{ formState.material_13_data.name }}√ ¥{{ formState.material_13_data.price }}
                <a-button type="primary" size="small" v-if="state.materialList[13].length > 1" @click="chooseMaterial(13)">重选材质</a-button>
              </p>
              <p v-if="formState.beian_zhang.includes('14')">
                合同章：{{ formState.material_14_data.name }}√ ¥{{ formState.material_14_data.price }}
                <a-button type="primary" size="small" v-if="state.materialList[14].length > 1" @click="chooseMaterial(14)">重选材质</a-button>
              </p>
              <p v-if="formState.beian_zhang.includes('15')">
                发票专用章：{{ formState.material_15_data.name }}√ ¥{{ formState.material_15_data.price }}
                <a-button type="primary" size="small" v-if="state.materialList[15].length > 1" @click="chooseMaterial(15)">重选材质</a-button>
              </p>
              <p v-if="formState.beian_zhang.includes('16')">
                其他类型章：{{ formState.material_16_data.name }}√ ¥{{ formState.material_16_data.price }}
                <a-button type="primary" size="small" v-if="state.materialList[16].length > 1" @click="chooseMaterial(16)">重选材质</a-button>
              </p>
            </div>

            <div>
              订单金额：<span style="color: #f60; font-size: 24px"> ¥{{ formState.actually_paid_money }} </span><span v-if="formState.postage > 0">（含邮费¥{{ formState.postage }}）</span>
            </div>
            <div>
              <span style="color: #f60; font-size: 24px"> <a-checkbox :checked="formState.anxin_use == 1 ? true : false" @change="anxinUseChange">【选购】安心USE加油服务</a-checkbox></span>
            </div>
            <div>（含：不限次到店免费加油！赠送1瓶10ML印油！）</div>
            <div><a-button type="primary" @click="onFormSubmit" size="large" style="width: 100%; margin: 0 auto; display: block; margin-top: 20px; border-radius: 5px">提交订单</a-button></div>
          </div>
          <div style="background: #fffaf0; padding: 20px; margin-top: 20px; color: #6b2d00">
            <div class="form-tips-title" style="color: #6b2d00">我们与别人不同的地方</div>
            <div style="border-bottom: 1px dashed #e5e5e5">
              <p>√ 1.我们是<span style="color: #f60">真检测！</span>每枚印章均经过人工细致检测，检测合格才寄出，并配有合格证。</p>
              <p>√ 2.我们是<span style="color: #f60">真备案！</span>每枚印章均经过高清拍照，再经过公安局备案,确保后期印章清晰可查。</p>
              <p>√ 3.我们是<span style="color: #f60">真效率！</span>印章刻制一套只要10分钟，真正费时的是光敏章吸油墨。</p>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-spin>
    <!-- 图片裁剪 -->
    <image-cropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :isId="state.currentUpload == 'id_card_front' || state.currentUpload == 'id_card_back'"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      :upload_path="`public/uploads/刻章管理/长沙哇哇叫刻章服务有限公司/客户资料/${dayjs().format('YYYYMM')}/${formState.full_name}/`"
      :customerName="formState.full_name"
      :isTiqianClose="true"
      @change="cropperPost"
      v-if="state.showCropper"
    ></image-cropper>
    <image-cropper
      @cancel="
        () => {
          state.showImageCropper = false
        }
      "
      :windowsSize="[130, 180]"
      :isId="false"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      @change="cropperImageChange"
      :upload_path="`public/uploads/刻章管理/长沙哇哇叫刻章服务有限公司/客户资料/${dayjs().format('YYYYMM')}/${formState.full_name}/`"
      :customerName="formState.full_name"
      v-if="state.showImageCropper"
    ></image-cropper>
    <!-- 选择材质 -->
    <Material
      v-if="materialData.visible"
      :zhangType="materialData.zhangType"
      :optionList="materialData.optionList"
      :defaultValue="materialData.defaultValue"
      @cancel="materialData.visible = false"
      @ok="materialOk"
    ></Material>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, h, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import ImageCropper from '@/components/ImageCropper/index.vue'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import { useStore } from 'vuex'
import Upload from '@/components/base/Upload.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import AddressCascader from '@/components/base/AddressCascader.vue'
import Material from './Material.vue'
const $store = useStore()
let dayTime = dayjs()
import ls from '@/utils/Storage'
const user = ls.get('USER_INFO')
let timer: any = null
const emit = defineEmits(['cancel', 'ok'])
const ruleForm = ref()
const props = defineProps({
  open: {
    type: Boolean
  },
  tableColumnsData: {
    type: Object,
    default: () => null
  },
  //customer一键刻章默认的值
  customerData: {
    type: Object,
    default: () => null
  }
})

const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
  loading_front: boolean
  loading_back: boolean
  loading_db_front: boolean
  loading_db_back: boolean
  showCropper: boolean
  showImageCropper: boolean
  imageUrl: string
  imageName: string
  currentUpload: string
  nationalityList: string[]
  emailLoading: boolean
  priceLoading: boolean
  identifyItLoading: boolean
  materialList: object
}
const state = reactive<state>({
  modalTitle: '刻章下单',
  loading: false,
  loading_front: false,
  loading_back: false,
  loading_db_front: false,
  loading_db_back: false,
  showCropper: false,
  showImageCropper: false,
  imageUrl: '',
  imageName: '',
  currentUpload: '',
  nationalityList: [
    '汉族',
    '蒙古族',
    '回族',
    '藏族',
    '维吾尔族',
    '苗族',
    '彝族',
    '壮族',
    '布依族',
    '朝鲜族',
    '满族',
    '侗族',
    '瑶族',
    '白族',
    '土家族',
    '哈尼族',
    '哈萨克族',
    '傣族',
    '黎族',
    '傈僳族',
    '佤族',
    '畲族',
    '高山族',
    '拉祜族',
    '水族',
    '东乡族',
    '纳西族',
    '景颇族',
    '柯尔克孜族',
    '土族',
    '达斡尔族',
    '仫佬族',
    '羌族',
    '布朗族',
    '撒拉族',
    '毛难族',
    '仡佬族',
    '锡伯族',
    '阿昌族',
    '普米族',
    '塔吉克族',
    '怒族',
    '乌孜别克族',
    '俄罗斯族',
    '鄂温克族',
    '崩龙族',
    '保安族',
    '裕固族',
    '京族',
    '塔塔尔族',
    '独龙族',
    '额伦春族',
    '赫哲族',
    '门巴族',
    '洛巴族',
    '基诺族',
    '其他',
    '外国血统中国籍人士'
  ],
  emailLoading: false,
  priceLoading: false,
  identifyItLoading: false,
  materialList: {}
})

let validateEnd = (rule, value) => {
  if (!value && formState.fr_identity_perpetual != 1) {
    return Promise.reject(new Error('请选择身份证结束日期'))
  } else if (value && formState.fr_identity_perpetual != 1) {
    const start = dayjs(value, 'YYYY-MM-DD')
    const diff = start.diff(dayTime, 'day')
    if (diff < 5) {
      return Promise.reject(new Error('身份证结束日期不能少于5天'))
    } else {
      return Promise.resolve()
    }
  } else {
    return Promise.resolve()
  }
}
const rules = {
  fr_id_card_validity_start: [{ required: false, message: '请选择身份证开始日期', trigger: 'change', type: 'object' }],
  fr_id_card_validity_end: [{ required: false, validator: validateEnd, trigger: 'change' }]
}

interface formState {
  full_name: string
  social_credit_code: string
  fr_name: string
  fr_phone: string
  fr_gender: string | null
  fr_id_card_number: string | null
  fr_address: string | null
  fr_nationality: string | null
  fr_identity_perpetual: number | null
  fr_id_card_validity_start: any
  fr_id_card_validity_end: any
  licence_url: string
  one_inch_picture: string
  id_card_front: string
  id_card_back: string
  is_db: number
  db_name: string
  db_phone: string
  db_one_inch_picture: string
  db_id_card_front: string
  db_id_card_back: string
  db_weituoshu: string
  beian_zhang: string[]
  material_11_data: any
  material_12_data: any
  material_13_data: any
  material_14_data: any
  material_15_data: any
  material_16_data: any
  actually_paid_money: number
  postage: number
  mailing_type: number
  mailing_kdgs: number
  mailing_payment: number
  mailing_paotui: number
  recipient_name: string
  recipient_mobile: string
  zipcode: string
  recipient_address: string
  recipient_area_ids: string | undefined
  order_note: string
  from_tel: string
  status: number | null
  pay_status: number | null
  anxin_use: number
  licence_address: string
  licence_province: string
  licence_city: string
  licence_district: string
}
const formState = reactive<formState>({
  full_name: '',
  social_credit_code: '',
  fr_name: '',
  fr_phone: '',
  fr_gender: null,
  fr_id_card_number: null,
  fr_address: null,
  fr_nationality: null,
  fr_identity_perpetual: 2,
  fr_id_card_validity_start: null,
  fr_id_card_validity_end: null,
  licence_url: '',
  one_inch_picture: '',
  id_card_front: '',
  id_card_back: '',
  is_db: 2, //默认不是委托代办
  db_name: '', //代表人姓名
  db_phone: '',
  db_one_inch_picture: '',
  db_id_card_front: '',
  db_id_card_back: '',
  db_weituoshu: '',
  beian_zhang: [],
  material_11_data: null,
  material_12_data: null,
  material_13_data: null,
  material_14_data: null,
  material_15_data: null,
  material_16_data: null,
  actually_paid_money: 0,
  postage: 0, //邮费
  mailing_type: 1,
  mailing_kdgs: 1, //默认是京东
  mailing_payment: 2, //默认到付
  mailing_paotui: 2, //默认自行发货
  recipient_name: '',
  recipient_mobile: '',
  zipcode: '410000',
  recipient_address: '',
  recipient_area_ids: undefined,
  order_note: '',
  from_tel: '',
  status: null,
  pay_status: null,
  anxin_use: 2,
  licence_address: '',
  licence_province: '',
  licence_city: '',
  licence_district: ''
})

const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  formState[type] = str
}

onMounted(() => {
  initMaterial()
  if (props.tableColumnsData?.id) {
    if (props.tableColumnsData.isCopy) {
      queryDetail(props.tableColumnsData.id)
      state.modalTitle = '再下一单'
    } else {
      queryDetail(props.tableColumnsData.id)
      state.modalTitle = '编辑刻章'
    }
  } else if (props.customerData) {
    //一键刻章
    initCreate()
  }
  //只添加的时候自动获取邮寄地址
  if (!props.tableColumnsData?.id) {
    formState.recipient_mobile = user.phone
    emailChange()
  }
})
const initCreate = () => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/kezhang/create`,
    method: 'post',
    data: { customer_id: props.customerData.id ?? 0 }
  }
  baseService(requeryParam).then((res: any) => {
    state.loading = false
    formState.beian_zhang = ['11', '12', '13']
    if (res.data.fr_id_card_validity_end) {
      res.data.fr_id_card_validity_end = dayjs(res.data.fr_id_card_validity_end, format)
    }
    if (res.data.fr_id_card_validity_start) {
      res.data.fr_id_card_validity_start = dayjs(res.data.fr_id_card_validity_start, format)
    }
    Object.assign(formState, res.data)
    if (res.data.recipient_province_id && res.data.recipient_city_id && res.data.recipient_area_id) {
      formState.recipient_area_ids = res.data.recipient_province_id + ',' + res.data.recipient_city_id + ',' + res.data.recipient_area_id
    }
    calcPrice()
  })
}

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/kezhang/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    res.data.fr_id_card_validity_end = dayjs(res.data.fr_id_card_validity_end, format)
    res.data.fr_id_card_validity_start = dayjs(res.data.fr_id_card_validity_start, format)
    Object.assign(formState, res.data)
    if (res.data.recipient_province_id && res.data.recipient_city_id && res.data.recipient_area_id) {
      formState.recipient_area_ids = res.data.recipient_province_id + ',' + res.data.recipient_city_id + ',' + res.data.recipient_area_id
    }
    if (props.tableColumnsData?.id > 0 && props.tableColumnsData.isCopy) {
      //如果是复制,清空一些数据
      formState.status = null
    }
  })
}
// 上传图片之前检查
const beforeCheck = () => {
  if (formState.full_name == '') {
    message.warning('请先输入主体名称')
    return false
  }
  return true
}
// 手动上传验证
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请上传正确图片！')
  }
  const isLt2M = file.size / 1024 / 1024 < 10
  if (!isLt2M) {
    message.error('图片必须小于10MB!')
  }
  return false
}
// 图片上传
const handleChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  const isGt50K = info.file.size / 1024 < 50
  if (isGt50K) {
    message.error('图片必须大于50KB!')
    return
  }
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}
//上传裁剪后的图片
const cropperPost = (formData, isRecognize) => {
  //这个变量会在点另一个的时候变化，先记录一下再关闭窗口
  let currentUpload = state.currentUpload
  state.showCropper = false
  if (currentUpload == 'id_card_front') {
    state.loading_front = true
  } else if (currentUpload == 'id_card_back') {
    state.loading_back = true
  } else if (currentUpload == 'db_id_card_front') {
    state.loading_db_front = true
  } else if (currentUpload == 'db_id_card_back') {
    state.loading_db_back = true
  }
  if (isRecognize) {
    let requestParam = {
      url: '/admin/v1/ocr',
      method: 'post',
      data: formData
    }
    baseService(requestParam)
      .then((res) => {
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        } else if (currentUpload == 'db_id_card_front') {
          state.loading_db_front = false
        } else if (currentUpload == 'db_id_card_back') {
          state.loading_db_back = false
        }
        cropperChange(res, isRecognize, currentUpload)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        } else if (currentUpload == 'db_id_card_front') {
          state.loading_db_front = false
        } else if (currentUpload == 'db_id_card_back') {
          state.loading_db_back = false
        }
      })
  } else {
    let requestParam = {
      url: '/admin/v1/upload',
      method: 'post',
      data: formData
    }
    baseService(requestParam)
      .then((res) => {
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        } else if (currentUpload == 'db_id_card_front') {
          state.loading_db_front = false
        } else if (currentUpload == 'db_id_card_back') {
          state.loading_db_back = false
        }
        cropperChange(res.data, isRecognize, currentUpload)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        } else if (currentUpload == 'db_id_card_front') {
          state.loading_db_front = false
        } else if (currentUpload == 'db_id_card_back') {
          state.loading_db_back = false
        }
      })
  }
}
// 裁剪成功
const format = 'YYYY-MM-DD'
const cropperChange = (res, isRecognize, currentUpload) => {
  if (isRecognize) {
    let data = res.data.身份证识别实体信息
    if (currentUpload == 'id_card_back') {
      if (data.身份证国徽面实体信息.有效期限.length < 1) {
        message.error('请上传身份证正面照片（国徽面）')
        formState.id_card_back = ''
        return
      }
      let list = data.身份证国徽面实体信息.有效期限.split('-').map((item, index) => {
        return item.replaceAll('.', '-')
      })
      let obj = {
        fr_id_card_validity_start: dayjs(list[0], format),
        fr_id_card_validity_end: dayjs(list[1], format),
        fr_identity_perpetual: list[1] == '长期' ? 1 : 2
      }
      Object.assign(formState, obj)
    } else if (currentUpload == 'id_card_front') {
      if (data.身份证人像面实体信息.姓名.length < 1) {
        message.error('请上传身份证反面照片（人像面）')
        formState.id_card_front = ''
        return
      }
      let nationality: any = null
      state.nationalityList.forEach((item) => {
        if (item.indexOf(data.身份证人像面实体信息.民族) > -1) {
          nationality = item
        }
      })
      let obj = {
        fr_name: data.身份证人像面实体信息.姓名,
        fr_address: data.身份证人像面实体信息.住址,
        fr_id_card_number: data.身份证人像面实体信息.身份证号,
        fr_gender: data.身份证人像面实体信息.性别 == '男' ? 1 : 2,
        fr_nationality: nationality
      }
      Object.assign(formState, obj)
    }
    //后面识别再补充代办人的识别
    formState[currentUpload] = res.data.url
  } else {
    formState[currentUpload] = res.url
  }
}
// 裁剪头像
const handleImageChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  const isGt50K = info.file.size / 1024 < 50
  if (isGt50K) {
    message.error('图片必须大于50KB!')
    return
  }
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showImageCropper = true
  }
}
// 裁剪成功
const cropperImageChange = (res) => {
  let currentUpload = state.currentUpload
  formState[currentUpload] = res.url
  state.showImageCropper = false
}
const changeMailingType = () => {
  if (formState.mailing_type == 1 && !formState.mailing_payment) {
    formState.mailing_payment = 2
  }
  if (formState.mailing_type == 2 && !formState.mailing_paotui) {
    formState.mailing_paotui = 2
  }
  calcPrice()
}
// 营业执照识别
const handleLicenceChange = (info) => {
  if (!formState.licence_url) {
    return
  }
  let imgurl = $store.state.user.image_domain + formState.licence_url
  state.identifyItLoading = true
  let requestParam = {
    url: '/admin/v1/identifyIt',
    method: 'post',
    data: {
      imgurl: imgurl
    }
  }
  baseService(requestParam)
    .then((res) => {
      state.identifyItLoading = false
      let companyName = res.data['企业名称']
      //把res.data['企业名称']中的(替换成（
      formState.full_name = companyName.replace(/\(/g, '（').replace(/\)/g, '）')
      formState.social_credit_code = res.data['企业统一社会信用编码']
      //   formState.founding_time = res.data['企业注册时间']
      formState.licence_address = res.data['企业注册地址']
      formState.licence_province = res.data['企业注册地址所在省份']
      formState.licence_city = res.data['企业注册地址所在城市']
      formState.licence_district = res.data['企业注册地址所在区域']
      message.success('识别成功！')
    })
    .catch((res) => {
      state.identifyItLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('识别失败')
      }
    })
}
const changeBeianZhang = (val) => {
  //选上默认的材质
  calcPrice()
}
//计算金额
const calcPrice = () => {
  state.priceLoading = true
  let requeryParam = {
    url: '/admin/v1/kezhang/calcPrice',
    method: 'post',
    data: formState
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.priceLoading = false
      formState.actually_paid_money = res.data.actually_paid_money
      formState.postage = res.data.postage
    })
    .catch((res) => {
      state.priceLoading = false
      if (res) {
        if (res.msg) {
          message.warning(res.msg)
          return
        }
      }
    })
}
// 邮寄地址省市区
const residenceChange = (idList, value: any) => {
  let address = `${value[0].name}${value[1].name}${value[2].name}`
  formState.recipient_address = address
  calcPrice()
}
// 获取邮寄信息
const emailChange = () => {
  if (!formState.recipient_mobile) {
    message.warn('请填写收货人手机号码再获取邮寄信息')
    return
  }
  state.emailLoading = true
  let requeryParam = {
    url: '/admin/v1/mailingAddressList',
    method: 'post',
    data: {
      page: 1,
      limit: 'all',
      customer_tel: formState.recipient_mobile
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      if (res.data.list?.length > 0) {
        formState.recipient_name = res.data.list[0].name
        formState.recipient_mobile = res.data.list[0].tel
        formState.recipient_address = res.data.list[0].address
        formState.recipient_area_ids = `${res.data.list[0].recipient_province_id},${res.data.list[0].recipient_city_id},${res.data.list[0].recipient_area_id}`
      }
      //   message.success(res.msg)
      calcPrice()
      state.emailLoading = false
    })
    .catch((res) => {
      if (res) {
        state.emailLoading = false
        if (res.msg) {
          //   message.warning(res.msg)
          return
        }
      }
    })
}
//
const identityPerpetualChange = (val) => {
  formState.fr_identity_perpetual = val.target.checked ? 1 : 2
  ruleForm.value.validateFields('fr_id_card_validity_end')
}
const anxinUseChange = (val) => {
  formState.anxin_use = val.target.checked ? 1 : 2
  calcPrice()
}

// 提交
const onFormSubmit = () => {
  if (formState.id_card_front == '' || formState.id_card_back == '' || formState.one_inch_picture == '') {
    message.warning('请上传身份证和头像')
    return
  }

  if (formState.is_db == 1) {
    if (formState.db_id_card_front == '' || formState.db_id_card_back == '' || formState.db_one_inch_picture == '' || formState.db_weituoshu == '') {
      message.warning('请上传代办人身份证和头像,授权委托书')
      return
    }
  }
  if (formState.beian_zhang.length < 1) {
    message.warning('请选择刻章类型')
    return
  }
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.tableColumnsData?.id > 0 && !props.tableColumnsData.isCopy) {
        let requeryParam = {
          url: '/admin/v1/kezhang/' + props.tableColumnsData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok', 'edit', props.tableColumnsData.id)
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/kezhang',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok', 'add', res.data)
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      }
    })
    .catch(() => {
      message.warning('请检查表单必填项')
    })
}
//选择材质
// 查询类型对应的材质选项
const initMaterial = () => {
  state.loading = true
  let requeryParam = {
    url: '/admin/v1/kezhang/material',
    method: 'post',
    data: {}
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.materialList = res.data
      //选上默认的材质
      formState.material_11_data = res.data[11][0]
      formState.material_12_data = res.data[12][0]
      formState.material_13_data = res.data[13][0]
      formState.material_14_data = res.data[14][0]
      formState.material_15_data = res.data[15][0]
      formState.material_16_data = res.data[16][0]
    })
    .catch((res) => {
      if (res.data) {
        message.warning('查询材质失败')
      } else {
        message.warning(res.msg)
      }
    })
    .finally(() => {
      state.loading = false
    })
}
const materialData = reactive<any>({
  visible: false,
  zhangType: 11,
  defaultValue: null,
  optionList: []
})
const chooseMaterial = (type) => {
  materialData.zhangType = type
  if (type == 11) {
    materialData.defaultValue = formState.material_11_data
  } else if (type == 12) {
    materialData.defaultValue = formState.material_12_data
  } else if (type == 13) {
    materialData.defaultValue = formState.material_13_data
  } else if (type == 14) {
    materialData.defaultValue = formState.material_14_data
  } else if (type == 15) {
    materialData.defaultValue = formState.material_15_data
  } else if (type == 16) {
    materialData.defaultValue = formState.material_16_data
  }

  materialData.optionList = state.materialList[type]
  materialData.visible = true
}
const materialOk = (type, val) => {
  if (type == 11) {
    formState.material_11_data = val
  } else if (type == 12) {
    formState.material_12_data = val
  } else if (type == 13) {
    formState.material_13_data = val
  } else if (type == 14) {
    formState.material_14_data = val
  } else if (type == 15) {
    formState.material_15_data = val
  } else if (type == 16) {
    formState.material_16_data = val
  }
  materialData.visible = false
  calcPrice()
}
</script>

<style lang="less" scoped>
.information {
  display: flex;
  justify-content: center;
}

.information-right {
  display: flex;
  justify-content: center;
}

.information-card {
  margin-bottom: 20px;
  border-radius: 10px;

  :deep(.ant-card-head) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #40a9ff;
    color: #fff;
  }
}

.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 150px !important;
    height: 100px !important;
  }
}

.one_inch_picture {
  width: 100px;
  height: 100px;
}

.upload-img {
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td) {
  padding-left: 28px;
}
</style>
