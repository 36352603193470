<template>
  <a-modal
    :bodyStyle="{ maxHeight: '80vh', overflow: 'auto' }"
    :centered="true"
    :title="state.modalTitle"
    :width="1200"
    :open="open"
    :confirmLoading="state.confirmLoading"
    :maskClosable="state.maskClosable"
    @ok="onFormSubmit"
    @cancel="cancelClick"
  >
    <template #footer>
      <a-button key="取消" @click="cancelClick">取消</a-button>
      <a-button key="确定提交" type="primary" :loading="formState.loading" @click="onFormSubmit">确定提交</a-button>
    </template>
    <a-spin :spinning="state.loading">
      <a-form ref="formRef" :model="formState" :rules="rules" v-bind="formLayout">
        <!-- 基础信息 -->
        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="注销类型">
          <a-radio-group v-model:value="formState.logout_type" button-style="solid">
            <a-radio-button :value="1">简易注销</a-radio-button>
            <a-radio-button :value="2">一般注销</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="待注销的主体类型">
          <a-radio-group v-model:value="formState.main_type" button-style="solid" @change="principalTypeChange">
            <a-radio-button :value="1">公司</a-radio-button>
            <a-radio-button :value="2">个体户</a-radio-button>
            <a-radio-button :value="3">个独</a-radio-button>
            <a-radio-button :value="4">合伙</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="主体名称" name="full_name">
          <a-input v-model:value="formState.full_name" placeholder="请输入主体名称"></a-input>
        </a-form-item>
        <a-form-item label="统一社会信用代码" name="social_credit_code">
          <a-input v-model:value="formState.social_credit_code" placeholder="请输入统一社会信用代码"></a-input>
        </a-form-item>
        <a-form-item label="主体所在省市区" name="recipient_area_ids">
          <AddressCascader v-model:value="formState.recipient_area_ids" @change="recipient_area_ids_change"> </AddressCascader>
        </a-form-item>
        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="授权方式">
          <a-radio-group v-model:value="formState.logout_sqtype" button-style="solid">
            <a-radio-button :value="2">电子营业执照授权</a-radio-button>
            <a-radio-button :value="1">联络员收验证码</a-radio-button> </a-radio-group
          ><br />
          推荐电子营业执照授权，省得邮寄，否则请发寄付快递过来。
        </a-form-item>
        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="授权方式详细说明" v-if="formState.logout_sqtype == 2">
          <p style="border: 3px dashed #ccc; padding: 10px; width: 480px">
            用法人代表的手机微信搜索“电子营业执照”<br />点击【下载电子营业执照】-输入信息-完成人脸识别-点击湖南-点击主体名称<br />返回主页-点击【授权他人管理营业执照】-点击店名-输入营业执照密码123456<br />【添加证照管理员】输入信息-确认选择。
            丁朋的信息： <br />丁朋 430703198911273258 手机号码随便填
          </p></a-form-item
        >

        <a-form-item label="联络员身份证号码" name="liaison_identity" v-if="formState.logout_sqtype == 1">
          <a-input v-model:value="formState.liaison_identity" placeholder="请输入联络员身份证号码"></a-input>
        </a-form-item>

        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="原营业执照提交方式">
          <a-radio-group v-model:value="formState.appFlog_sumType" button-style="solid" @change="yyzzliandong">
            <a-radio-button :value="1">到登记窗口提交</a-radio-button>
            <a-radio-button :value="3">执照遗失或损坏公告作废</a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="注销原因" name="etpsRepeal_rplReasonGb" v-if="formState.main_type == 1">
          <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.etpsRepeal_rplReasonGb" placeholder="请选择">
            <a-select-option value="经营期限届满">经营期限届满</a-select-option>
            <a-select-option value="决议解散">决议解散</a-select-option>
            <a-select-option value="强制解散">强制解散</a-select-option>
            <a-select-option value="责令关闭">责令关闭</a-select-option>
            <a-select-option value="被撤销">被撤销</a-select-option>
            <a-select-option value="宣告破产">宣告破产</a-select-option>
            <a-select-option value="被吊销营业执照">被吊销营业执照</a-select-option>
            <a-select-option value="隶属(派出)企业终止">隶属(派出)企业终止</a-select-option>
            <a-select-option value="外国（地区）企业 撤销代表机构">外国（地区）企业 撤销代表机构</a-select-option>
            <a-select-option value="因合并或分立">因合并或分立</a-select-option>
            <a-select-option value="章程规定的解散事由出现">章程规定的解散事由出现</a-select-option>
            <a-select-option value="合伙人已不具备法定人数">合伙人已不具备法定人数</a-select-option>
            <a-select-option value="合伙协议约定的合伙目的已经实现或者无法实现">合伙协议约定的合伙目的已经实现或者无法实现</a-select-option>
            <a-select-option value="投资人死亡或者被宣告死亡,无继承人或者继承人决定放弃继承">投资人死亡或者被宣告死亡,无继承人或者继承人决定放弃继承</a-select-option>
            <a-select-option value="合伙协议约定的解散事由出现">合伙协议约定的解散事由出现</a-select-option>
            <a-select-option value="企业法人歇业">企业法人歇业</a-select-option>
            <a-select-option value="其他原因">其他原因</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="注销原因" name="etpsRepeal_rplReasonGb" v-if="formState.main_type == 2">
          <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.etpsRepeal_rplReasonGb" placeholder="请选择">
            <a-select-option value="停止经营">停止经营</a-select-option>
            <a-select-option value="依法被吊销营业执照">依法被吊销营业执照</a-select-option>
            <a-select-option value="依法被撤销设立登记">依法被撤销设立登记</a-select-option>
            <a-select-option value="个转企">个转企</a-select-option>
            <a-select-option value="改为公司">改为公司</a-select-option>
            <a-select-option value="改为个人独资">改为个人独资</a-select-option>
            <a-select-option value="改为合伙企业">改为合伙企业</a-select-option>
            <a-select-option value="转让给其他经营者">转让给其他经营者</a-select-option>
            <a-select-option value="其他">其他</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item name="isTaxReg" label="是否已办理涉税事项" v-if="formState.main_type == 2">
          <a-radio-group v-model:value="formState.isTaxReg" button-style="solid">
            <a-radio-button :value="1">是</a-radio-button>
            <a-radio-button :value="0">否</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="清税证明文号" name="clearTaxFileNo" v-if="formState.isTaxReg == 1 && formState.main_type == 2">
          <a-input v-model:value="formState.clearTaxFileNo" placeholder="请输入清税证明文号"></a-input>
        </a-form-item>
        <a-form-item label="主管税务机关" name="taxRegOrgan" v-if="formState.isTaxReg == 1 && formState.main_type == 2">
          <a-input v-model:value="formState.taxRegOrgan" placeholder="请输入主管税务机关"></a-input>
        </a-form-item>
        <a-form-item label="注销备注" name="memo" v-if="formState.main_type == 2">
          <a-input v-model:value="formState.memo" placeholder="请输入注销备注"></a-input>
        </a-form-item>
        <a-form-item label="公告报纸名称" name="etpsRepeal_announcePaper" v-if="formState.main_type == 1 && formState.appFlog_sumType == 3">
          <a-input v-model:value="formState.etpsRepeal_announcePaper" placeholder="请输入公告报纸名称"></a-input>
        </a-form-item>
        <a-form-item label="清税情况" name="etpsRepeal_payTax" v-if="formState.main_type == 1">
          <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.etpsRepeal_payTax" placeholder="请选择">
            <a-select-option value="1">已经清理完毕</a-select-option>
            <a-select-option value="2">未涉及纳税义务</a-select-option>
          </a-select>
        </a-form-item>
        <!-- 文件信息 -->
        <a-form-item name="investor_letter" htmlFor="{null}" label="全体投资人承诺书" v-if="formState.main_type == 1">
          <UploadTmp uploadType="picture-card" :limitation="99" :accept="['jpg', 'jpeg']" v-model:value="formState.investor_letter" uploadText="全体投资人承诺书"> </UploadTmp>
        </a-form-item>
        <a-form-item name="decree_dissolution" htmlFor="{null}" label="人民法院的破产裁定、解散裁判文书，行政机关责令关闭或者公司被撤销的文件" v-if="formState.main_type == 1">
          <UploadTmp uploadType="picture-card" :limitation="99" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.decree_dissolution" uploadText="其它文件"> </UploadTmp>
        </a-form-item>

        <a-form-item name="proprietor_identity_copy" label="经营者身份证复印件" htmlFor="{null}" v-if="formState.main_type == 2">
          <UploadTmp uploadType="picture-card" :limitation="99" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.proprietor_identity_copy" uploadText="经营者身份证复印件"> </UploadTmp>
        </a-form-item>
        <a-form-item name="business_license_and_copy" :label="formState.appFlog_sumType == 3 ? '全国企业网遗失公示截图' : '营业执照正本和副本'" htmlFor="{null}" :required="state.isRequired">
          <UploadTmp
            :accept="['png', 'jpg', 'jpeg', 'pdf']"
            uploadType="picture-card"
            :limitation="99"
            v-model:value="formState.business_license_and_copy"
            :uploadText="formState.appFlog_sumType == 3 ? '全国企业网遗失公示截图' : '营业执照正本和副本'"
          >
          </UploadTmp>
        </a-form-item>

        <a-form-item name="tax_authority_clear_proof" label="税务机关出具的清税证明" htmlFor="{null}">
          <UploadTmp :accept="['png', 'jpg', 'jpeg', 'pdf']" uploadType="picture-card" :limitation="99" v-model:value="formState.tax_authority_clear_proof" uploadText="税务机关出具的清税证明">
          </UploadTmp>
        </a-form-item>
        <a-form-item name="dbtype" label="是否委托代办">
          <a-radio-group v-model:value="formState.dbtype" button-style="solid" @change="quxiaoLly">
            <a-radio-button :value="1">是</a-radio-button>
            <a-radio-button :value="2" v-if="formState.main_type == 2">否</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="代办人身份证姓名" name="agent_idcard_name" :required="formState.dbtype == 1">
          <a-select
            placeholder="请输入代办人身份证姓名"
            style="width: 100%"
            optionLabelProp="label"
            v-model:value="formState.agent_idcard_name"
            show-search
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
          >
            <a-select-option v-for="(optionsItem, optionsIndex) in state.selectOptions" :key="optionsIndex" :value="optionsItem.customer_name" :label="optionsItem.customer_name" :title="optionsItem">
              <a-tooltip>
                {{ optionsItem.customer_name }}
              </a-tooltip>
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="身份证证件号码" name="agent_idcard_num" :required="formState.dbtype == 1">
          <a-input v-model:value="formState.agent_idcard_num" placeholder="请输入身份证证件号码"></a-input>
        </a-form-item>
        <a-form-item label="代办人联系电话" name="agent_tel" :required="formState.dbtype == 1">
          <a-input v-model:value="formState.agent_tel" placeholder="请输入代办人联系电话"></a-input>
        </a-form-item>
        <a-form-item label="代办人身份证正反面照片" name="identity_img_z" :required="formState.dbtype == 1">
          <div class="information">
            <div class="information-right" style="float: left">
              <a-row>
                <a-col :span="12">
                  <UploadTmp uploadType="picture-card" :limitation="1" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.identity_img_z" uploadText="上传人员身份证照片(人像面)">
                  </UploadTmp
                ></a-col>
                <a-col :span="12">
                  <UploadTmp uploadType="picture-card" :limitation="1" :accept="['png', 'jpg', 'jpeg', 'pdf']" v-model:value="formState.identity_img_f" uploadText="上传人员身份证照片(国徽面)">
                  </UploadTmp
                ></a-col>
              </a-row>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="联系人" name="lianxi_name">
          <a-input v-model:value="formState.lianxi_name" placeholder="请输入联系人姓名"></a-input>
        </a-form-item>
        <a-form-item label="来源手机号" name="lianxi_mobile">
          <a-input v-model:value="formState.lianxi_mobile" placeholder="请输入来源手机号"></a-input>
        </a-form-item>
        <a-form-item label="我的销售价" name="sales_price">
          <a-input class="mr-2x" type="number" addon-after="元" v-model:value="formState.sales_price" style="width: 200px" placeholder="请输入我的销售价"> </a-input>
        </a-form-item>
      </a-form>
    </a-spin>
    <!-- 公司添加人员 -->
  </a-modal>
</template>

<script lang="ts" setup>
import baseService from '@/utils/http/axios'
import UploadTmp from '@/components/base/UploadTmp.vue'
import { reactive, onMounted, nextTick, h, ref, onBeforeUnmount, watch, computed } from 'vue'
import { message, Modal } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import AddressCascader from '@/components/base/AddressCascader.vue'
import { mobileRequiredValidator, mobileValidator } from '@/utils/util'
import ls from '@/utils/Storage'
const UserInfo = ls.get('USER_INFO')
const emit = defineEmits(['ok', 'cancel'])
import { useStore } from 'vuex'
const $store = useStore()
const formRef = ref()
const principalName = ref()
// 防抖器
let timer: any = null
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
// 定时器
let interval
onBeforeUnmount(() => {
  clearInterval(interval)
})
//规则
const etpsRepeal_rplReasonGb = (rule, value) => {
  //目前只有两种需要选注销原因
  if (formState.main_type == 1 || formState.main_type == 2) {
    if (!value) {
      return Promise.reject('请选择注销原因')
    }
  }
  return Promise.resolve()
}
// const memo = (rule, value) => {
//   if (formState.main_type == 2) {
//     if (!value) {
//       return Promise.reject('请输入注销备注')
//     }
//   }
//   return Promise.resolve()
// }
const clearTaxFileNo = (rule, value) => {
  if (formState.main_type == 2 && formState.isTaxReg == 1) {
    if (!value) {
      return Promise.reject('请输入清税证明文号')
    }
  }
  return Promise.resolve()
}
const taxRegOrgan = (rule, value) => {
  if (formState.main_type == 2 && formState.isTaxReg == 1) {
    if (!value) {
      return Promise.reject('请输入主管税务机关')
    }
  }
  return Promise.resolve()
}
const etpsRepeal_announcePaper = (rule, value) => {
  if (formState.main_type == 1) {
    if (!value) {
      return Promise.reject('请输入公告报纸名称')
    }
  }
  return Promise.resolve()
}
const rules: Record<string, Rule[]> = {
  platform_id: [{ required: true, message: '选择平台' }],

  full_name: [{ required: true, message: '请输入主体名称' }],
  social_credit_code: [{ required: true, message: '请输入社会信用代码' }],
  liaison_identity: [{ required: true, message: '请输入联络员身份证号码' }],

  // agent_tel: [{ required: true, validator: mobileValidator }],
  identity_img_z: [{ message: '请输入身份证正面' }],
  identity_img_f: [{ message: '请输入身份证反面' }],
  recipient_area_ids: [{ message: '请填写省市区', trigger: 'change' }],
  appFlog_sumType: [{ required: true, message: '请选择营业执照提交方式' }],

  etpsRepeal_rplReasonGb: [{ validator: etpsRepeal_rplReasonGb }],
  //   memo: [{ validator: memo }],
  clearTaxFileNo: [{ validator: clearTaxFileNo }],
  taxRegOrgan: [{ validator: taxRegOrgan }],

  etpsRepeal_announcePaper: [{ validator: etpsRepeal_announcePaper }],
  proprietor_identity_copy: [{ required: true, message: '请输入经营者身份证复印件' }]
}
const yyzzliandong = () => {
  if (formState.appFlog_sumType == 3) {
    //全国企业网遗失公示截图
    state.isRequired = false
  } else {
    //营业执照正本和副本2024-08-27改成非必填
    state.isRequired = false
  }
}
interface formState {
  logout_type: number | undefined //注销类型 1简易注销  2一般注销
  main_type: number | undefined //主体类型：1、公司，2、个体，3、个人独资企业，4合伙企业
  logout_sqtype: number | undefined //主体类型：1、收验证码2扫码
  main_type_name: string | undefined //主体类型中文
  recipient_area_ids: string | undefined //主体类型中文
  loading: boolean
  full_name: string | undefined //公司名字
  social_credit_code: string | null //统一社会信用代码
  liaison_identity: string | null //联络员身份证号码
  agent_idcard_name?: string | null //代办人名字
  agent_idcard_num?: string | null //代办人身份证号码
  agent_tel?: string | null //代办人手机号

  identity_img_z: string | undefined //身份证图片正面
  identity_img_f: string | undefined

  appFlog_sumType: number | undefined //原营业执照提交方式 1到登记窗口提交,3执照遗失或损坏公告作废
  etpsRepeal_rplReasonGb: string | undefined //注销原因
  isTaxReg: number | null //是否已办理涉税事项 1是 2否
  clearTaxFileNo: string | undefined //清税证明文号
  taxRegOrgan: string | null //主管税务机关
  memo: string | undefined //注销备注

  etpsRepeal_announcePaper: string | undefined //公告报纸名称
  etpsRepeal_payTax: string | undefined //清税情况
  investor_letter: object[] | undefined
  decree_dissolution: object[] | undefined

  proprietor_identity_copy: object[] | undefined //经营者身份证复印件
  business_license_and_copy: object[] | undefined //营业执照正本和副本
  tax_authority_clear_proof: object[] | undefined //税务机关出具的清税证明
  dbtype: number

  lianxi_name: string | undefined //联系人
  lianxi_mobile: string | undefined //手机号
  sales_price: number | null //销售价
}
let formState = reactive<formState>({
  loading: false,
  logout_type: 1,
  main_type: 1,
  recipient_area_ids: '',
  logout_sqtype: 2,
  main_type_name: '公司',
  full_name: '',
  social_credit_code: null,
  liaison_identity: null,
  agent_idcard_name: null,
  agent_idcard_num: null,
  agent_tel: null,

  //identity:'',
  identity_img_z: '',
  identity_img_f: '',

  appFlog_sumType: 1,
  etpsRepeal_rplReasonGb: '决议解散',
  isTaxReg: 0,
  clearTaxFileNo: '',
  taxRegOrgan: null,
  memo: '',

  etpsRepeal_announcePaper: '国家企业信用信息公示系统',
  etpsRepeal_payTax: '2',
  investor_letter: [],
  decree_dissolution: [],

  proprietor_identity_copy: [],
  business_license_and_copy: [],
  tax_authority_clear_proof: [],
  dbtype: 2,
  lianxi_name: '',
  lianxi_mobile: '',
  sales_price: null
})
const props = defineProps({
  open: {
    type: Boolean
  },
  tableColumnsData: {
    type: Object,
    default: () => null
  }
})

onMounted(() => {
  // 初始化
  if (props.tableColumnsData?.id) {
    state.modalTitle = '编辑注销客户'
    state.loading = true
    state.isEdit = true
    state.isRequired = true
    formState.main_type = 1
  } else {
    //添加注销客户，默认需要传营业执照正副本，默认是公司
    state.isRequired = true
    formState.main_type = 1
  }
  queryDetail()
})

interface state {
  selectOptions: any[] //代办人的选择列表
  loading: boolean
  maskClosable: boolean //点击蒙层是否允许关闭
  modalTitle: string
  isEdit: boolean
  confirmLoading: boolean
  isRequired: boolean //business_license_and_copy 判断是否必填
}
const state = reactive<state>({
  //代办人搜索选择
  selectOptions: [],
  loading: false,
  maskClosable: true,
  modalTitle: '新增注销客户',
  // 是否为编辑
  isEdit: false,
  confirmLoading: false,
  isRequired: false
})
const queryDetail = () => {
  let requestParam = {
    url: `/admin/v1/simpleLogout/${props.tableColumnsData?.id}/edit`,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      Object.assign(formState, res.data)
      //改变是否传营业执照
      yyzzliandong()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
      state.loading = false
    })
}
//省市区变动时去搜索代办人
const recipient_area_ids_change = (e, val) => {
  // formState.recipient_area_ids = e
  //如果选择了省市区，则清空代办人信息
  formState.agent_idcard_name = ''
  formState.agent_tel = ''
  formState.identity_img_z = ''
  formState.identity_img_f = ''
  formState.agent_idcard_num = ''
  // 发送请求获取数据
  let area_ids = formState.recipient_area_ids
  let requestParam = {
    url: '/admin/v1/filings/checkWaiqin',
    data: { area_ids: area_ids },
    method: 'post'
  }
  baseService(requestParam)
    .then((res) => {
      if (res.data.length > 0) {
        //如果有指定的业务员,取一个
        let item = res.data[0]
        formState.agent_idcard_name = item.customer_name
        formState.agent_tel = item.customer_tel
        formState.identity_img_z = item.identity_card_front
        formState.identity_img_f = item.identity_card_contrary
        formState.agent_idcard_num = item.identity_card_number
      }
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('查询区域代办人失败')
      }
    })
}
//是否委托代办选否取消联络员
const quxiaoLly = () => {
  if (formState.dbtype == 2) {
    formState.agent_idcard_name = ''
    formState.agent_tel = ''
    formState.identity_img_z = ''
    formState.identity_img_f = ''
    formState.agent_idcard_num = ''
  }
}
// 代办人搜索
const handleChange = (e, val) => {
  formState.agent_tel = val.title.customer_tel
  formState.identity_img_z = val.title.identity_card_front
  formState.identity_img_f = val.title.identity_card_contrary
  formState.agent_idcard_num = val.title.identity_card_number
}
const handleSearch = (e) => {
  clearTimeout(timer)
  if (e == '') {
    state.selectOptions = []
    return
  }
  timer = setTimeout(() => {
    let requestParam = {
      url: '/admin/v1/filings/checkWaiqin',
      data: { customer_name: e },
      method: 'post'
    }
    baseService(requestParam)
      .then((res) => {
        if (!res.data.some((res) => res.customer_name === e)) {
          res.data.unshift({
            customer_name: e
          })
        }
        state.selectOptions = res.data
      })
      .catch((res) => {
        state.selectOptions = []
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('查询失败')
        }
      })
  }, 500)
}

// 切换公司主体类型
const principalTypeChange = (val) => {
  //处理名称
  if (val.target.value == 1) {
    formState.main_type_name = '公司'
    formState.etpsRepeal_rplReasonGb = '决议解散' //注销原因
  } else if (val.target.value == 2) {
    formState.main_type_name = '个人'
    formState.etpsRepeal_rplReasonGb = '停止经营'
  } else if (val.target.value == 3) {
    formState.main_type_name = '独资'
  } else if (val.target.value == 4) {
    formState.main_type_name = '合伙'
  }
  //公司只能选代办
  formState.dbtype = 2
}

// 关闭
const cancelClick = (val) => {
  if (state.isEdit) {
    emit('cancel')
    return
  }
  emit('cancel')
}
// 提交
const onFormSubmit = () => {
  formRef.value
    .validate()
    .then(() => {
      formState.loading = true
      if (props.tableColumnsData?.id) {
        let requeryParam = {
          url: '/admin/v1/simpleLogout/' + props.tableColumnsData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.confirmLoading = false
            formState.loading = false
            emit('ok')
          })
          .catch((res) => {
            if (res) {
              state.confirmLoading = false
              formState.loading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/simpleLogout',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            state.loading = false
            state.confirmLoading = false
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res) {
              state.confirmLoading = false
              if (res.msg) {
                message.warning(res.msg)
                return
              }
              message.warning('参数错误,请检查')
            }
          })
      }
    })
    .catch((val) => {
      state.loading = false
      message.warning('请检查必填项')
    })
}
</script>

<style lang="less" scoped>
.category {
  padding: 30px 0;
  display: flex;
  justify-content: space-around;

  :deep(.ant-input-number-handler-wrap) {
    display: none;
  }

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }

  .company {
    border: 1px solid #e0f9f2;
    background: #e0f9f2;
    color: #00d097;
  }

  .company.check {
    border: 1px solid #00d097;
  }

  .individual {
    background: #e8f1ff;
    color: #3f8cff;
    border: 1px solid #e8f1ff;
  }

  .individual.check {
    border: 1px solid #3f8cff;
  }

  .proprietorship {
    border: 1px solid #e6fffb;
    background: #e6fffb;
    color: #13c2c2;
  }

  .proprietorship.check {
    border: 1px solid #13c2c2;
  }
}

.platform-label {
  line-height: 32px;
  max-width: 102px;
  display: flex;
  justify-content: end;
}

.platform {
  :deep(.ant-form-item-label) {
    display: none;
  }

  :deep(.ant-form-item .ant-form-item-label > label.ant-form-item-no-colon::after) {
    display: none;
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.add-button {
  position: absolute;
  right: 70px;
  top: 10px;
}

.positionItem {
  display: inline-block;
  margin: 5px 0;
}

// 通过标签样式
.range {
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 4px 7px;
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 20px;
  white-space: inherit;
  list-style: none;
  border-radius: 2px;
}

.pass-through {
  color: #5ca200 !important;
  background: #f6ffe4 !important;
  border: 1px solid #bad092 !important;
}

.no-pass {
  color: #ff6e03 !important;
  background: #fff5e7 !important;
  border: 1px solid #ffc067 !important;
}

:deep(.ant-modal-title) {
  font-size: 20px;
}

.share-ownership {
  min-width: 1100px;
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;

  .ownership-table {
    margin-bottom: 30px;
    width: 90%;
  }
}
:deep(.ant-form-item-label) {
  display: flex;
  justify-content: flex-end;
  line-height: 16px;
  height: 40px;
  align-items: center;
  label {
    white-space: normal;
    text-align: right;
    padding-right: 10px;
    &:after {
      content: none !important; //解决上面的样式label后面会有空格
    }
  }
}
</style>
