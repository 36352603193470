<template>
  <!-- pc端微信Native支付,JSAPI支付 -->
  <a-modal title="微信支付" :width="600" :open="true" @cancel="cancel" @ok="ok">
    <div style="text-align: center; display: flex; flex-direction: column; align-items: center; margin: 30px 0">
      <a-qrcode :status="state.qrcode?.length > 0 ? 'active' : 'loading'" :value="state.qrcode" :size="230" icon="/themes/images/weichatpay.svg"></a-qrcode>
      <div>
        <span style="color: red">￥{{ state.qrcode_money }}元</span> {{ props.qrcodeData.trade_type == 'Native' ? '微信扫码付款(有效期2小时)' : '微信扫码或识别二维码付款' }}
      </div>
      <div>{{ state.full_name }}</div>
    </div>
  </a-modal>
</template>
<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { reactive, onMounted, ref, h, onUnmounted } from 'vue'
const emit = defineEmits(['cancel', 'ok'])

const props = defineProps({
  qrcodeData: {
    type: Object,
    default: {
      qrcode: '', //二维码url
      qrcode_money: 0, //金额
      payment_record_id: 0, //记录id
      full_name: '', //名称
      trade_type: '' //付款方式
    }
  }
})
const state = reactive<any>({
  qrcode: '',
  qrcode_money: 0,
  qrcodeInterval: null, //二维码状态定时查询
  full_name: '',
  trade_type: 'Native'
})

onMounted(() => {
  //显示二维码并开启查询
  state.qrcode = props.qrcodeData.qrcode
  state.qrcode_money = props.qrcodeData.qrcode_money
  state.full_name = props.qrcodeData.full_name
  getQrcodeStatus()
})
onUnmounted(() => {
  clearInterval(state.qrcodeInterval)
})
const getQrcodeStatus = () => {
  //开启支付成功的查询
  clearInterval(state.qrcodeInterval)
  state.qrcodeInterval = setInterval(() => {
    let requeryParam = {
      url: '/admin/v1/paymentRecordStatus?id=' + props.qrcodeData.payment_record_id,
      method: 'get'
    }
    baseService(requeryParam)
      .then((res: any) => {
        if (res.msg == 'success') {
          clearInterval(state.qrcodeInterval)
          message.success('微信付款成功!')
          emit('ok')
        }
      })
      .catch((res) => {})
  }, 2000)
}
const cancel = () => {
  emit('cancel')
}
const ok = () => {
  emit('ok')
}
</script>
<style lang="scss" scoped></style>
