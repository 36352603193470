<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    width="60%"
    :open="open"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    :maskClosable="false"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" scrollToFirstError :model="formState" v-bind="formLayout" :rules="rules" style="margin: 20px 0 100px 0">
        <div class="form-tips-title">第一步：填基本信息</div>
        <a-form-item label="主体名称" name="full_name">
          <a-input placeholder="请输入主体名称" v-model:value="formState.full_name"></a-input>
        </a-form-item>
        <a-form-item label="统一社会信用代码" name="social_credit_code">
          <a-input placeholder="请输入统一社会信用代码" v-model:value="formState.social_credit_code"></a-input>
        </a-form-item>
        <a-form-item label="营业执照照片" name="licence_url" :required="true">
          <Upload
            :upload_path="`public/uploads/刻章管理/长沙哇哇叫刻章服务有限公司/客户资料/${dayjs().format('YYYYMM')}/${formState.full_name}/`"
            :accept="['png', 'jpg', 'jpeg', 'pdf']"
            uploadType="picture-card"
            :limitation="1"
            v-model:value="formState.licence_url"
            :beforeCheck="beforeCheck"
            uploadText="上传营业执照正副本或遗失声明"
          >
          </Upload>
        </a-form-item>

        <a-form-item label="法人代表身份证照片" name="id_card_front" :required="true">
          <div class="information">
            <div class="information-right">
              <a-spin :spinning="state.loading_front">
                <a-form-item-rest>
                  <a-upload
                    list-type="picture-card"
                    accept=".jpeg,.png,.jpg"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :before-upload="beforeUpload"
                    @change="
                      (info) => {
                        handleChange(info, 'id_card_front')
                      }
                    "
                  >
                    <img class="upload-img" type="" v-if="formState.id_card_front" :src="$store.state.user.image_domain + formState.id_card_front" alt="avatar" />
                    <div v-else class="upload-img">
                      <PlusOutlined />
                      <div class="ant-upload-text">上传人员身份证照片(人像面)</div>
                    </div>
                  </a-upload>
                </a-form-item-rest>
              </a-spin>
              <a-spin :spinning="state.loading_back">
                <a-form-item-rest>
                  <a-upload
                    list-type="picture-card"
                    class="avatar-uploader"
                    accept=".jpeg,.png,.jpg"
                    :show-upload-list="false"
                    :before-upload="beforeUpload"
                    @change="
                      (info) => {
                        handleChange(info, 'id_card_back')
                      }
                    "
                  >
                    <img class="upload-img" v-if="formState.id_card_back" :src="$store.state.user.image_domain + formState.id_card_back" alt="avatar" />
                    <div v-else class="upload-img">
                      <PlusOutlined />
                      <div class="ant-upload-text">上传人员身份证照片(国徽面)</div>
                    </div>
                  </a-upload>
                </a-form-item-rest>
              </a-spin>
              <a-form-item-rest>
                <a-upload
                  list-type="picture-card"
                  accept=".jpeg,.png,.jpg"
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  @change="
                    (info) => {
                      handleImageChange(info, 'one_inch_picture')
                    }
                  "
                >
                  <img class="one_inch_picture" v-if="formState.one_inch_picture" :src="$store.state.user.image_domain + formState.one_inch_picture" alt="avatar" />
                  <div v-else>
                    <PlusOutlined />
                    <div class="ant-upload-text">上传用户生活头像照片</div>
                  </div>
                </a-upload>
              </a-form-item-rest>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="法人代表姓名" name="fr_name" :required="true">
          <a-input placeholder="请输入法人代表姓名" v-model:value="formState.fr_name"></a-input>
        </a-form-item>
        <a-form-item label="法人代表电话" name="fr_phone" :required="true">
          <a-input
            placeholder="请输入法人代表电话"
            v-model:value="formState.fr_phone"
            @change="
              (v) => {
                phoneChange(v, 'fr_phone')
              }
            "
          ></a-input>
        </a-form-item>
        <div class="form-tips-title">第二步：选择刻章类型</div>
        <a-form-item label="请选择备案章的类型" name="beian_zhang">
          <a-checkbox-group v-model:value="formState.beian_zhang" style="width: 880px" @change="calcPrice">
            <a-row>
              <a-col :span="4">
                <a-checkbox value="11">备案公章</a-checkbox>
              </a-col>
              <a-col :span="4">
                <a-checkbox value="12">备案财务章</a-checkbox>
              </a-col>
              <a-col :span="4">
                <a-checkbox value="13">备案姓名章</a-checkbox>
              </a-col>
              <a-col :span="4">
                <a-checkbox value="14">备案合同专用章</a-checkbox>
              </a-col>
              <a-col :span="4">
                <a-checkbox value="15">备案发票专用章</a-checkbox>
              </a-col>
              <a-col :span="4">
                <a-checkbox value="16">备案其他类型章</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
        <div class="form-tips-title">第三步：收货地址</div>
        <a-form-item label="邮寄类型">
          <a-radio-group v-model:value="formState.mailing_type" button-style="solid" @change="calcPrice">
            <a-radio-button :value="1">快递发货</a-radio-button>
            <a-radio-button :value="2">跑腿发货</a-radio-button>
            <a-radio-button :value="3">现场取货</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <div v-if="formState.mailing_type == 1 || formState.mailing_type == 2">
          <a-form-item label="发货类型">
            <a-radio-group v-model:value="formState.mailing_paotui" button-style="solid" v-if="formState.mailing_type == 2">
              <a-radio-button :value="1">刻章店发货</a-radio-button>
              <a-radio-button :value="2">自行发货</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="证章收货人姓名">
            <div class="d-flex">
              <a-input class="mr-1x" placeholder="请输入证章收货人姓名" v-model:value="formState.recipient_name"></a-input>
            </div>
          </a-form-item>
          <a-form-item label="证章收货人手机号码">
            <a-input
              @change="
                (v) => {
                  phoneChange(v, 'recipient_mobile')
                }
              "
              :value="formState.recipient_mobile"
              placeholder="请输入证章收货人手机号码"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="证章收货人省市区">
            <AddressCascader v-model:value="formState.recipient_area_ids" @change="residenceChange"> </AddressCascader>
          </a-form-item>
          <a-form-item label="证章收货人地址">
            <a-input placeholder="请输入证章收货人地址" v-model:value="formState.recipient_address"></a-input>
          </a-form-item>
          <a-form-item label="邮编">
            <a-input placeholder="请输入邮编" v-model:value="formState.zipcode"></a-input>
          </a-form-item>
        </div>
        <div class="form-tips-title">第四步：确定订单金额，没有问题则下单付款即可</div>
        <a-form-item label="订单总金额：" name="">
          <a-button type="primary" style="display: flex" :loading="state.priceLoading">¥{{ formState.actually_paid_money }}</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
    <!-- 图片裁剪 -->
    <image-cropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :isId="false"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      :upload_path="`public/uploads/刻章管理/长沙哇哇叫刻章服务有限公司/客户资料/${dayjs().format('YYYYMM')}/${formState.full_name}/`"
      :customerName="formState.full_name"
      :isTiqianClose="true"
      @change="cropperPost"
      v-if="state.showCropper"
    ></image-cropper>
    <image-cropper
      @cancel="
        () => {
          state.showImageCropper = false
        }
      "
      :windowsSize="[130, 180]"
      :isId="false"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      @change="cropperImageChange"
      :upload_path="`public/uploads/刻章管理/长沙哇哇叫刻章服务有限公司/客户资料/${dayjs().format('YYYYMM')}/${formState.full_name}/`"
      :customerName="formState.full_name"
      v-if="state.showImageCropper"
    ></image-cropper>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, h } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import ImageCropper from '@/components/ImageCropper/index.vue'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import { useStore } from 'vuex'
import Upload from '@/components/base/Upload.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import AddressCascader from '@/components/base/AddressCascader.vue'
const $store = useStore()

let timer: any = null
const emit = defineEmits(['cancel', 'ok'])
const ruleForm = ref()
const props = defineProps({
  open: {
    type: Boolean
  },
  tableColumnsData: {
    type: Object,
    default: () => null
  },
  client: {
    type: Object,
    default: {
      agent_id: 0,
      client_id: 0
    }
  }
})

const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
  loading_front: boolean
  loading_back: boolean
  showCropper: boolean
  showImageCropper: boolean
  imageUrl: string
  imageName: string
  currentUpload: string
  nationalityList: string[]
  emailLoading: boolean
  priceLoading: boolean
}
const state = reactive<state>({
  modalTitle: '刻章下单',
  loading: false,
  loading_front: false,
  loading_back: false,
  showCropper: false,
  showImageCropper: false,
  imageUrl: '',
  imageName: '',
  currentUpload: '',
  nationalityList: [
    '汉族',
    '蒙古族',
    '回族',
    '藏族',
    '维吾尔族',
    '苗族',
    '彝族',
    '壮族',
    '布依族',
    '朝鲜族',
    '满族',
    '侗族',
    '瑶族',
    '白族',
    '土家族',
    '哈尼族',
    '哈萨克族',
    '傣族',
    '黎族',
    '傈僳族',
    '佤族',
    '畲族',
    '高山族',
    '拉祜族',
    '水族',
    '东乡族',
    '纳西族',
    '景颇族',
    '柯尔克孜族',
    '土族',
    '达斡尔族',
    '仫佬族',
    '羌族',
    '布朗族',
    '撒拉族',
    '毛难族',
    '仡佬族',
    '锡伯族',
    '阿昌族',
    '普米族',
    '塔吉克族',
    '怒族',
    '乌孜别克族',
    '俄罗斯族',
    '鄂温克族',
    '崩龙族',
    '保安族',
    '裕固族',
    '京族',
    '塔塔尔族',
    '独龙族',
    '额伦春族',
    '赫哲族',
    '门巴族',
    '洛巴族',
    '基诺族',
    '其他',
    '外国血统中国籍人士'
  ],
  emailLoading: false,
  priceLoading: false
})

const rules = {
  full_name: [{ required: true, message: '请输入主体名称' }],
  social_credit_code: [{ required: true, message: '请输入主体统一社会信用代码' }]
}

interface formState {
  full_name: string
  social_credit_code: string
  fr_name: string
  fr_phone: string
  licence_url: string
  one_inch_picture: string
  id_card_front: string
  id_card_back: string
  beian_zhang: number[]
  actually_paid_money: number
  mailing_type: number
  mailing_paotui: number
  recipient_name: string
  recipient_mobile: string
  zipcode: string
  recipient_address: string
  recipient_area_ids: string | undefined
}
const formState = reactive<formState>({
  full_name: '',
  social_credit_code: '',
  fr_name: '',
  fr_phone: '',
  licence_url: '',
  one_inch_picture: '',
  id_card_front: '',
  id_card_back: '',
  beian_zhang: [],
  actually_paid_money: 0,
  mailing_type: 1,
  mailing_paotui: 2,
  recipient_name: '',
  recipient_mobile: '',
  zipcode: '410000',
  recipient_address: '',
  recipient_area_ids: undefined
})

const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  formState[type] = str
}

onMounted(() => {
  if (props.tableColumnsData?.id) {
    queryDetail(props.tableColumnsData.id)
    state.modalTitle = '编辑刻章'
  }
})

const queryDetail = (id) => {
  //   state.loading = true
  //   let requeryParam = {
  //     url: `/admin/v1/kezhangClient/${id}/edit`,
  //     method: 'get'
  //   }
  //   baseService(requeryParam).then((res) => {
  //     state.loading = false
  //     Object.assign(formState, res.data)
  //   })
}
// 上传图片之前检查
const beforeCheck = () => {
  if (formState.full_name == '') {
    message.warning('请先输入主体名称')
    return false
  }
  return true
}
// 手动上传验证
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请上传正确图片！')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片必须小于2MB!')
  }
  return false
}
// 图片上传
const handleChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}
//上传裁剪后的图片
const cropperPost = (formData, isRecognize) => {
  //这个变量会在点另一个的时候变化，先记录一下再关闭窗口
  let currentUpload = state.currentUpload
  state.showCropper = false
  if (currentUpload == 'id_card_front') {
    state.loading_front = true
  } else if (currentUpload == 'id_card_back') {
    state.loading_back = true
  }
  if (isRecognize) {
    //身份证识别需要登录
  } else {
    let requestParam = {
      url: '/admin/v1/upload',
      method: 'post',
      data: formData
    }
    baseService(requestParam)
      .then((res) => {
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        }
        cropperChange(res.data, isRecognize, currentUpload)
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
        if (currentUpload == 'id_card_front') {
          state.loading_front = false
        } else if (currentUpload == 'id_card_back') {
          state.loading_back = false
        }
      })
  }
}
// 裁剪成功
const format = 'YYYY-MM-DD'
const cropperChange = (res, isRecognize, currentUpload) => {
  if (isRecognize) {
    let data = res.data.身份证识别实体信息
    if (currentUpload == 'id_card_back') {
      if (data.身份证国徽面实体信息.有效期限.length < 1) {
        message.error('请上传身份证正面照片（国徽面）')
        formState.id_card_back = ''
        return
      }
      let list = data.身份证国徽面实体信息.有效期限.split('-').map((item, index) => {
        return item.replaceAll('.', '-')
      })
      let obj = {
        id_card_validity_start: dayjs(list[0], format),
        id_card_validity_end: dayjs(list[1], format),
        identity_perpetual: list[1] == '长期' ? 1 : 2
      }
      Object.assign(formState, obj)
    } else if (currentUpload == 'id_card_front') {
      if (data.身份证人像面实体信息.姓名.length < 1) {
        message.error('请上传身份证反面照片（人像面）')
        formState.id_card_front = ''
        return
      }
      let nationality: any = null
      state.nationalityList.forEach((item) => {
        if (item.indexOf(data.身份证人像面实体信息.民族) > -1) {
          nationality = item
        }
      })
      let obj = {
        name: data.身份证人像面实体信息.姓名,
        address: data.身份证人像面实体信息.住址,
        id_card_number: data.身份证人像面实体信息.身份证号,
        gender: data.身份证人像面实体信息.性别 == '男' ? 1 : 2,
        nationality: nationality
      }
      Object.assign(formState, obj)
    }
    formState[currentUpload] = res.data.url
  } else {
    formState[currentUpload] = res.url
  }
}
// 裁剪头像
const handleImageChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showImageCropper = true
  }
}
// 裁剪成功
const cropperImageChange = (res) => {
  formState.one_inch_picture = res.url
  state.showImageCropper = false
}
//计算金额
const calcPrice = () => {
  state.priceLoading = true
  let requeryParam = {
    url: '/admin/v1/kezhangClient/calcPrice',
    method: 'post',
    data: Object.assign(formState, props.client)
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.priceLoading = false
      formState.actually_paid_money = res.data
    })
    .catch((res) => {
      state.priceLoading = false
      if (res) {
        if (res.msg) {
          message.warning(res.msg)
          return
        }
      }
    })
}
// 邮寄地址省市区
const residenceChange = (idList, value: any) => {
  let address = `${value[0].name}${value[1].name}${value[2].name}`
  formState.recipient_address = address
  calcPrice()
}
// 提交
const onFormSubmit = () => {
  if (formState.id_card_front == '' || formState.id_card_back == '' || formState.one_inch_picture == '') {
    message.warning('请上传身份证和头像')
    return
  }
  if (formState.beian_zhang.length < 1) {
    message.warning('请选择刻章类型')
    return
  }
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.tableColumnsData?.id > 0) {
        // let requeryParam = {
        //   url: '/admin/v1/kezhangClient/' + props.tableColumnsData.id,
        //   method: 'put',
        //   data: formState
        // }
        // baseService(requeryParam)
        //   .then((res: any) => {
        //     state.loading = false
        //     message.success(res.msg)
        //     emit('ok', 'edit', props.tableColumnsData.id)
        //   })
        //   .catch((res) => {
        //     state.loading = false
        //     if (res.data) {
        //       message.warning('参数错误,请检查')
        //     } else {
        //       message.warning(res.msg)
        //     }
        //   })
      } else {
        let requeryParam = {
          url: '/admin/v1/kezhangClient',
          method: 'post',
          data: Object.assign(formState, props.client)
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok', 'add', res.data)
          })
          .catch((res) => {
            state.loading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      }
    })
    .catch(() => {
      message.warning('请检查表单必填项')
    })
}
</script>

<style lang="less" scoped>
.information {
  display: flex;
  justify-content: center;
}

.information-right {
  display: flex;
  justify-content: center;
}

.information-card {
  margin-bottom: 20px;
  border-radius: 10px;

  :deep(.ant-card-head) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #40a9ff;
    color: #fff;
  }
}

.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 150px !important;
    height: 100px !important;
  }
}

.one_inch_picture {
  width: 100px;
  height: 100px;
}

.upload-img {
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td) {
  padding-left: 28px;
}

.category {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }
}

.m-0 {
  :deep(.ant-form-explain) {
    position: absolute;
  }
}
</style>
